import React from 'react';
import { List } from 'semantic-ui-react';
import { getAPIUrl } from '../../../util/url';
import { Image } from 'semantic-ui-react';
import PopUp from "../base-kit/PopUp";
import { Document, Page, pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export interface Props {
    file: any,
    onDelete?: any,
}

const createLink = (fileId: any) => {
    return getAPIUrl("/attachment", fileId);
}

const FileListItem = (props: Props) => {
    const imgLink = createLink(props.file.id);
    return (
        <List.Item>
            <div className="content">
                <h5>
                    <div>
                        <i className="icon attach" />
                            <PopUp
                                hoverable
                                mouseLeave
                                pinned
                                position="top center"
                                trigger = {
                                    <a href={imgLink} target="_blank">{props.file.alias} </a>
                                }
                                content = {
                                    props.file.alias.includes(".pdf") ?
                                        <a href={imgLink} target="_blank">
                                            <Document loading={<div style={{width: "200px", height: "260px"}}></div>} file={imgLink}>
                                                <Page width={200} pageNumber={1} />
                                            </Document>
                                        </a>
                                        : 
                                        <div style={{
                                            minHeight: "150px", 
                                            maxHeight:"220px", 
                                            display: "flex", 
                                            alignItems: "center", 
                                            justifyContent: "center"}}
                                            >
                                            <Image
                                                fluid
                                                src={imgLink}
                                                size="small"
                                                href={imgLink}
                                                target='_blank'
                                                />
                                        </div>
                                }
                            />
                        {
                            props.onDelete ?
                                <i className="close link icon floated inline" onClick={props.onDelete} />
                                : null
                        }
                    </div>
                </h5>
            </div>
        </List.Item>
    );
};

export default FileListItem;