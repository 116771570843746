import React from 'react';
import { Form } from 'semantic-ui-react';

export interface Props {
    type: string,
    id: string,
    value: string,
    error?: boolean,
    onChange?: any
    icon?: any,
    iconPosition?: any,
    max?: number,
    readOnly?: boolean,
    label?: string,
    labelPosition?: any,
    required?: boolean,
    disabled?: boolean,
    loading?: boolean,
    placeholder?: string,
    size?: string,
    style?: any
}

class FormInput extends React.Component<Props, never> {
    shouldComponentUpdate(nextProps: Props, nextState: never): boolean {
        return this.props.value !== nextProps.value || this.props.label !== nextProps.label || this.props.type !== nextProps.type;
    }

    handleChange = (event: any) => {
        if(!this.props.max || event.target.value.length < this.props.max)
            this.props.onChange(this.props.id, event.target.value)
    }

    render() {
        return (
            <Form.Field style={this.props.style} required={this.props.required} error={this.props.error}>
                {this.props.label && !this.props.labelPosition ?
                    <label htmlFor={this.props.id}>{this.props.label}</label>
                    :
                    null
                }
                <Form.Input 
                    autoComplete="off"
                    type={this.props.type}
                    id={this.props.id}
                    label={this.props.label && this.props.labelPosition ? this.props.label : false}
                    labelPosition={this.props.labelPosition}
                    icon={this.props.icon}
                    iconPosition={this.props.iconPosition}
                    disabled={this.props.disabled}
                    loading={this.props.loading}
                    placeholder={this.props.placeholder}
                    value={this.props.value}
                    onChange={this.handleChange} />
            </Form.Field>
        );
    }
}

export default FormInput;