import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import ColorCircle from './ColorCircle';
import { Popper, Paper, ClickAwayListener, MenuList, Divider } from '@material-ui/core';
import { Button, Icon, Item } from 'semantic-ui-react';
import {sortCategoryDict} from '../../../util/listOptions';

export interface Props {
    id: string,
    value: string[],
    categoryDict: any,
    handleChange: any,
    headerIcon?: string,
    header?: string
}

// styles
const dropDownStyle: any= {
  "overflowY": "scroll",
  "overflowX": "hidden",
  height: "300px"
}

const menuItemStyle: any = { 
  display: "inline-block", 
  margin: 0,
}

const selectedStyle: any= { "backgroundColor": "#E6E6E6"} 
const basicStyle: any= { "backgroundColor": "white"} 

//functions
const toggleCategory = (value: string, props: Props) => {
    let categoryArray = props.value.slice();
    let index = categoryArray.findIndex(c => c === value);
    if (index >= 0) {
        categoryArray.splice(index, 1);
    } else {
        categoryArray.push(value);
    }
    props.handleChange(props.id, categoryArray);
}

const clearCategories = (props: Props) => {
    let categoryArray: string[] = [];
    props.handleChange(props.id, categoryArray);
}

const renderItems = (props: Props) => {
  let keys = sortCategoryDict(props.categoryDict, "text", true).map((item: any) => item.key);
  let result: any = [];
  keys.map((category: string) => {
    result.push(<MenuItem key={category}
      style={props.value.findIndex(c => c === category) >= 0 ? selectedStyle: basicStyle}
      onClick={() => toggleCategory(category, props)}>
          <ColorCircle color={props.categoryDict[category].color} />
          <p style={menuItemStyle}>{props.categoryDict[category].text}</p>
      </MenuItem>)
  });
  result.push(<Divider key="d1" />);
  result.push(<MenuItem  key="empty"
  onClick={() => clearCategories(props)}>
      <Icon style={{"marginBottom": "6px"}} name="delete" />
      <p style={menuItemStyle}>Poista Rajaukset</p>
  </MenuItem>);
  return result;
}

//class
class CategoryDropdown extends React.Component<Props, any> {
 constructor(props:Props) {
   super(props);
   this.state={
      open : false,
      anchorEl: this,
      selected: false
   }
 }

handleDropDownToggle(e: any, open?: boolean){
  if (open === undefined) {
      const newVal = !this.state.open;
      this.setState({
        open: newVal,
        anchorEl: newVal ? e.currentTarget : this
      })
  }
  else {
    this.setState({
      open: open,
      anchorEl: open ? e.currentTarget : this
    })
  }
}

render(){
  return (
    <div>
      <Button style={{width: "210px"}} onClick={(e:any) => this.handleDropDownToggle(e)} icon="filter" content={this.props.header} labelPosition="left" />
      <Popper placement={"bottom-start"}  open={this.state.open} anchorEl={this.state.anchorEl}>
          <Paper>
              <ClickAwayListener onClickAway={(e:any) => this.handleDropDownToggle(e, false)}>
                  <MenuList style={dropDownStyle}>
                      {renderItems(this.props)}
                  </MenuList>
              </ClickAwayListener>
          </Paper>
      </Popper>
    </div>
  );
}

}

export default CategoryDropdown;