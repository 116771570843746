import rest from './rest';
import { getAPIUrl } from './url';
import { setStore, getStore } from './session';

export interface DropdownOptions {
    key: string | number,
    value: string | number,
    text: string
}


export const sortCategoryDict = (categoryDict: any, sortKey?: string, includeKey?: boolean) => {
    let sortedCategory = sortDictToList(categoryDict, 
        (x: string, y: string) => {
            let key = sortKey || "fi";
            return categoryDict[x][key] < categoryDict[y][key] ? -1 
            : categoryDict[x][key] > categoryDict[y][key] ? 1 
            : 0
        },
        includeKey
    );
    return sortedCategory;
}

export const sortDictToList = (dict: any, callback?: any, includeKey?: boolean) => {
    let keys = Object.keys(dict);
    let sortedList: any = [];
    keys.sort(callback);
    for (let i = 0; i < keys.length; i++) {
        let entry = dict[keys[i]];
        if(includeKey){
            entry.key = keys[i];
        }
        sortedList.push(entry);
    }
    return sortedList;
  }

/// CATEGORIES
export const getCategories = async (forceUpdate: boolean = false, feedId?: string) => {
    let categories = getStore("categories");
    let sortedCategories;
    if (!categories || categories.length <= 0 || forceUpdate) {
        categories = await rest.get(getAPIUrl("/category", feedId ? `?feed=${feedId}` : ""));
        setStore("categories", sortedCategories);
    }
    return categories;
}

export const getCategoryOptions = async (lang: string, forceUpdate: boolean = false, feedId?: string) => {
    let categories: any = await getCategories(forceUpdate, feedId);
    let options: DropdownOptions[] = [];
    if (categories) {
        for (let i = 0; i < categories.length; i++) {
            options[i] = {
                key: categories[i].key,
                value: categories[i].key,
                text: categories[i][lang]
            }
        }
    }
    return options;
}

export const getCategoryDict = async (lang: string, forceUpdate: boolean = false, feedId?: string) => {
    let categories: any = await getCategories(forceUpdate, feedId);
    let options: any = {};
    if (categories) {
        for (let i = 0; i < categories.length; i++) {
            options[categories[i].key] = {
                text: categories[i][lang],
                color: categories[i].color || ""
            }
        }
    }
    return options;
}

/// SUBSCRIBERS
export const getSubscribers = async (forceUpdate: boolean = false) => {
    let subscribers = getStore("subscribers");
    if (!subscribers || subscribers.length <= 0 || forceUpdate) {
        subscribers = await rest.get(getAPIUrl("/pharmacy"));
        setStore("subscribers", subscribers);
    }
    return subscribers;
}

export const getSubscriberTemplates = async (id?: string) => {
    let url = getAPIUrl("/pharmacyListTemplate");
    if(id){
        url += "?id=" + id;
    }
    let templates = await rest.get(url);
    return templates;
}

export const getSubscriberOptions = async (forceUpdate: boolean = false) => {
    let subscribers: any = await getSubscribers(forceUpdate);
    let options: DropdownOptions[] = [];
    if (subscribers) {
        options = subscribers.map((sub: any) => {
            return {
                key: sub.id,
                value: sub.oid,
                text: sub.name,
                email: sub.email,
                isActive: sub.isActive
            };
        });
    }
    return options;
}

export const getSubscriberDict = async (forceUpdate: boolean = false) => {
    let subscribers: any = await getSubscribers(forceUpdate);
    let options: any = {};
    options.count = subscribers.length;
    if (subscribers) {
        for (let i = 0; i < subscribers.length; i++) {
            options[subscribers[i].id] = {
                oid: subscribers[i].oid,
                name: subscribers[i].name,
                email: subscribers[i].email,
                isActive: subscribers[i].isActive
            };
        }
    }
    return options;
}

/// PRODUCTS
export const getProducts = async (forceUpdate: boolean = false, feedId?: string) => {
    let products = await rest.get(getAPIUrl("/product", feedId ? `?feed=${feedId}` : ""));
    return products;
}

export const getProductOptions = async (forceUpdate: boolean = false, feedId?: string) => {
    let products: any = await getProducts(forceUpdate, feedId);
    let options: DropdownOptions[] = [];
    if (products) {
        for (let i = 0; i < products.length; i++) {
            options[i] = {
                key: products[i].id,
                value: products[i].id,
                text: products[i].name
            }
        }
    }
    return options;
}

export const getProductDict = async (forceUpdate: boolean = false, feedId?: string) => {
    let products: any = await getProducts(forceUpdate, feedId);
    let options: any = {};
    if (products) {
        for (let i = 0; i < products.length; i++) {
            options[products[i].id] = {
                name: products[i].name,
                vnr: products[i].vnr,
                supplier: products[i].supplier,
                format: products[i].format,
                packSize: products[i].packSize,
                strength: products[i].strength
            }
        }
    }
    return options;
}