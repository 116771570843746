import React from 'react';
import { Form, Header, Divider } from 'semantic-ui-react';
import FormInput from '../kits/form-kit/FormInput';
import { withRouter, RouteComponentProps } from 'react-router';
import rest from '../../util/rest';
import { getItem } from '../../assets/localization';
import { getAPIUrl, getRouteUrl, getFullRouteUrl } from '../../util/url';
import { openNotification } from '../kits/base-kit/Notification';
import InfoView from "../kits/base-kit/InfoView";
import FileListItem from '../kits/file-kit/FileListItem';

interface Props extends RouteComponentProps<any> {
    lang : string,
    handlePermissionChange : any
}

class LoginView extends React.Component<Props, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            username: "",
            password: "",
            error: false,
            initialized: false
        }
    }

    handleChange = (key: any, value: any) => {
        let newState = {
            ...this.state,
            [key]: value
        };
        this.setState(newState);
    }

    handleLogin = async (event: any) => {
        event.preventDefault();
        this.setState({error: false});
        try {
            let userObj = {
                username: this.state.username,
                password: this.state.password
            };
            let response = await rest.post(getAPIUrl("/login"), userObj);
            let url = this.props.location.state && this.props.location.state.lastPage ? 
                        getFullRouteUrl(this.props.location.state.lastPage) :
                        getFullRouteUrl("/");
            // Cause a full reload
            window.open(url, "_self");
        } catch(error) {
            //We probably want to show an error message somehow in the UI
            if(error.response.status === 403 || error.response.status === 400) {
                this.setState({error: true});
            } else {
                openNotification(error.message);
            }
        }
    };

    async componentDidMount() {
        if(this.props.location.state && this.props.location.state.lastPage) {
            try {
                // Check if we actually have a "permitted session" going
                await rest.refresh();
                this.props.location.search = "";
                let url = getRouteUrl(this.props.location.state.lastPage);
                const location = {
                    pathname: url,
                    state: {lastPage: this.props.location.state.lastPage}
                }
                this.props.history.push(location);
            } catch(error){
                // Do nothing, as it means you're supposed to log in
                this.setState({initialized: true})
            }
        } else {
            this.setState({initialized: true})
        }
    }

    render() {
        if(!this.state.initialized) return null;
        let usernameLabel = getItem(this.props.lang, "loginscreen-username");
        let passwordLabel = getItem(this.props.lang, "loginscreen-password");
        return (
            <div>
                <Divider clearing hidden section/>
                <br />
                <div style={{minWidth: "290px", maxWidth: "290px", textAlign: "center", margin: "auto"}}>
                    <Header style={{fontFamily: "Lato", fontWeight: "normal", fontSize:"26px"}}>{getItem(this.props.lang, "loginscreen-header")}</Header>
                    <Divider clearing hidden />
                    <Form>
                        <FormInput type="text"
                            id="username"
                            icon="mail"
                            iconPosition="left"
                            required={true}
                            placeholder={usernameLabel}
                            value={this.state.username}
                            onChange={this.handleChange} />
                        <FormInput type="password"
                            id="password"
                            icon="lock"
                            iconPosition="left"
                            required={true}
                            placeholder={passwordLabel}
                            value={this.state.password}
                            onChange={this.handleChange} />
                            
                        
                        {
                            this.state.error ?
                            <Header size="tiny" color="red">Kirjautuminen epäonnistui</Header>
                            :
                            <Divider clearing section hidden />
                        }
                        <Form.Button primary fluid onClick={this.handleLogin}>{getItem(this.props.lang, "loginscreen-login")}</Form.Button>
                    </Form>
                </div>
                <Divider clearing section/>
                <InfoView/>         
            </div>
        );
    }
};

export default withRouter(LoginView);