import React from "react";
import { Header, Form, Button, Divider } from "semantic-ui-react";
import { getItem } from "../../assets/localization";
import FormDropdown from "../kits/form-kit/FormDropdown";
import FormInput from "../kits/form-kit/FormInput";
import PlaceholderBlock from "../kits/layout-kit/PlaceholderBlock";
import FormTextArea from "../kits/form-kit/FormTextArea";
import RouteBreadcrumb from "../kits/base-kit/RouteBreadcrumb";
import { MIN_INPUT_LENGTH } from "../../assets/constants";
import { ModalExternalState, openModal } from "../kits/modal-kit/ModalWindow";
import ModalDialogPage from "../kits/modal-kit/ModalDialogPage";

const apSystemOptions = [
  { key: 0, value: 0, text: "Pd3" },
  { key: 1, value: 1, text: "Salix" },
  { key: 2, value: 2, text: "Maxx (lyhyt)" },
  { key: 3, value: 3, text: "Maxx (pitkä)" }
];

const validateForm = (feed: any) => {
  return (
    feed.title &&
    feed.title.length >= MIN_INPUT_LENGTH &&
    feed.description &&
    feed.description.length >= MIN_INPUT_LENGTH &&
    (feed.password.length === 0 || feed.password.length >= 6)
  );
};

const backPopup = (event: any, onConfirm: any) => {
  event.preventDefault();
  let state: ModalExternalState = {
    render: (props: any) => (
      <ModalDialogPage
        {...props}
        content="Oletko varma että haluat hylätä tekemäsi muutokset?"
      />
    ),
    size: "tiny",
    onClose: onConfirm
  };
  openModal(state);
};

class FeedFormView extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      showPassword: false
    }
  }

  handleShowHide = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    const shown = this.state.showPassword;
    this.setState({showPassword: !shown});
  }
  
  render() {
    return (
      <div>
        {this.props.mode === "new" ? (
          <RouteBreadcrumb
            items={[
              { text: "Syötteet", url: "/feeds" },
              { text: "Uusi syöte", url: "/feeds/new" }
            ]}
            size="tiny"
          />
        ) : (
          <RouteBreadcrumb
            items={[
              { text: "Syötteet", url: "/feeds" },
              {
                text: "Muokkaa syötettä",
                url: "/feeds/" + this.props.feed.id + "/edit"
              }
            ]}
            size="tiny"
          />
        )}
        <Header size="large">
          {getItem(
            this.props.lang,
            this.props.mode === "new"
              ? "feed-form-header-new"
              : "feed-form-header-edit"
          )}
        </Header>
        <br />
        <PlaceholderBlock
          isLoading={this.props.isLoading}
          lines={3}
          paragraphs={3}
          inverted
          lang={this.props.lang}
        >
          <Form>
            <FormDropdown
              id="apSystem"
              label={getItem(this.props.lang, "feed-form-apSystem")}
              selection
              required
              options={apSystemOptions}
              value={this.props.feed.apSystem}
              onChange={this.props.handleChange}
            />
            <FormInput
              id="title"
              type="text"
              label={getItem(this.props.lang, "feed-form-title")}
              required
              max={160}
              error={
                this.props.feed.title.length > 0 &&
                this.props.feed.title.length < MIN_INPUT_LENGTH
              }
              placeholder="Syötä nimi"
              value={this.props.feed.title}
              onChange={this.props.handleChange}
            />
            <FormTextArea
              id="description"
              label={getItem(this.props.lang, "feed-form-description")}
              rows={2}
              required
              max={240}
              error={
                this.props.feed.description.length > 0 &&
                this.props.feed.description.length < MIN_INPUT_LENGTH
              }
              placeholder="Syötä kuvaus"
              value={this.props.feed.description}
              onChange={this.props.handleChange}
            />
            <FormDropdown
              id="categories"
              label={getItem(this.props.lang, "feed-form-categories")}
              multiple
              search
              selection
              placeholder="Kaikki kategoriat"
              options={this.props.categoryOptions}
              value={this.props.feed.categories}
              onChange={this.props.handleChange}
            />
            
            <div style={{display: "flex", alignItems: "center", width: "100%"}}>
              <FormInput
                style={{width: "100%"}}
                type={this.state.showPassword === true ? "text" : "password"}
                id="password"
                label={getItem(this.props.lang, "feed-form-password")}
                placeholder={"Syötä salasana tähän..."}
                error={this.props.feed.password.length > 0 && this.props.feed.password.length < 6}
                max={200}
                value={this.props.feed.password} 
                onChange={this.props.handleChange}
              />
              <Button 
                  style={{marginLeft: "10px", marginTop: "7px"}}
                  icon={this.state.showPassword === true ? "eye slash" : "eye"}
                  onClick={this.handleShowHide}
              />
            </div>
            <p style={{fontSize: "11px", margin: "0px", marginTop: "-10px", padding: "0px"}}>
              * Ilman salasanaa syötelista ei vastaanota salasanasuojattuja tiedotteita.
            </p>
            <br />
  
            <Divider />
            <Form.Group>
              <Button onClick={event => backPopup(event, this.props.handleBack)}>
                {getItem(this.props.lang, "feed-form-back")}
              </Button>
              <Button
                primary
                disabled={!validateForm(this.props.feed)}
                onClick={this.props.handleSubmit}
              >
                {getItem(
                  this.props.lang,
                  this.props.mode === "new" ? "feed-form-submit" : "feed-form-accept"
                )}
              </Button>
            </Form.Group>
          </Form>
        </PlaceholderBlock>
      </div>
    );
  }
};

export default FeedFormView;
