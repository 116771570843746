import React from 'react';
import { List } from 'semantic-ui-react';
import FileListItem from './FileListItem';

const FileList = (props: any) => {
    return (
        <div>
            <List verticalAlign="middle">
                {
                    props.files.map((file: any, key: any) => {
                        return <FileListItem key={key} file={file} />
                    })
                }
            </List>
        </div>
    );
};

export default FileList;