import axios from 'axios';
import * as path from 'path';
import { getRouteUrl, getAPIUrl } from './url';
import { refreshSession, clearSession } from './session';

const errorCodeEvaluation = (error: any, history?: any) => {
    if(axios.isCancel(error)){
        error.cancel = true;
        throw error;
    }
    if (history && (error.response.status === 401 || error.response.status === 403)) {
        clearSession();
        const location = {
            pathname: getRouteUrl("/auth/login"),
            state: {lastPage: undefined}
        }
        history.replace(location);
    }
    throw error;
}

const parseUrlQuery = (url: string, queryObj: any) => {
    let result = encodeURI(url);
    if (queryObj != null) {
        result += "?";
        let keys = Object.keys(queryObj);
        for (let i = 0; i < keys.length; i++) {
            result += `${keys[i]}=` + encodeURIComponent(`${queryObj[keys[i]]}`);
            if (i < keys.length - 1) result += "&";
        }
    }
    return result;
}

export default {
    async get(url: string, queryObj?: any, history?: any, refreshToken?: boolean) {
        try {
            let response = await axios.get(parseUrlQuery(url, queryObj));
            if(refreshToken) this.refresh(history);
            return response.data;
        } catch (error) {
            return errorCodeEvaluation(error, history);
        }
    },

    async post(url: string, data: any, history?: any, refreshToken?: boolean) {
        try {
            let response = await axios.post(url, data);
            if(refreshToken) this.refresh(history);
            return response.data;
        } catch (error) {
            return errorCodeEvaluation(error, history);
        }
    },

    async delete(url: string, id: string, history?: any, refreshToken?: boolean) {
        try {
            let response = await axios.delete(path.join(url, id));
            if(refreshToken) this.refresh(history);
            return response.data;
        } catch (error) {
            return errorCodeEvaluation(error, history);
        }
    },

    async patch(url: string, id: string, data: any, history?: any, refreshToken?: boolean) {
        try {
            let response = await axios.patch(path.join(url, id), data);
            if(refreshToken) this.refresh(history);
            return response.data;
        } catch (error) {
            return errorCodeEvaluation(error, history);
        }
    },

    async put(url: string, id: string, data: any, history?: any, refreshToken?: boolean) {
        try {
            let response = await axios.put(path.join(url, id), data);
            if(refreshToken) this.refresh(history);
            return response.data;
        } catch (error) {
            return errorCodeEvaluation(error, history);
        }
    },

    async upload(url: string, file: any, cancelToken?: any, onUploadProgress?: any, history?: any, refreshToken?: boolean) {
        try {
            let formData = new FormData();
            formData.append("upload", file);
            const config = {
                onUploadProgress: onUploadProgress,
                headers: { "Content-Type": "multipart/form-data; boundary=----WebKitFormBoundaryABCDEFGHIJKLMNOPQRSTUVWXYZ" },
                cancelToken: cancelToken.token
            }
            let response = await axios.post(url, formData, config)
            if(refreshToken) this.refresh(history);
            return response.data;
        } catch (error) {
            return errorCodeEvaluation(error, history);
        }
    },

    async refresh(history?: any) {
        try {
            await axios.post(getAPIUrl("/token"), {grant_type: "refresh_token"});
            refreshSession();
        } catch(error) {
            return errorCodeEvaluation(error, history);
        }
    },

    getCancelToken() {
        return axios.CancelToken.source();
    }
}