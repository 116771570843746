import React from 'react';
import { withRouter, Switch, Route, Redirect } from 'react-router';
import FeedFormView from '../views/FeedFormView';
import { getRouteUrl, getAPIUrl } from '../../util/url';
import rest from '../../util/rest';
import { openNotification } from '../kits/base-kit/Notification';

const emptyFeed = {
    apSystem: 0,
    title: "",
    description: "",
    categories: [],
    id: "",
    password: ""
};

class FeedRoutes extends React.Component<any, any> {
    constructor(props : any) {
        super(props);
        this.state = {
            feed: {...emptyFeed},
            mode: "new",
            isLoading: true
        }
    }

    handleChange = (key: string, value: any) => {
        let newFeed = {
            ...this.state.feed,
            [key]: value
        };
        this.setState({ ["feed"]: newFeed });
    }

    handleSubmit = async (event : any) => {
        if(event) event.preventDefault();
        let feed = {
            title: this.state.feed.title,
            description: this.state.feed.description,
            apSystem: parseInt(this.state.feed.apSystem, 10),
            categories: this.state.feed.categories,
            password: this.state.feed.password ? this.state.feed.password : undefined,
        };
        try {
            if(this.state.mode === "new"){
                await rest.post(getAPIUrl("/feed"), feed, this.props.history, true);
                this.props.history.push(getRouteUrl("/feeds"));
            } else {
                let id = this.state.feed.id;
                await rest.put(getAPIUrl("/feed"), id,  feed, this.props.history, true);
                this.props.history.push(getRouteUrl("/feeds"));
            }
        } catch (error) {
            openNotification(error.message);
        }
    }

    handleGet = async (redirectUrl? : any) => {
        try {
            let url = getAPIUrl("/feed", this.props.match.params.id);
            let response = await rest.get(url, null, this.props.history, true);
            this.setState({
                feed: response,
                mode: "edit",
                isLoading: false
            });
            if(redirectUrl) this.props.history.push(redirectUrl);
        } catch (error) {
            openNotification(error.message);
            this.setState({
                feed: {...emptyFeed},
                mode: "new",
                isLoading: false
            });
            this.props.history.replace(getRouteUrl("/feeds"));
        }
    }

    handleBack = () => {
        this.props.history.push(getRouteUrl("/feeds"));
    }

    async componentDidMount() {
        let id = this.props.match.params.id;
        if(id === "new") {
            this.setState({
                feed: {...emptyFeed},
                mode: "new",
                isLoading: false
            })
        } else {
            await this.handleGet();
        }
    }

    render() {
        return (
            <Switch>
                <Route exact path={getRouteUrl("/feeds", "/:id(new)")} render={
                    (p) => <FeedFormView {...p} mode="new" 
                                    feed={this.state.feed} 
                                    lang={this.props.lang}
                                    isLoading={this.state.isLoading}
                                    categoryOptions={this.props.categoryOptions}
                                    handleChange={this.handleChange}
                                    handleSubmit={this.handleSubmit}
                                    handleBack={this.handleBack} />
                }/>
                <Route path={getRouteUrl("/feeds", "/:id")} render={
                    (p) => <FeedFormView {...p} mode="edit" 
                                    feed={this.state.feed} 
                                    lang={this.props.lang}
                                    isLoading={this.state.isLoading}
                                    categoryOptions={this.props.categoryOptions}
                                    handleChange={this.handleChange}
                                    handleSubmit={this.handleSubmit} 
                                    handleBack={this.handleBack} />
                }/>
                <Route path={getRouteUrl("/feeds", "/*")} render={
                    (p) => <Redirect to={getRouteUrl("/feeds")} />
                }/>
            </Switch>
        );
    }
};

export default withRouter(FeedRoutes);