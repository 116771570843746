import React from 'react';
import { Button, Divider } from 'semantic-ui-react';
import TemplateList from '../kits/template-kit/TemplateList';
import { withRouter } from 'react-router';
import PermissionCheck from '../kits/layout-kit/PermissionCheck';
import rest from '../../util/rest';
import * as Const from '../../assets/constants';
import { getAPIUrl, getRouteUrl } from '../../util/url';
import RouteBreadcrumb from '../kits/base-kit/RouteBreadcrumb';
import { openNotification } from '../kits/base-kit/Notification';

class TemplateManagerView extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            isLoading: true,
            templates: []
        }
    }

    handleCreate = () => {
        this.props.history.push(getRouteUrl("/templates", "/new"));
    }

    handleEdit = (id: string) => {
        this.props.history.push(getRouteUrl("/templates", id));
    }

    handleDelete = async (id: string) => {
        try {
            await rest.delete(Const.ROUTES.API + "/pharmacyListTemplate", id, this.props.history);
            await this.handleGet();
            openNotification("Listapohja poistettu.");
        } catch (error) {
            openNotification(error.message);
        }
    }

    handleUse= (id: string) => {
        this.props.history.push(getRouteUrl("/notices", "/new"), id);
    }

    handleGet = async () => {
        try {
            let response = await rest.get(getAPIUrl("/pharmacyListTemplate"), null, this.props.history, true);
            this.setState({
                templates: response
            });
        } catch (error) {
            openNotification(error.message);
        }
    }

    async componentDidMount() {
        await this.handleGet();
    }

    render() {
        return (
            <div>
                <RouteBreadcrumb items={[
                    { text: "Listapohjat", url: "/templates" }
                ]} size="tiny" />
                <Divider clearing hidden />
                <Button icon="plus" content="Luo uusi listapohja" primary onClick={this.handleCreate}></Button>
                <Divider clearing hidden />
                <PermissionCheck permissions={this.props.permissions} check="canListPharmacyTemplates" adminOverride>
                    <TemplateList isLoading={this.state.isLoading}
                        items={this.state.templates}
                        lang={this.props.lang}
                        handleEdit={this.handleEdit}
                        handleDelete={this.handleDelete}
                        handleUse={this.handleUse}
                        permissions={this.props.permissions} />
                </PermissionCheck>
            </div>
        );
    }
}

export default withRouter(TemplateManagerView);