import React from 'react';
import FormInput from '../kits/form-kit/FormInput';
import { getItem } from '../../assets/localization';
import FormTextArea from '../kits/form-kit/FormTextArea';
import { Form, Header, Divider, Grid, Segment, Button, List, Popup } from 'semantic-ui-react';
import FormDropdown from '../kits/form-kit/FormDropdown';
import { NoticeViewEnum, COLORS, MIN_INPUT_LENGTH } from '../../assets/constants';
import { getStore } from '../../util/session';
import { getProducts, getSubscribers, getSubscriberTemplates, sortCategoryDict } from '../../util/listOptions';
import FormRadioButton from '../kits/form-kit/FormRadioButton';
import FormDateInput from '../kits/form-kit/FormDateInput';
import PriceBreakdown from '../kits/notice-kit/PriceBreakdown';
import { withRouter } from 'react-router';
import FileUpload from '../kits/file-kit/FileUpload';
import RouteBreadcrumb from '../kits/base-kit/RouteBreadcrumb';
import { openNotification } from '../kits/base-kit/Notification';
import { ModalExternalState, openModal } from '../kits/modal-kit/ModalWindow';
import ModalListPage from '../kits/modal-kit/ModalListPage';
import ModalDialogPage from '../kits/modal-kit/ModalDialogPage';
import FormCheckbox from '../kits/form-kit/FormCheckbox';
import Request from '../../util/rest';
import * as Const from '../../assets/constants';
import PopUp from "../kits/base-kit/PopUp"
import TableView from "../kits/base-kit/TableView"
import LoaderSpinner from "../kits/base-kit/Loader"
import { getAPIUrl } from '../../util/url';
import rest from '../../util/rest';


const BUTTON_WIDTH = "216px";
const BUTTON_HEIGHT = "36px";

var templateInUse = "";

class NoticeFormView extends React.Component<any, never> {
    constructor(props: any) {
        super(props);
    }
    templateInUse = "";

    async componentDidMount() {
        if (!this.props.permissions || this.props.uuid) {
            history.back();
        }
        if(this.props.item.parentNotice){
            this.props.handleChange("isChild", true);
        }
    }

    drawFormActions = (lang: string) => {
        return (
            <Form.Group>
                <Form.Button size="medium" onClick={(event) => this.backPopup(event, () => this.props.handleBack(event, this.props.noticeMode))}>
                    {getItem(lang, "notice-form-cancel")}
                </Form.Button>
                {
                    this.props.noticeMode === NoticeViewEnum.CREATE ?
                        <Form.Button size="medium" primary onClick={(event) => this.props.handleTransition(event, NoticeViewEnum.PREVIEW)}
                            disabled={!this.validateForm()}>
                            {getItem(lang, "notice-form-preview")}
                        </Form.Button>
                        :
                        <Form.Button size="medium" primary onClick={(event) => this.props.handleSubmit(event)}
                            disabled={!this.validateForm()}>
                            {getItem(lang, "notice-form-save")}
                        </Form.Button>
                }
            </Form.Group>
        );
    }

    getSubscriberModalItems = async () => {
        try {
            const response = await getSubscribers();
            return response.map((sub: any) => {
                return {
                    id: sub.id,
                    oid: sub.oid,
                    name: sub.name,
                    isActive: sub.isActive
                }
            });
        } catch (error) {
            openNotification(error.message);
        }
    }

    drawSubscriberModalItem = (props: any) => {
        const label = props.item.isActive ? "Aktiivinen" : "Ei aktiivinen";
        return (
            <p style={{ color: props.item.isActive ? COLORS.green : COLORS.grey }}>{label}</p>
        );
    }

    getProductModalItems = async () => {
        try {
            const response = await getProducts(false, this.props.uuid);
            return response.map((p: any) => {
                return {
                    id: p.id,
                    vnr: p.vnr,
                    name: p.name,
                    supplier: p.supplier,
                    strength: p.strength,
                    packSize: p.packSize
                };
            });
        } catch (error) {
            openNotification(error.message);
        }
    }

    parseSubscriberTemplates = async () => {
        try {
            const response = await getSubscriberTemplates();
            return response.map((temp: any) => {
                return {
                    id: temp.id,
                    name: temp.name,
                    pharmacies: temp.pharmacies.length
                }
            });
        } catch (error) {
            openNotification(error.message);
        }
    }

    setSelectedSubscribers = async (selected: any) => {
        await this.props.handleChange("subscribers", selected);
        await this.props.handleChange("usedTemplate", templateInUse);
    }

    setSelectedProducts = async (selected: any) => {
        let tempArr: any = [];
        const products = await getProducts(false, this.props.uuid);
        for (let i = 0; i < selected.length; i++) {
            let foundIndex = products.findIndex((x: any) => x.id === selected[i]);
            tempArr.push(products[foundIndex]);
        }
        this.props.handleChange("products", tempArr);
    }

    deleteSubscriberTemplate = async (id: string) => {
        try {
            await Request.delete(Const.ROUTES.API + "/pharmacyListTemplate", id, this.props.history);
            openNotification("Listapohja poistettiin onnistuneesti");
        } catch (error) {
            openNotification("Virhe listapohjan poistossa.");
        }
    }

    saveSubscribersTemplate = async (payload: any) => {
        try {
            await Request.post(Const.ROUTES.API + "/pharmacyListTemplate", payload, this.props.history);
            openNotification("Listapohja luotiin onnistuneesti");
        } catch (error) {
            openNotification("Virhe listapohjan luomisessa.");
        }
    }

    validateForm = () => {
        return this.props.item.title.length >= MIN_INPUT_LENGTH &&
            this.props.item.categories.length > 0 &&
            this.props.item.description.length >= MIN_INPUT_LENGTH &&
            (this.props.item.deliverToAll || this.props.item.subscribers.length > 0);
    }

    openProductModal = (event: any) => {
        event.preventDefault();
        let productId: any = [];
        let productItem = this.props.item.products;
        productItem.forEach((item: any) => {
            if(item.id){
                productId.push(item.id);
            } else {
                productId.push(item);
            }
        });

        let state: ModalExternalState = {
            render: (props: any) => <ModalListPage
                {...props}
                header="Valitse tuotteet"
                lang={this.props.lang}
                selectable scrolling searchable
                rowKeyElements={[
                    { key: "vnr", title: "VNR-numero" },
                    { key: "name", title: "Nimi" },
                    { key: "strength", title: "Vahvuus" },
                    { key: "packSize", title: "Pakkauskoko" }
                ]}
                selected={productId}
                searchPlaceholder="Hae tuotteita"
                searchKeys={["name", "vnr"]} />,
            onOpen: this.getProductModalItems,
            onClose: this.setSelectedProducts,
            canCancel: true,
            canAccept: true,
            canCreateList: false,
            canOpenList: false
        };
        this.props.clearTimer();
        openModal(state);
    }

    openSubscribersModal = (event: any, s?: any, mySelections?: any, usedTemplate?: any) => {
        if (event && event.preventDefault) {
            event.preventDefault();
        }
        let state: ModalExternalState = {
            render: (props: any) => <ModalListPage
                {...props}
                header="Valitse vastaanottajat"
                lang={this.props.lang}
                selectable scrolling searchable
                rowKeyElements={[
                    { key: "oid", title: "OID" },
                    { key: "name", title: "Nimi" },
                    { key: "state", title: "Tila", render: this.drawSubscriberModalItem }
                ]}
                selected={!mySelections ? this.props.item.subscribers : mySelections}
                searchPlaceholder="Hae vastaanottajia"
                searchKeys={["name", "oid"]}
                openSelf={this.openSubscribersModal}
                saveTemplate={this.saveSubscribersTemplate}
                getTemplateItems={this.parseSubscriberTemplates}
                requestTemplateItems={getSubscriberTemplates}
                deleteTemplateItem={this.deleteSubscriberTemplate}
                usedTemplate={usedTemplate ? usedTemplate : null}
                templateInUse = ""
            />,
            onOpen: this.getSubscriberModalItems,
            onClose: this.setSelectedSubscribers, 
            canCancel: true,
            canAccept: true,
            canOpenList: true,
            canCreateList: true
        };
        templateInUse  = usedTemplate; 
        this.props.clearTimer();
        openModal(state);
    }

    backPopup = (event: any, onConfirm: any) => {
        event.preventDefault();
        let state: ModalExternalState = {
            render: (props: any) => <ModalDialogPage {...props}
                content="Oletko varma että haluat hylätä tekemäsi muutokset?" />,
            size: "tiny",
            onClose: onConfirm
        }
        openModal(state)
    }


    handleParentNoticeChange = (key: any, value: any) => {
        let item = Object.assign({}, this.props.item.parentNotice || {});
        item.id = value;
    
        for (let i = 0; i < this.props.noticeList.length; i++) {
            if(this.props.noticeList[i].key === value)
            {
                item.title = this.props.noticeList[i].text;
            }
        }

        this.props.handleChange(key, item);
    }

    render() {
        let lang = getStore("lang", "fi");
        let titleLabel = getItem(lang, "notice-form-title");
        let categoriesLabel = getItem(lang, "notice-form-categories");
        let descriptionLabel = getItem(lang, "notice-form-description");
        let recipientsLabel = getItem(lang, "notice-form-recipients");
        let categoryOptions = this.props.categoryOptions.sort((a: any, b: any) => 
            a.text < b.text ? -1 
            : a.text > b.text ? 1 
            : 0
        );
        
        return (
            <div>
                {
                    this.props.noticeMode === NoticeViewEnum.CREATE ?
                        <RouteBreadcrumb items={[
                            { text: "Tiedotteet", url: "/notices" },
                            { text: "Uusi tiedote", url: "/notices/new" }
                        ]} size="tiny" />
                        :
                        <RouteBreadcrumb items={[
                            { text: "Tiedotteet", url: "/notices" },
                            { text: "Tiedote", url: "/notices/" + this.props.item.id },
                            { text: "Muokkaa tiedotetta", url: "/notices/" + this.props.item.id + "/edit" }
                        ]} size="tiny" />
                }
                <Header size="medium">{getItem(lang, this.props.noticeMode === NoticeViewEnum.CREATE ? "notice-form-header-create" : "notice-form-header-edit")}</Header>
                <br />
                <Grid divided >
                    {/* Notice Form */}
                    <Grid.Column width={!this.props.permissions.isAdmin ? 11 : 16}>
                        <Form>

                            {/* Required fields */}
                            <FormInput label={titleLabel}
                                type="text"
                                id="title"
                                placeholder={"Syötä otsikko"}
                                max={160}
                                error={this.props.item.title.length > 0 && this.props.item.title.length < MIN_INPUT_LENGTH}
                                required={true}
                                onChange={this.props.handleChange}
                                value={this.props.item.title} />
                            <FormDropdown id="categories"
                                label={categoriesLabel}
                                multiple search selection
                                options={categoryOptions}
                                placeholder={"Valitse yksi tai useampi kategoria"}
                                required={true}
                                noResultsMessage={getItem(lang, "search-noresults")}
                                onChange={this.props.handleChange}
                                onClick={this.props.clearTimer}
                                value={this.props.item.categories} />
                            <PopUp 
                                trigger={
                                    <div>
                                        <FormTextArea label={descriptionLabel}
                                            id="description"
                                            rows={4}
                                            error={this.props.item.description.length > 0 && this.props.item.description.length < MIN_INPUT_LENGTH}
                                            placeholder={"Syötä tiedotteen kuvaus"}
                                            required={true}
                                            onChange={this.props.handleChange}
                                            value={this.props.item.description} 
                                            handleEnterPress={this.props.handleEnterPress}/>
                                        <div style={{ minHeight:"20px"}}>
                                            <LoaderSpinner 
                                                active 
                                                inline 
                                                size="tiny"
                                                visible={this.props.loaderVisible}
                                                message={"Haetaan VNR -koodeja..."}
                                            />
                                        </div>
                                    </div>
                                }
                                open={this.props.popupVisible}
                                onClose={this.props.handlePopUpCancel}
                                position="bottom right"
                                style={{marginTop: "-26px"}}
                                content={<TableView 
                                    title="Haluatko lisätä VNR-koodeja vastaavat tuotteet liitteeksi?"
                                    headers={[{name: "Nimi", strength:"Vahvuus", packSize: "Pakkauskoko"}]}
                                    content={this.props.foundProducts}
                                    handleSubmit={this.props.handlePopUpSubmit} 
                                    handleCancel={this.props.handlePopUpCancel}
                                    handleEnterPress={this.props.handleEnterPress}/>
                                } 
                                />
                                
                            {/* Dates */}
                            <List horizontal style={{paddingTop:"16px"}}>
                                <List.Item style={{ marginLeft: "0px", marginRight: "20px" }}>
                                    <FormDateInput id="activationDate"
                                        lang={this.props.lang}
                                        label={getItem(lang, "notice-form-activationDate")}
                                        min={Date.now()}
                                        max={this.props.item.expirationDate}
                                        required
                                        onChange={this.props.handleChange}
                                        value={this.props.item.activationDate} />
                                </List.Item>
                                <List.Item style={{ marginLeft: "0px", marginRight: "20px" }}>
                                    <FormDateInput id="expirationDate"
                                        lang={this.props.lang}
                                        label={getItem(lang, "notice-form-expirationDate")}
                                        min={this.props.item.activationDate}
                                        required
                                        onChange={this.props.handleChange}
                                        value={this.props.item.expirationDate} />
                                </List.Item>
                                <List.Item style={{ marginLeft: "0px", marginRight: "20px" }}>
                                    <FormDateInput id="archiveDate"
                                        lang={this.props.lang}
                                        label={getItem(lang, "notice-form-archiveDate")}
                                        min={this.props.item.expirationDate}
                                        onChange={this.props.handleChange}
                                        value={this.props.item.archiveDate} />
                                </List.Item>
                            </List>
                            <br />
                            <br />

                            {/* Acknowledge options */}
                            <Form.Group grouped>
                                <label>{getItem(this.props.lang, "notice-form-requireLabel")}</label>
                                <Grid widths="equal">
                                    <Grid.Column width={8}>
                                        <FormRadioButton id="requireAck"
                                            group="requireAck"
                                            label={getItem(this.props.lang, "notice-form-noneRequired")}
                                            value={0}
                                            checked={this.props.item.requireAck === 0}
                                            onChange={this.props.handleChange} />
                                        <FormRadioButton id="requireAck"
                                            group="requireAck"
                                            label={getItem(this.props.lang, "notice-form-requireRead")}
                                            value={1}
                                            checked={this.props.item.requireAck === 1}
                                            onChange={this.props.handleChange} />
                                        <FormRadioButton id="requireAck"
                                            group="requireAck"
                                            label={getItem(this.props.lang, "notice-form-requireReact")}
                                            value={2}
                                            checked={this.props.item.requireAck === 2}
                                            onChange={this.props.handleChange} />
                                    </Grid.Column>
                                    <Grid.Column width={8}>
                                        <FormRadioButton id="requireAuth"
                                            group="requireAuth"
                                            label={getItem(this.props.lang, "notice-form-requireAuthFalse")}
                                            value={0}
                                            disabled={this.props.item.requireAck === 0}
                                            checked={this.props.item.requireAuth == 0}
                                            onChange={this.props.handleChange} />
                                        <FormRadioButton id="requireAuth"
                                            group="requireAuth"
                                            label={getItem(this.props.lang, "notice-form-requireAuthTrue")}
                                            value={1}
                                            disabled={this.props.item.requireAck === 0}
                                            checked={this.props.item.requireAuth == 1}
                                            onChange={this.props.handleChange} />
                                    </Grid.Column>
                                </Grid>
                            </Form.Group>

                            {/* Subscribers */}
                            <Form.Group grouped>
                                <label>{recipientsLabel}</label>
                                        <FormRadioButton id="deliverToAll"
                                            group="deliverToAll"
                                            label="Lähetä kaikille"
                                            value={1}
                                            checked={this.props.item.deliverToAll == 1}
                                            onChange={this.props.handleChange} />
                                        <FormRadioButton id="deliverToAll"
                                            group="deliverToAll"
                                            label="Valitse vastaanottajat"
                                            value={0}
                                            checked={this.props.item.deliverToAll == 0}
                                            onChange={this.props.handleChange} />

                                        <Grid widths="equal">
                                            <Grid.Column style={{marginTop: "8px"}}  width={8}>
                                                <Button disabled={this.props.item.deliverToAll == 1}
                                                    style={{ width: BUTTON_WIDTH }}
                                                    icon="users"
                                                    content={
                                                        this.props.item.deliverToAll == 1 ?
                                                            "Lähetetään kaikille" :
                                                            this.props.item.subscribers.length > 0 ?
                                                                this.props.item.subscribers.length + " vastaanottajaa" :
                                                                "Valitse vastaanottajat"
                                                    }
                                                    onClick={this.openSubscribersModal} />
                                            </Grid.Column>
                                            <Grid.Column width={8}>
                                                { 
                                                    this.props.item.usedTemplate != "" && 
                                                    this.props.item.usedTemplate != null && 
                                                    this.props.item.deliverToAll !== 1 ? 
                                                        <div>
                                                            <p><strong>Listapohja: </strong>{this.props.item.usedTemplate}</p> 
                                                        </div> : null
                                                }
                                            </Grid.Column>
                                        </Grid>
                            </Form.Group>

                            {/* Files */}
                            <Form.Field>
                                <label>Liitettävät tiedostot</label>
                                <FileUpload
                                    multiple
                                    style={{ width: BUTTON_WIDTH, height: BUTTON_HEIGHT, lineHeight: "14px", paddingTop: "9px" }}
                                    files={this.props.item.files}
                                    item={this.props.item}
                                    onChange={this.props.handleChange} />
                            </Form.Field>

                            {/* Products */}
                            <Form.Field>
                                <label>Tuotteet</label>
                                <Button
                                    style={{ width: BUTTON_WIDTH }}
                                    icon="tag"
                                    content={
                                        this.props.item.products.length > 0 ?
                                            this.props.item.products.length + " tuotetta" :
                                            "Lisää tuotteita"
                                    }
                                    onClick={this.openProductModal} />
                            </Form.Field>

                            {/* Parent select */}
                            <Form.Field>
                                <FormCheckbox
                                    label={"Alatiedote"}
                                    id="isChild"
                                    checked={this.props.item.isChild}
                                    value={this.props.item.isChild}
                                    onChange={this.props.handleChange} 
                                    disabled={this.props.noticeList.length < 1}
                                    />
                                
                                    <FormDropdown id="parentNotice"
                                        value={this.props.item.parentNotice ? this.props.item.parentNotice.id : undefined}
                                        disabled={!this.props.item.isChild}
                                        label={"Jatkoa tiedotteelle"}
                                        search selection
                                        options={this.props.noticeList}
                                        placeholder={"Päätiedote"}
                                        onChange={this.handleParentNoticeChange} />
                            </Form.Field>

                            <Form.Field>
                                <FormCheckbox
                                    label={getItem(this.props.lang, "notice-form-requireFeedPassword")}
                                    id="requireFeedPassword"
                                    checked={this.props.item.requireFeedPassword}
                                    value={this.props.item.requireFeedPassword}
                                    onChange={this.props.handleChange} />
                            </Form.Field>

                            <Divider clearing hidden />

                            {this.drawFormActions(lang)}
                        </Form>
                    </Grid.Column>

                    {/* Price listing */}
                    {
                        !this.props.permissions.isAdmin ?
                            <Grid.Column width={5}>
                                <Segment basic>
                                    <PriceBreakdown pricing={this.props.pricing}
                                        categories={this.props.categoryDict}
                                        lang={lang} />
                                </Segment>
                            </Grid.Column>
                            :
                            null
                    }

                </Grid>
            </div>
        );
    }
}

export default withRouter(NoticeFormView);