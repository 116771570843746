import React from 'react';
import { Form } from 'semantic-ui-react';

export interface Props {
    id: string,
    label: string,
    checked: boolean,
    value: string | number | undefined,
    onChange: any,
    disabled?: boolean
}

const FormCheckbox = (props : Props) => {
    return (
        <Form.Checkbox  id={props.id}
                        control="input"
                        type="checkbox"
                        label={props.label}
                        value={props.value}
                        checked={props.checked}
                        disabled={props.disabled}
                        onChange={() => props.onChange(props.id, !props.value)}/>
    );
};

export default FormCheckbox;