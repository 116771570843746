import React, {KeyboardEvent} from 'react';
import { Button, Table, Header } from 'semantic-ui-react'

export interface Props {
    title?: string,
    headers: any,
    content: any
    handleSubmit?: any,
    handleCancel?: any,
    handleEnterPress?: any
}

const divStyle: any = {
    width: "60%", 
    display: "inline", 
    margin: "0px auto", 
    overflow: "auto", 
    whiteSpace: "nowrap"
}

const TableView = (props: Props) => {
    let headerValues = [];
    let headerKeys = Object.keys(props.headers[0]);
    for (let i = 0; i < props.headers.length; i++) {
        headerValues.push(Object.values(props.headers[i]));
    }
    return (
        <div>
            <Header>{props.title}</Header>
            <Table>
                <Table.Header>
                    <Table.Row key="header">
                    {
                        props.headers ?
                            headerValues[0].map((item: any, index: number) => {
                                return (
                                    <Table.HeaderCell key={index}>{item}</Table.HeaderCell>
                                )
                            })
                            : null
                    } 
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {
                        props.content ?
                            props.content.map((item: any, index: number) => {
                                return (
                                    <Table.Row key={index}>
                                        {
                                            headerKeys.map((cellItem: any, key: number) => {
                                                return (
                                                    <Table.Cell key={key}>{item[cellItem]}</Table.Cell>
                                                ) 
                                            })
                                        }
                                    </Table.Row>
                                )
                            })
                        : null
                    } 
                </Table.Body>
            </Table>
            <div style={divStyle}>
            {
                props.handleSubmit ? 
                <div style={{float:"left"}}>
                    <Button onKeyDown={(e: any) => props.handleEnterPress(e)} onClick={props.handleSubmit} primary>Kyllä</Button>
                </div>
                :
                null
            }
            {
                props.handleCancel ?
                <div style={{ float: "right"}}>
                    <Button onClick={props.handleCancel}>Peruuta</Button>
                </div>
               
               : null
            }
            </div>
        </div>
        );
    };
    
    export default TableView;
