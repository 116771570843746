import React from 'react';
import { Search, Form, Icon, Input, Dropdown, Label, Select } from 'semantic-ui-react';
import { timingSafeEqual } from 'crypto';

export interface Props {
    id: string,
    value: string,
    onChange: any,
    onSubmit?: any,
    disabled?: boolean,
    placeholder?: string
}

class FormSearch extends React.Component<Props, never> {
    shouldComponentUpdate(nextProps: Props, nextState: never): boolean {
        return this.props.value !== nextProps.value;
    }

    render() {
        return (
            <Form.Input icon
                id={this.props.id}
                fluid={false}
                placeholder={this.props.placeholder}
                onChange={(event: any) => this.props.onChange(this.props.id, event.target.value)}
                onSubmit={this.props.onSubmit}
                value={this.props.value}>
                <input />
                <Icon name="search" />
            </Form.Input>
        );
    }
}

export default FormSearch;