import React from 'react';
import { Container, Pagination, Icon } from 'semantic-ui-react';

export interface Props {
    activePage: number,
    totalPages: number,
    onPageChange: any,
}

const PageMenu = (props: Props) => {
    return (
        props.totalPages > 1 ?
            <Container textAlign="center">
                <Pagination nextItem={{ content: <Icon name="caret right" />, icon: true, disabled: props.activePage === props.totalPages }}
                    prevItem={{ content: <Icon name="caret left" />, icon: true, disabled: props.activePage === 1 }}
                    lastItem={false}
                    firstItem={false}
                    activePage={props.activePage}
                    totalPages={props.totalPages}
                    onPageChange={(event: any, { activePage }) => props.onPageChange("activePage", activePage)} />
            </Container>
            : null
    );
};

export default PageMenu;