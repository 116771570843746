import React from 'react'
import {Loader} from 'semantic-ui-react'

export interface Props {
  active?: boolean,
  inline?: boolean,
  visible?: boolean,
  message?: string,
  size?: "tiny" | "small" | "large"
}

const LoaderSpinner = (props: Props) => (
        <div 
            style={{
                display: props.visible ? "flex" : "none", 
                flexDirection: "row", 
                justifyContent: "center", 
                alignItems: "center"
            }}>
            <div style={{width: "30px"}}>
                <Loader 
                    size={props.size}
                    active={props.active}
                    inline
                />
            </div>
            {
                props.message ?
                    <p>{props.message}</p>
                    :
                    null
            }
        </div>
)

export default LoaderSpinner;