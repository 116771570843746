import React from 'react';
import Badge from './Badge';

export interface Props {
    keys: any,
    options: any
}

const BadgeList = (props: Props) => {
    return (
        props.keys && props.keys.length > 0 && props.options ?
            props.keys.map((item: any, id: any) =>
                <Badge key={id} value={props.options[item].text} color={props.options[item].color} />
            )
            : null
    );
};

export default BadgeList;