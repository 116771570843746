import React from 'react';
import { Table } from 'semantic-ui-react';
import FeedListItem from './FeedListItem';
import PermissionCheck from '../layout-kit/PermissionCheck';

export interface Props {
    items: any,
    categoryDict: any,
    handleGenerateID: any,
    handleEdit: any,
    handleDelete: any,
    permissions: any,
    lang: any,
    isLoading?: boolean
}

const FeedList = (props: Props) => {
    return (
        <Table basic="very" columns="2">
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell width="6">Syötteen tiedot</Table.HeaderCell>
                    <Table.HeaderCell width="10">Syötteen kategoriat</Table.HeaderCell>
                </Table.Row>
            </Table.Header>

            <Table.Body>
                <PermissionCheck permissions={props.permissions} check="canListFeeds">
                    {props.items ?
                        props.items.map((item: any, id: any) =>
                            <FeedListItem key={id} item={item}
                                categoryDict={props.categoryDict}
                                handleGenerateID={props.handleGenerateID}
                                handleEdit={props.handleEdit}
                                handleDelete={props.handleDelete}
                                permissions={props.permissions}
                                listLength={props.items.length} />
                        )
                        :
                        null
                    }
                </PermissionCheck>
            </Table.Body>
        </Table>
    );
};

export default FeedList;