import React from 'react';
import { withRouter } from 'react-router';
import { Dropdown, Button, Icon } from 'semantic-ui-react';
import { getFullRouteUrl } from '../../../util/url';

export interface Props {
    items: any,
    value: string | undefined
}

const handleClick = (value: any, history: any) => {
    let query = "?feed=" + value;
    let url = getFullRouteUrl("/notices", query);
    window.open(url, "_self");
}

const renderItems = (props: any) => {
    let result: any = [];
    props.items.map((item: any) => {
        result.push(<Dropdown.Item key={item.id} 
            value={item.id} 
            content={item.title}
            selected={item.id === props.value}
            onClick={() => handleClick(item.id, props.history)}/>
        );
    })
    return result;
}

const triggerComponent = (
    <Button primary style={{padding: "12px 16px"}}>
        <Button.Content>
            <Icon name="feed"/>
            <Icon name="caret down"/>
        </Button.Content>
    </Button>
)

const FeedDropdown = (props: any) => {
    const item = props.items.find((x:any) => x.id === props.value);
    const title = item ? item.title : "Valitse syöte";
    return (
        <Dropdown   trigger={triggerComponent} 
                    value={props.value}
                    icon={null}
                    pointing="top left">
            <Dropdown.Menu>
                {renderItems(props)}
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default withRouter(FeedDropdown);