import React from 'react';
import { Table, Icon } from 'semantic-ui-react';
import BadgeList from '../base-kit/BadgeList';
import PopupButton from '../base-kit/PopupButton';
import { copyToClipboard } from '../../../util/clipboard';
import { openNotification } from '../base-kit/Notification';
import { getFullAPIUrl } from '../../../util/url';
import { COLORS } from '../../../assets/constants';
import ModalDialogPage from '../modal-kit/ModalDialogPage';
import { ModalExternalState, openModal } from '../modal-kit/ModalWindow';

export interface Props {
    item: any,
    handleGenerateID: any,
    handleEdit: any,
    handleDelete: any,
    categoryDict: any,
    permissions: any,
    listLength: number
}

const handleRSSUrlCopy = (id: string) => {
    let rss = getFullAPIUrl("/rss", id);
    copyToClipboard(rss);
    openNotification("RSS-osoite kopioitu leikepöydälle.");
}

const generatePopup = (onConfirm: any) => {
    let state: ModalExternalState = {
        render: (props: any) => <ModalDialogPage {...props}
            content="Oletko varma, että haluat generoida syötteelle uuden RSS-osoitteen?" />,
        size: "tiny",
        onClose: onConfirm
    }
    openModal(state)
}

const deletePopup = (onConfirm: any) => {
    let state: ModalExternalState = {
        render: (props: any) => <ModalDialogPage {...props}
            content="Oletko varma, että haluat poistaa tämän syötteen?" />,
        size: "tiny",
        onClose: onConfirm
    }
    openModal(state)
}

const FeedListItem = (props: Props) => {
    return (
        <Table.Row>

            {/* Name & Categories */}
            <Table.Cell width="6">
                <div style={{display: "flex"}}>
                    {
                        props.item.requirePassword ?
                        <Icon style={{marginTop: "1px"}} name="lock"/>
                        : null
                    }
                    <h4 style={{ lineHeight: "14px", marginTop: "5px", marginBottom: "0px" }}>
                        {props.item.title}
                    </h4>
                </div>
                <h5 style={{ color: COLORS.grey, fontSize: "12px", marginTop: "0px", marginBottom: "10px" }}>ID: {props.item.id}</h5>
                <p style={{ textAlign: "left", fontSize: "11px" }}>{props.item.description}</p>
                <div>
                    <PopupButton icon="linkify"
                        inverted
                        tooltip="Kopioi syötteen RSS-osoite leikepöydälle"
                        onClick={() => handleRSSUrlCopy(props.item.id)} />
                    <PopupButton icon="refresh"
                        inverted
                        tooltip="Generoi syötteelle uusi RSS-osoite"
                        onClick={() => generatePopup(() => props.handleGenerateID(props.item.id))} />
                    <PopupButton icon="edit"
                        inverted
                        tooltip="Muokkaa syötettä"
                        onClick={() => props.handleEdit(props.item.id)} />
                    <PopupButton icon="trash alternate"
                        inverted
                        disabled={props.listLength < 2}
                        tooltip="Poista syöte"
                        onClick={() => deletePopup(() => props.handleDelete(props.item.id))} />
                </div>

            </Table.Cell>

            {/* RSS ID & Actions */}
            <Table.Cell width="10">
                {
                    props.item.categories.length > 0 ?
                        <BadgeList keys={props.item.categories} options={props.categoryDict} />
                        :
                        <h4 style={{ color: "#ACACAC", lineHeight: "14px" }}>(Kaikki kategoriat)</h4>
                }
            </Table.Cell>
        </Table.Row>
    );
};

export default FeedListItem;