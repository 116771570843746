import { ROUTES } from '../assets/constants';

const normalizeUrl = (...urls: string[]) : string => {
    if(urls.length > 0) {
        let result = "";
        for (let i = 0; i < urls.length; i++) {
            // If the last one's a query, just stamp it on
            if(i === urls.length-1 && urls[i][0] === "?") {
                result += urls[i];
            } 
            // Otherwise normalize the slashes
            else {
                let l = urls[i].length;
                if(l > 0) {
                    let part = urls[i][l-1] === "/" ? urls[i].substring(0, l-1) : urls[i];
                    result += part[0] !== "/" ? "/" + part : part;
                }
            }
        }
        return result;
    } else {
        return "/";
    }
}

export function getRouteUrl (...urls: string[]) : string {
    return normalizeUrl(...urls);
}

export function getFullRouteUrl (...urls: string[]) : string {
    return window.location.protocol + "//" + location.host + normalizeUrl(ROUTES.UI, ...urls);
}

export function getAPIUrl (...urls: string[]) : string {
    return normalizeUrl(ROUTES.API, ...urls);
}

export function getFullAPIUrl (...urls: string[]) : string {
    return window.location.protocol + "//" + location.host + normalizeUrl(ROUTES.API, ...urls);
}