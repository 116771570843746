import React from 'react';
import { Header, Divider, Button } from 'semantic-ui-react';
import FormInput from '../form-kit/FormInput';
import '../../../styles/components/passwordModalDialogPage.css';

export interface Props {
    content: any,
    header?: any,
    confirmLabel?: string,
    cancelLabel?: string,
    handleClose?: any
    handleSubmit?: any
}

class PasswordModalDialogPage extends React.Component<Props, any> {
    constructor(props: Props) {
        super(props);
        this.state = {
            feedPassword: "",
            showPassword: false
        }
    }

    handleChange = (key: string, value: any) => {
        this.setState({ [key]: value });
    }

    handleShowHide = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
        const shown = this.state.showPassword;
        this.setState({ showPassword: !shown });
    }

    render() {
        return (
            <div style={{ padding: "30px" }}>
                {
                    this.props.header ?
                        <div>
                            <Header>{this.props.header}</Header>
                            <Divider hidden clearing />
                        </div>
                        : null
                }
                <p style={{ textAlign: "left" }}>{this.props.content}</p>
                <div style={{ display: "flex", alignItems: "center"}}>
                    <FormInput
                        style={{ marginRight: "0px" }}
                        type={this.state.showPassword === true ? "text" : "password"}
                        id="feedPassword"
                        placeholder={"Syötä salasana tähän..."}
                        max={200}
                        required={true}
                        onChange={this.handleChange}
                        value={this.state.feedpassword}
                    />
                    <Button
                        style={{ marginLeft: "10px", marginTop: "0px" }}
                        icon={this.state.showPassword === true ? "eye slash" : "eye"}
                        onClick={this.handleShowHide}
                    />
                </div>
                <Divider hidden clearing />
                <div style={{ display: "flex", justifyContent: "flex-start" }}>
                    <Button primary content={this.props.confirmLabel || "Lähetä"}
                        style={{ width: "200px", marginRight: "15px" }}
                        onClick={() => {
                            this.props.handleSubmit(this.state.feedPassword);
                            this.props.handleClose(false, false);
                        }} />

                    <Button content={this.props.cancelLabel || "Peruuta"}
                        style={{ width: "200px", marginRight: "15px" }}
                        onClick={() => this.props.handleClose(false, true)} />
                </div>
            </div>
        );
    }
}

export default PasswordModalDialogPage;