import React from 'react';
import Dropzone from 'react-dropzone';
import { List } from 'semantic-ui-react';
import rest from '../../../util/rest';
import { getAPIUrl } from '../../../util/url';
import { withRouter, RouteComponentProps } from 'react-router';
import FileUploadItem from './FileUploadItem';
import FileListItem from './FileListItem';
import { openNotification } from '../base-kit/Notification';

const MAX_UPLOADS_LIMIT = 5;

export interface Props extends RouteComponentProps<any> {
    files: any,
    onChange: any,
    item?: any,
    onDrop?: any,
    onUploadProgress?: any,
    onSuccess?: any,
    onError?: any,
    areaComponent?: any,
    multiple?: boolean,
    label?: string,
    style?: any
}

class FileUpload extends React.Component<Props, any> {
    constructor(props: Props) {
        super(props);
        this.state = {
            uploads: {},
            curIndex: 0
        }
    }

    componentWillUnmount() {
        // Auto-cancel & remove all pending upload instances
        let uploads = this.listUploads();
        uploads.forEach((upload: any) => {
            upload.cancelToken.cancel();
        });
        this.setState({
            uploads: {},
            curIndex: 0
        })
    }

    listUploads() {
        // Sort & list upload instances by their indices (which may have gaps)
        let keys = Object.keys(this.state.uploads).sort();
        if (keys.length > 0) {
            return keys.map((key: any) => {
                return this.state.uploads[key];
            });
        }
        return [];
    }

    addUpload(file: any, callback?: any) {
        // Insert a new upload instance into Object Dict & increment index
        let uploads = this.state.uploads;
        let curIndex = this.state.curIndex;
        let upload = {
            alias: file.name,
            id: curIndex,
            cancelToken: rest.getCancelToken(),
            progress: 0
        };
        uploads[curIndex] = upload;
        curIndex++;
        this.setState({
            uploads: uploads,
            curIndex: curIndex
        }, callback);
        return upload;
    }

    removeUpload(index: number, callback?: any) {
        // Cancel the upload if pending & delete instance
        let uploads = this.state.uploads;
        let curIndex = this.state.curIndex;
        if (uploads[index]) {
            uploads[index].cancelToken.cancel();
            delete uploads[index];
            this.setState({
                uploads: uploads,
                curIndex: curIndex
            }, callback)
        }
    }

    onUploadProgress(progressEvent: any, index: number) {
        // Update upload instance progress
        let uploads = this.state.uploads;
        if (uploads[index]) {
            uploads[index].progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            this.setState({ ["uploads"]: uploads })
        }
    }

    onPost = async (file: any, upload: any) => {
        try {
            let response = await rest.upload(getAPIUrl("/attachment"), file,
                upload.cancelToken,
                (e: any) => this.onUploadProgress(e, upload.id),
                this.props.history, true);
            if (response) {
                // Request completed succesfully:
                // Transfer the new file to item.files & delete upload instance
                let newFiles = this.props.item.files.slice();
                newFiles.push(response);
                this.removeUpload(upload.id);
                this.props.onChange("files", newFiles);
                openNotification(`Tiedosto '${file.name}' liitetty.`);
            } else {
                // The request was canceled, delete upload instance
                this.removeUpload(upload.id);
            }
        } catch (error) {
            openNotification(error.message);
            this.removeUpload(upload.id);
        }
    }

    onDrop = async (acceptedFiles: any, rejectedFiles: any) => {
        // Check upload limit -> start uploading
        let uploads = this.state.uploads;
        if (uploads.length >= MAX_UPLOADS_LIMIT) {
            openNotification("Samanaikaisten lähetettävien tiedostojen raja (5) ylitetty. Odota lähetysten valmistumista.");
            return;
        }
        acceptedFiles.forEach((file: any) => {
            let upload = this.addUpload(file);
            this.onPost(file, upload)
        });
        if (this.props.onDrop) this.props.onDrop(acceptedFiles, rejectedFiles);
    }

    onDeleteAttachment = (deletedFile: any) => {
        // Delete a file from item.files
        let newFiles = this.props.item.files.slice();
        newFiles.splice(deletedFile, 1);
        this.props.onChange("files", newFiles);
    }

    render() {
        let uploads = this.listUploads();
        let seeList = uploads.length > 0 || this.props.item.files.length > 0;
        return (
            <div>
                {
                    seeList ?
                        <List verticalAlign="middle">
                            {
                                // Existing attached files
                                this.props.item.files.map((file: any, key: any) => {
                                    return <FileListItem key={key} file={file}
                                        onDelete={() => this.onDeleteAttachment(key)} />
                                })
                            }
                            {
                                // Uploading files
                                uploads.map((u: any) => {
                                    return <FileUploadItem key={u.id} label={u.alias}
                                        cancelToken={u.cancelToken}
                                        percent={u.progress}
                                        indicating />
                                })
                            }
                        </List>
                        : null
                }

                <Dropzone onDrop={this.onDrop}
                    inputProps={{ type: "file", name: "upload" }}
                    name="upload"
                    multiple
                    className="ui icon button"
                    style={this.props.style || {}}>
                    <i className="upload icon" />
                    <p style={{whiteSpace: "pre", display: "inline"}}>  Liitä tiedostoja</p>
                </Dropzone>
            </div>
        );
    }
};

export default withRouter(FileUpload);