import React from 'react';
import { Popup, Button } from 'semantic-ui-react';

export interface Props {
    tooltip: string,
    tooltipShow?: "hover" | "click" | "focus",
    disabled?: boolean,
    onClick?: any,
    inverted?: boolean,
    basic?: any,
    label?: string,
    buttonContent?: string,
    labelPosition?: "left" | "right" | undefined,
    icon?: string,
}

const PopupButton = (props: Props) => {
    return (
        <Popup content={props.tooltip}
            on={props.tooltipShow}
            inverted={props.inverted}
            basic={props.basic}
            trigger={
                <Button icon={props.icon} label={props.label} 
                        content={props.buttonContent}
                        disabled={props.disabled} 
                        labelPosition={props.labelPosition} 
                        onClick={props.onClick}/>
            }>
        </Popup>
    );
};

export default PopupButton;