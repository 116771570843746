import React from "react";
import { withRouter } from "react-router-dom";
import { Route, Switch, Redirect } from "react-router";
import BaseRoutes from "./routes/BaseRoutes";
import AuthRoutes from "./routes/AuthRoutes";
import FrontRoutes from "./routes/FrontRoutes";
import HiddenRoutes from './routes/HiddenRoutes';
import { setStore, getStore, registerSessionEndCallback } from "../util/session";
import Notification from "./kits/base-kit/Notification";
import ModalWindow, { openModal, ModalExternalState } from "./kits/modal-kit/ModalWindow";
import rest from "../util/rest";
import queryString from "query-string";
import SessionExpirationPage from "./kits/modal-kit/SessionExpirationPage";

class App extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      initialized: false,
      hasSession: false
    }
  }

  async componentDidMount() {
    registerSessionEndCallback(() => {
      const state: ModalExternalState = {
        render: (props: any) => <SessionExpirationPage {...props} />,
        closeOnDimmerClick: false,
        size: "tiny"
      }
      openModal(state);
    });
    let hasSession = false;
    try {
      await rest.refresh();
      hasSession = true;
    } catch (error) {
      hasSession = false;
    }
    return this.setState({ initialized: true, hasSession: hasSession });
  }
  checkFeedQuery = () => {
    const query = queryString.parse(this.props.location.search);
    if (query.feed) {
      return query.feed;
    }
    return undefined;
  }

  handleLanguangeChange = (lang: string) => {
    setStore("lang", lang);
    this.forceUpdate();
  }

  render() {
    let lang = getStore("lang", "fi");
    let uuid = this.checkFeedQuery();
    if (!this.state.initialized) return null;
    return (
      <div>
        <Switch>
          {/* LOGIN */}
          <Route
            path={"/auth"}
            render={props => (
              <AuthRoutes
                {...props}
                lang={lang}
                handleLanguangeChange={this.handleLanguangeChange}
                hasSession={this.state.hasSession}
              />
            )}
          />
          {/* FRONT */}
          <Route
            path={"/contact"}
            render={(props: any) => <FrontRoutes lang={lang} />}
          />

          {/*HIDDEN */}
          <Route
            path={"/apteekeille"}
            render={(props: any) => <HiddenRoutes lang={lang} />}
          />

          {/* VIEWS */}
          <Route
            path={"/"}
            render={(props: any) =>
              this.state.hasSession || uuid ? (
                <BaseRoutes
                  {...props}
                  lang={lang}
                  handleLanguangeChange={this.handleLanguangeChange}
                  uuid={uuid}
                  hasSession={this.state.hasSession}
                />
              ) : (
                  <Redirect to={"/auth/login"} />
                )
            }
          />
        </Switch>
        {/* Global Modal component */}
        <ModalWindow />
        {/* Global Notification component */}
        <Notification />
      </div>
    );
  }
}

export default withRouter<any>(App);
