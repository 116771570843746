import React from 'react';
import { Table } from 'semantic-ui-react';
import '../../../styles/components/ModalListItem.css';

export interface KeyElement {
    key: string | number,
    title: string,
    render?: any,
    onClick?: any
}

export interface Props {
    item: any,
    rowKeyElements: KeyElement[],
    selectable?: boolean,
    selected?: any,
    onSelect?: any,
    singleSelection?: boolean
}

const renderComponent = (WrappedComponent: any, onClick: any, item: any, id: any) => {
    return <WrappedComponent id={id} value={item[id]} item={item} onClick={onClick} />
}

class ModalListItem extends React.Component<Props, never> {
    shouldComponentUpdate(nextProps: Props, nextState: never): boolean {
        return nextProps.item !== this.props.item ||
            nextProps.selected[this.props.item.id] !== this.props.selected[this.props.item.id];
    }

    render() {
        if (!this.props.item) return null;
        let isSelected = false;
        if (this.props.selectable && this.props.selected) {
            isSelected = this.props.selected[this.props.item.id] != null;
        }
        let className = "item";
        if (this.props.selectable) {
            if (isSelected) {
                className = "itemSelected"
            } else {
                className = "itemSelectable"
            }
        }
        return (
            <Table.Row
                onClick={this.props.selectable ? () => {
                    if (this.props.singleSelection) {
                        if (Object.keys(this.props.selected).length === 0) {
                            this.props.onSelect(this.props.item.id);
                        }
                        else{
                            this.props.onSelect(Object.keys(this.props.selected)[0]);
                        }
                    } else {
                        this.props.onSelect(this.props.item.id);
                    }
                } : null}
                className={className}>
                {
                    this.props.rowKeyElements.map((pair: KeyElement) =>
                        <Table.Cell key={pair.key}>
                            {pair.render ?
                                renderComponent(pair.render, pair.onClick, this.props.item, pair.key)
                                :
                                this.props.item[pair.key]
                            }
                        </Table.Cell>
                    )
                }
            </Table.Row>
        );
    }
};

export default ModalListItem;