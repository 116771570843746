const API_VERSION: number = 1;
export const ITEMS_PER_PAGE: number = 10;
export const MIN_INPUT_LENGTH: number = 3;
export const SESSION_DURATION: number = 1800; //30 minutes in seconds
export const SESSION_TIME_LEFT_POPUP: number = 60;

export const DATEFORMATS: any = {
    fi: "dd.MM.yyyy",
    en: "MM/dd/yyyy"
}

export const COLORS: any = {
    red: "#D0021B",
    green: "#2DB84B",
    lightGreen: "#1EC743",
    blue: "#1EA0DD",
    yellow: "#F3BB00",
    orange: "#F0712C",
    grey: "#A8A8A8"
}

export const ROUTES: any = {
    UI: "/ui",
    API: "/api/v" + API_VERSION.toString(),
    AUTH: "/auth",
    LOGIN: "/auth/login",
    CHANGEPWD: "/auth/changepw",
    NOTICES: "/notices",
    FEEDS: "/feeds",
    TEMPLATES: "/templates"
}

export const NoticeViewEnum: any = {
    VIEW: 0,
    PREVIEW: 1,
    CREATE: 2,
    EDIT: 3,
}