import React, { Component } from 'react';
import { Form, Dropdown } from 'semantic-ui-react';

export interface DropdownOptions {
    key: string | number | undefined,
    value: string | number | undefined,
    text: string | undefined
}

export interface Props {
    id: string,
    options: DropdownOptions[],
    value: string | number,
    onChange: any,
    required?: boolean,
    placeholder?: string,
    label?: string,
    fluid?: boolean,
    multiple?: boolean,
    allowAdditions?: boolean,
    additionLabel?: string,
    noResultsMessage?: string,
    search?: boolean,
    selection?: boolean,
    clearable?: boolean,
    onClick?: any,
    disabled?: boolean,
    style?: any,
    returnFullData?: boolean
}

const renderLabel = (label: any) => ({
    color: 'blue',
    content: label.text
})

class FormDropdown extends Component<Props, never> {
    shouldComponentUpdate(nextProps: Props, nextState: never): boolean {
        return  this.props.value !== nextProps.value || 
                this.props.label !== nextProps.label ||
                this.props.options !== nextProps.options || 
                this.props.disabled !== nextProps.disabled;
    }

    render() {
        return (
            <Form.Field required={this.props.required}>
                <label style={this.props.disabled ? {color: "#DFDFDF"} : undefined}>{this.props.label}</label>
                <Dropdown fluid={this.props.fluid}
                    multiple={this.props.multiple}
                    search={this.props.search}
                    selection={this.props.selection}
                    clearable={this.props.clearable}
                    placeholder={this.props.placeholder}
                    allowAdditions={this.props.allowAdditions}
                    additionLabel={this.props.additionLabel}
                    id={this.props.id}
                    renderLabel={renderLabel}
                    noResultsMessage={this.props.noResultsMessage}
                    options={this.props.options}
                    value={this.props.value}
                    onChange={(event, data) => this.props.onChange(this.props.id, this.props.returnFullData ? data : data.value)} 
                    onClick={this.props.onClick}
                    disabled={this.props.disabled}/>
            </Form.Field>
        );
    }
}

export default FormDropdown;