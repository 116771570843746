import React from 'react';
import { withRouter } from 'react-router';

export interface Props {
    children: any,
    textAlign?: any
}

const Layout = (props: any) => {
    return (
        <div style={{minWidth:"700px", maxWidth:"1000px", margin: "0 auto"}}>
            {props.children}
        </div>
    );
};

export default withRouter(Layout);