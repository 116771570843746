import React from 'react';
import { Form } from 'semantic-ui-react';

export interface Props {
    id: string,
    group: string,
    label: string,
    value: string | number,
    checked: any,
    onChange: any,
    disabled?: boolean
}

const FormRadioButton = (props : Props) => {
    return (
        <Form.Radio id={props.id}
                    control="input"
                    type="radio"
                    name={props.group}
                    label={props.label}
                    value={props.value}
                    disabled={props.disabled}
                    checked={props.checked}
                    onChange={() => props.onChange(props.id, props.value)}/>
    );
};

export default FormRadioButton;