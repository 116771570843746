import React from 'react';
import { Breadcrumb } from 'semantic-ui-react';
import { withRouter, RouteComponentProps } from 'react-router';
import { getRouteUrl } from '../../../util/url';

export interface Item {
    text: string,
    url: string
}

export interface Props extends RouteComponentProps<any> {
    items: Item[],
    size?: any
}

const handleLink = (url: string, history: any) => {
    history.push(getRouteUrl(url));
}

const drawItems = (props: Props) => {
    let result = [];
    for (let i = 0; i < props.items.length; i++) {
        if (i === props.items.length - 1) {
            result.push(<Breadcrumb.Section key={i} active>{props.items[i].text}</Breadcrumb.Section>);
        } else {
            result.push(<Breadcrumb.Section link onClick={() => handleLink(props.items[i].url, props.history)} key={i}>{props.items[i].text}</Breadcrumb.Section>);
            result.push(<Breadcrumb.Divider key={i + "d"} />);
        }
    }
    return result;
}

const RouteBreadcrumb = (props: Props) => {
    return (
        <Breadcrumb size={props.size}>
            {drawItems(props)}
        </Breadcrumb>
    );
};

export default withRouter(RouteBreadcrumb);