import React from 'react'
import { Popup } from 'semantic-ui-react'

export interface Props {
    trigger: any
    content: any,
    open?: boolean
    style?: any,
    position?: "top center" | "top left" | "top right" | "bottom right" | "bottom left" | "bottom center" | "right center" | "left center" 
    onClose?: any,
    mouseLeave?: boolean,
    hoverable?: boolean,
    inverted?: boolean,
    basic?: boolean
    pinned?: boolean
}

const PopUp = (props: Props) => {
    return (
        <Popup
            pinned={props.pinned}
            inverted={props.inverted}
            basic={props.basic}
            style={props.style}
            hoverable={props.hoverable}
            closeOnTriggerMouseLeave={props.mouseLeave}
            trigger={props.trigger}
            open={props.open}
            position={props.position}
            content={props.content}
            onClose={props.onClose}
            >
        </Popup>
    )
}

export default PopUp;