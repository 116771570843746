import React from 'react';
import NoticeListItem from './NoticeListItem';
import { withRouter } from 'react-router-dom';
import { List } from 'semantic-ui-react';
import PlaceholderBlock from '../layout-kit/PlaceholderBlock';

const NoticeList = (props: any) => {
    return (
        <PlaceholderBlock lang={props.lang}
            paragraphs={4}
            lines={6}
            isLoading={props.isLoading}
            inverted
            loaderSize="large">

            <List divided>
                {props.items ?
                    props.items.map((item: any, key: number) =>
                        <NoticeListItem key={key}
                            url={props.match.url}
                            item={item}
                            lang={props.lang}
                            categoryDict={props.categoryDict}/>
                    ) : null}
            </List>
        </PlaceholderBlock>
    );
};

export default withRouter(NoticeList);