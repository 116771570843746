import React from 'react';
import { Card, Icon } from 'semantic-ui-react';

const ContactView = (props: any) => {
  return (
    <div>
      <h1>Yhteystiedot</h1>
      <Card.Group>
        <Card>
          <Card.Content>
            <Card.Header content="Apteekkien tunnusten käyttöönotto" />
            <Card.Description content="Lähetä allekirjoitettu käyttösopimus (sopimus julkaistu  Farmaportissa 23.4.2019), minkä jälkeen saat tunnukset." />
          </Card.Content>
          <Card.Content extra>
            <Icon name="mail" />
            <a href="mailto:asiakaspalvelu@farmatools.fi">
              asiakaspalvelu@farmatools.fi
            </a>
          </Card.Content>
        </Card>
        <Card>
          <Card.Content>
            <Card.Header content="Yritysten tunnusten käyttöönotto" />
            <Card.Description content="Jos olet kiinnostunut palvelusta ota yhteyttä alla olevaan sähköpostiin." />
          </Card.Content>
          <Card.Content extra>
            <Icon name="mail" />
            <a href="mailto:myynti@farmatools.fi">myynti@farmatools.fi</a>
          </Card.Content>
        </Card>
        <Card>
          <Card.Content>
            <Card.Header content="Palaute ja asiakaspalvelu" />
            <Card.Description content="Kehitysehdotuksia? Huolia? Saat meihin yhteyttä oheisesta sähköpostista." />
          </Card.Content>
          <Card.Content extra>
            <Icon name="mail" />
            <a href="mailto:tatu@farmatools.fi">tatu@farmatools.fi</a>
          </Card.Content>
        </Card>
      </Card.Group>
    </div>
  );
};

export default ContactView;
