import React from 'react';
import { withRouter, Switch, Route, Redirect } from 'react-router';
import TemplateFormView from '../views/TemplateFormView';
import { getRouteUrl, getAPIUrl } from '../../util/url';
import rest from '../../util/rest';
import * as Const from '../../assets/constants';
import { openNotification } from '../kits/base-kit/Notification';

const emptyTemplate = {
    name: "",
    pharmacies: [],
    id: ""
};

class TemplateRoutes extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            template: { ...emptyTemplate },
            mode: "new",
            isLoading: true
        }
    }

    handleChange = (key: string, value: any) => {
        let newTemplate = {
            ...this.state.template,
            [key]: value
        };
        this.setState({ ["template"]: newTemplate });
    }

    handleSubmit = async (event: any) => {
        if (event) event.preventDefault();
        let template = {
            name: this.state.template.name,
            pharmacies: this.state.template.pharmacies
        };
        try {
            if (this.state.mode === "new") {
                await rest.post(Const.ROUTES.API + "/pharmacyListTemplate", template, this.props.history);
                this.props.history.push(getRouteUrl("/templates"));
            } else {
                let id = this.state.template.id;
                await rest.put(Const.ROUTES.API + "/pharmacyListTemplate", id, template, this.props.history);
                this.props.history.push(getRouteUrl("/templates"));
            }
        } catch (error) {
            openNotification(error.message);
        }
    }

    handleGet = async (redirectUrl?: any) => {
        try {
            let url = getAPIUrl("/pharmacyListTemplate");
            if (this.props.match.params.id) {
                url += "?id=" + this.props.match.params.id;
            }
            let response = await rest.get(url, null, this.props.history, true);
            this.setState({
                template: response[0],
                mode: "edit",
                isLoading: false
            });
            if (redirectUrl) this.props.history.push(redirectUrl);
        } catch (error) {
            openNotification(error.message);
            this.setState({
                template: { ...emptyTemplate },
                mode: "new",
                isLoading: false
            });
            this.props.history.replace(getRouteUrl("/templates"));
        }
    }

    handleBack = () => {
        this.props.history.push(getRouteUrl("/templates"));
    }

    async componentDidMount() {
        let id = this.props.match.params.id;
        if (id === "new") {
            this.setState({
                template: { ...emptyTemplate },
                mode: "new",
                isLoading: false
            })
        } else {
            await this.handleGet();
        }
    }

    render() {
        return (
            <Switch>
                <Route exact path={getRouteUrl("/templates", "/:id(new)")} render={
                    (p) => <TemplateFormView {...p} mode="new"
                        template={this.state.template}
                        lang={this.props.lang}
                        isLoading={this.state.isLoading}
                        subscriberOptions={this.props.subscriberOptions}
                        handleChange={this.handleChange}
                        handleSubmit={this.handleSubmit}
                        handleBack={this.handleBack} />
                } />
                <Route path={getRouteUrl("/templates", "/:id")} render={
                    (p) => <TemplateFormView {...p} mode="edit"
                        template={this.state.template}
                        lang={this.props.lang}
                        isLoading={this.state.isLoading}
                        subscriberOptions={this.props.subscriberOptions}
                        handleChange={this.handleChange}
                        handleSubmit={this.handleSubmit}
                        handleBack={this.handleBack} />
                } />
                <Route path={getRouteUrl("/templates", "/*")} render={
                    (p) => <Redirect to={getRouteUrl("/templates")} />
                } />
            </Switch>
        );
    }
};

export default withRouter(TemplateRoutes);