import React from 'react';
import { List, Grid, Header, Icon, Item, Segment, Container } from 'semantic-ui-react';

const DocumentsView = (props: any) => {
    return (
        <>
            <Header as='h2'>Apteekeille</Header>
            <Container textAlign="left">Lue <a href="https://storage.googleapis.com/farmahubi-static-assets/Farmahub_kayttoehdot_apteekeille_27112020.pdf">käyttöehdot</a> sekä tulosta sopimus kahtena kappaleena ja postita sopimukset seuraavaan osoitteeseen:<br />
                <Segment style={{ 'display': 'inline-block' }}>
                    Farmatools Oy <br />
                    Kiiskitie 3 <br />
                    51200 KANGASNIEMI <br />
                </Segment> <br />
             Palautamme teille toisen allekirjoitetuista sopimuksista sekä toimitamme käyttäjätunnukset palveluun. Käyttäjätunnusten saamisen jälkeen voit asentaa palvelun käyttöön ohjeiden mukaisesti.
             Ongelmatilanteissa jätä yhteydenottopyyntö <a href="mailto:asiakaspalvelu@farmatools.fi">asiakaspalvelu@farmatools.fi</a>, niin olemme teihin yhteydessä. Kerro lyhyesti viestissä missä kohtaa tuli ongelmia.</Container>
            <Header as='h3'>Tiedostot</Header>

            {/* Files here */}
            <List>
                <List.Item>
                    <i className="icon attach" />
                    <div className="content">
                        <h5>
                            <a href="https://storage.googleapis.com/farmahubi-static-assets/Farmahub_kayttoehdot_apteekeille_27112020.pdf" target="_blank">Käyttöehdot apteekeille</a>
                        </h5>
                    </div>
                </List.Item>
                <List.Item>
                    <i className="icon attach" />
                    <div className="content">
                        <h5>
                            <a href="https://storage.googleapis.com/farmahubi-static-assets/Farmahubi_sopimus_apteekeille_27112020.pdf" target="_blank">Sopimus apteekeille</a>
                        </h5>
                    </div>
                </List.Item>
                <List.Item>
                    <i className="icon attach" />
                    <div className="content">
                        <h5>
                            <a href="https://storage.googleapis.com/farmahubi-static-assets/Farmahubi_MAXX-ohje_17_03_2020.pdf" target="_blank">Käyttöönotto MAXX</a>
                        </h5>
                    </div>
                </List.Item>
                <List.Item>
                    <i className="icon attach" />
                    <div className="content">
                        <h5>
                            <a href="https://storage.googleapis.com/farmahubi-static-assets/Farmahubin_Kayttoonotto_Presto_ohje_versio_2.3.pdf" target="_blank">Käyttöönotto Presto</a>
                        </h5>
                    </div>
                </List.Item>
                <List.Item>
                    <i className="icon attach" />
                    <div className="content">
                        <h5>
                            <a href="https://storage.googleapis.com/farmahubi-static-assets/Farmahubin_Kayttoonotto_Salix_versio_1.3.pdf" target="_blank">Käyttöönotto Salix</a>
                        </h5>
                    </div>
                </List.Item>
            </List>
        </>
    );
};

export default DocumentsView;
