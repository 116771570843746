const PAD = "                                                      ";

export const prettyNumber = (num: number, separator?: string) => {
    let result = num.toString();
    if(result.length > 3) {
        if(!separator) separator = ",";
        for(let i = result.length-1; i >= 0; i-3) {
            result = result.slice(0, i) + separator + result.slice(i, result.length);
        }
    }
    return result;
}

export const shortenString = (value : string, limit: number) => {
    if(value) {
        if (value.length > limit)
            return value.substring(0, limit-3) + "...";
        return value;
    }
}

export const padStringStart = (value: string, padding: number) => {
    return PAD.substring(0, padding) + value;
}

export const padStringEnd = (value: string, padding: number) => {
    return value + PAD.substring(0, padding);
}