import '@babel/polyfill';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import App from './components/App';
import {unregister} from './registerServiceWorker';
import './styles/fonts/fonts.css';
import './index.css';
import './semantic/dist/semantic.css';
import { ROUTES } from './assets/constants';

ReactDOM.render(
    <BrowserRouter basename={ROUTES.UI}>
        <App />
    </BrowserRouter>, 
document.getElementById('root'));

//Unregister the serviceWorker
unregister();
