import React from 'react';
import { Button, Divider } from 'semantic-ui-react';
import { getItem } from '../../assets/localization';
import FeedList from '../kits/feed-kit/FeedList';
import { withRouter } from 'react-router';
import PermissionCheck from '../kits/layout-kit/PermissionCheck';
import rest from '../../util/rest';
import { getAPIUrl, getRouteUrl } from '../../util/url';
import RouteBreadcrumb from '../kits/base-kit/RouteBreadcrumb';
import { openNotification } from '../kits/base-kit/Notification';

class FeedManagerView extends React.Component<any, any> {
    constructor(props : any) {
        super(props);
        this.state = {
            isLoading: true,
            feeds: []
        }
    }

    handleCreate = () => {
        this.props.history.push(getRouteUrl("/feeds", "/new"));
    }
    
    handleGenerateID = async (id : string) => {
        try {
            await rest.patch(getAPIUrl("/feed"), id, null, this.props.history, true);
            await this.handleGet();
            openNotification("Uusi RSS-osoite luotu.");
        } catch (error) {
            openNotification(error.message);
        }
    }

    handleEdit = (id : any) => {
        this.props.history.push(getRouteUrl("/feeds", id));
    }

    handleDelete = async (id : any) => {
        try {
            await rest.delete(getAPIUrl("/feed"), id, this.props.history, true);
            await this.handleGet();
            openNotification("Syöte poistettu.");
        } catch (error) {
            openNotification(error.message);
        }
    }

    handleGet = async () => {
        try {
            let response = await rest.get(getAPIUrl("/feed"), null, this.props.history, true);
            this.setState({
                feeds: response
            });
        } catch (error) {
            openNotification(error.message);
        }
    }

    async componentDidMount() {
        await this.handleGet();
    }
    
    render() {
        return (
            <div>
                <RouteBreadcrumb items={[
                    {text: "Syötteet", url: "/feeds"}
                    ]} size="tiny"/>
                <Divider clearing hidden />
                <Button icon="plus" content="Luo uusi syöte" primary onClick={this.handleCreate}></Button>
                <Divider clearing hidden />
                <PermissionCheck permissions={this.props.permissions} check="canListFeeds" adminOverride>
                    <FeedList   isLoading={this.state.isLoading} 
                                categoryDict={this.props.categoryDict} 
                                items={this.state.feeds}
                                lang={this.props.lang}
                                handleGenerateID={this.handleGenerateID}
                                handleEdit={this.handleEdit}
                                handleDelete={this.handleDelete}
                                permissions={this.props.permissions}/>
                </PermissionCheck>
            </div>
        );
    }
}

export default withRouter(FeedManagerView);