import React from 'react';
import { List, Progress, Label, Icon } from 'semantic-ui-react';

export interface Props {
    label: string,
    percent: number,
    cancelToken?: any,
    indicating?: boolean,
    progress?: boolean
}

const cancelUpload = (props: Props) => {
    if(props.cancelToken) props.cancelToken.cancel("Tiedostolähetys keskeytetty.");
}

const FileUploadItem = (props: Props) => {
    return (
        <List.Item>
            <Progress
                percent={props.percent}
                indicating={props.indicating}
                size="small"
                progress={props.progress}>
                <Label onClick={() => cancelUpload(props)}>{props.label}<Icon name="close" /></Label>
            </Progress>
        </List.Item>
    );
};

export default FileUploadItem;