import React from 'react';

export interface Props {
    value: string,
    color?: string
}

const getBadgeStyle = (color?: string): any => {
    return {
        color: "#FFFFFF",
        backgroundColor: color && color.length > 0 ? color : "#ACACAC",
        fontSize: "10px",
        fontWeight: "bold",
        borderRadius: "5px",
        marginTop: "3px",
        marginRight: "5px",
        minWidth: "95px",
        height: "20px",
        lineHeight: "20px",
        padding: "0px 5px",
        display: "inline-block",
        textAlign: "center",
        verticalAlign: "middle"
    }
}

const Badge = (props: Props) => {
    return (
        <div style={getBadgeStyle(props.color)}>
            {props.value.toUpperCase()}
        </div>
    );
};

export default Badge;