import React from 'react';
import { Header, Button, Divider } from 'semantic-ui-react';
import { openNotification } from '../base-kit/Notification';
import rest from '../../../util/rest';
import { withRouter } from 'react-router';
import { SESSION_DURATION, SESSION_TIME_LEFT_POPUP, ROUTES } from '../../../assets/constants';
import { getAPIUrl, getRouteUrl } from '../../../util/url';
import { getSessionEnd, refreshSession, clearSession } from '../../../util/session';

let timeLeftInterval: any = undefined;

class SessionExpirationPage extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            sessionSecondsLeft: SESSION_TIME_LEFT_POPUP,
            initialized: false
        }
    }

    tick = () => {
        let sessionSecondsLeft = Math.floor((getSessionEnd() - Date.now() / 1000));
        this.setState({ sessionSecondsLeft: sessionSecondsLeft });
        if (sessionSecondsLeft <= 0) {
            this.logout();
        }
    }

    logout = async () => {
        window.clearInterval(timeLeftInterval);
        this.setState({
            sessionSecondsLeft: 0,
            initialized: false
        });
        this.props.handleClose(true);
        try {
            await rest.post(getAPIUrl("/logout"), undefined, history);
            clearSession();
            const location = {
                pathname: getRouteUrl(ROUTES.LOGIN),
                state: { lastPage: undefined }
            }
            this.props.history.push(location);
        } catch (error) {
            openNotification(error.message);
        }

    }

    refresh = async () => {
        window.clearInterval(timeLeftInterval);
        this.setState({
            sessionSecondsLeft: 0,
            initialized: false
        });
        this.props.handleClose(true);
        try {
            await rest.refresh(this.props.history);
        } catch (error) {
            openNotification(error.message);
        }
    }

    componentDidMount() {
        if (!this.state.initialized) {
            timeLeftInterval = window.setInterval(this.tick, 1000);
            this.setState({
                sessionSecondsLeft: Math.floor((getSessionEnd() - Date.now() / 1000)),
                initialized: true
            });
        }
    }

    render() {
        return (
            <div style={{ padding: "30px" }}>
                <Header size="medium">Istuntosi on päättymässä</Header>
                <Divider hidden clearing />
                <p style={{ textAlign: "left" }}>
                    Istunto päättyy <b>{this.state.sessionSecondsLeft} sekunnin</b> kuluttua.
                    Jos istuntosi päättyy, sinut kirjataan ulos automaattisesti.
                    Haluatko jatkaa käyttöä vai kirjautua ulos?
                </p>
                <Divider hidden clearing />
                <br />
                <Button primary content="Jatka käyttöä"
                    style={{ width: "200px", marginRight: "15px" }}
                    onClick={() => this.refresh()} />
                <Button content="Kirjaudu ulos"
                    style={{ width: "200px", marginRight: "15px" }}
                    onClick={() => this.logout()} />
            </div>
        );
    }
};

export default withRouter(SessionExpirationPage);