import React, { Component } from 'react';
import { Header, Form, Divider } from 'semantic-ui-react';
import rest from '../../util/rest';
import { getItem } from '../../assets/localization';
import { withRouter } from 'react-router';
import { getRouteUrl, getAPIUrl } from '../../util/url';
import { openNotification } from '../kits/base-kit/Notification';
import { ROUTES, MIN_INPUT_LENGTH } from '../../assets/constants';

class ChangePasswordView extends Component<any, any> {
    constructor(props : any) {
        super(props);
        this.state = {
            password: "",
            newpassword: "",
            retype: "",
            error: undefined
        }
    }

    handleChange = (event : any) => {
        this.setState({[event.target.id]: event.target.value});
    }

    handleBack = (event : any) => {
        event.preventDefault();
        this.props.history.goBack();
    }

    handleSubmit = async () => {
        try {
            let data = {
                password: this.state.password,
                newpassword: this.state.newpassword
            }
            await rest.post(getAPIUrl("/user", "/changePassword"), 
                            data, 
                            undefined, 
                            true);
            openNotification("Salasana vaihdettu.")
            this.props.history.push(getRouteUrl(ROUTES.NOTICES));
            return;
        } catch (error) {
            if(error.response.status === 403) {
                this.setState({error: "Nykyinen salasana on virheellinen."});
            }
            else if (error.response.status === 401) {
                openNotification(error.message);
                this.props.history.replace(getRouteUrl(ROUTES.LOGIN));
            }
            else{
                openNotification(error.message);
            }
        }
    }

    evaluateForm = () => {
        return  this.state.password.length >= MIN_INPUT_LENGTH &&
                this.state.newpassword.length >= MIN_INPUT_LENGTH &&
                this.state.retype === this.state.newpassword;
    }

    submitPasswords = (event: any) => {
        if(event) event.preventDefault();
        this.setState({error: undefined});
        if (this.state.newpassword !== this.state.retype) {
            return this.setState({error: "Salasanat eivät täsmää."});
        }
        this.handleSubmit();
    }
    
    render() {
        let passwordLabel = getItem(this.props.lang, "changepw-password");
        let newPasswordLabel = getItem(this.props.lang, "changepw-newPassword");
        let retypeLabel = getItem(this.props.lang, "changepw-retype");
        return (
            <div>
                <Divider clearing hidden section/>
                <br />
                <div style={{minWidth: "290px", maxWidth: "290px", textAlign: "center", margin: "auto"}}>
                    <Header style={{fontFamily: "Lato", fontWeight: "normal", fontSize:"26px"}}>{getItem(this.props.lang, "changepw-header")}</Header>
                    <Divider clearing hidden />
                    <Form>
                        <Form.Input type="password"
                            id="password"
                            icon="lock"
                            error={this.state.password.length > 0 && this.state.password.length < MIN_INPUT_LENGTH}
                            required={true}
                            placeholder={passwordLabel}
                            value={this.state.password}
                            onChange={this.handleChange} />
                        <Form.Input type="password"
                            id="newpassword"
                            icon="lock"
                            error={this.state.newpassword.length > 0 && this.state.newpassword.length < MIN_INPUT_LENGTH}
                            required={true}
                            placeholder={newPasswordLabel}
                            value={this.state.newpassword}
                            onChange={this.handleChange} />
                        <Form.Input type="password"
                            id="retype"
                            icon="lock"
                            error={this.state.retype.length > 0 && this.state.retype !== this.state.newpassword}
                            required={true}
                            placeholder={retypeLabel}
                            value={this.state.retype}
                            onChange={this.handleChange} />
                        {
                            this.state.error ?
                            <Header size="tiny" color="red">{this.state.error}</Header>
                            :
                            <Divider clearing section hidden />
                        }
                        <Form.Button fluid primary disabled={!this.evaluateForm()} onClick={this.submitPasswords}>{getItem(this.props.lang, "changepw-accept")}</Form.Button>
                        <Form.Button fluid onClick={this.handleBack}>{getItem(this.props.lang, "changepw-back")}</Form.Button>
                    </Form>
                </div>
            </div>
        );
    }
}

export default withRouter(ChangePasswordView);