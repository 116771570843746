// FIXME: Webpack would require 'json-loader' to effectively load this data from a JSON file.
// As of now we do not have access to the config files due to create-react-app hiding them.

const locale : any = {
    "fi": {
        "loginscreen-header":"Kirjaudu Farmahubiin",
        "loginscreen-login":"Kirjaudu sisään",
        "loginscreen-username":"Käyttäjänimi",
        "loginscreen-password":"Salasana",
        "changepw-header":"Vaihda salasana",
        "changepw-password":"Vanha salasana",
        "changepw-newPassword":"Uusi salasana",
        "changepw-retype":"Kirjoita salasana uudelleen",
        "changepw-accept":"Vaihda",
        "changepw-back":"Takaisin",
        "search-noresults":"Ei osumia",
        "loading":"Ladataan...",
        "nav-login":"Kirjaudu sisään",
        "nav-logout":"Kirjaudu ulos",
        "nav-changepw":"Vaihda salasana",
        "nav-notices":"Tiedotteet",
        "nav-feeds":"Syötteet",
        "confirmdialog-deleteHeader":"Poista tiedote",
        "confirmdialog-deleteDesc":"Oletko varma, että haluat poistaa tämän tiedotteen?",
        "confirmdialog-confirm":"Hyväksy",
        "confirmdialog-cancel":"Takaisin",

        "noticelist-header":"Tiedotteet",
        "noticelist-newbutton":"Luo uusi tiedote",
        "noticelist-sendingLabel":"Lähetetään",
        "noticelist-sentLabel":"Lähetetty",

        "notice-form-header-create":"Uusi tiedote",
        "notice-form-header-edit":"Muokkaa tiedotetta",
        "notice-form-title":"Otsikko",
        "notice-form-categories":"Kategoriat",
        "notice-form-description":"Kuvaus",
        "notice-form-activationDate":"Aktivointipäivämäärä",
        "notice-form-expirationDate":"Päättymispäivämäärä",
        "notice-form-archiveDate":"Arkistointipäivämäärä",
        "notice-form-requireLabel":"Vaaditut kuittaukset",
        "notice-form-noneRequired":"Ei vaadittu",
        "notice-form-requireRead":"Tiedote luettu",
        "notice-form-requireReact":"Tiedote luettu sekä vaaditut toimenpiteet suoritettu",
        "notice-form-requireAuthFalse":"Kaikki käyttäjät voivat kuitata",
        "notice-form-requireAuthTrue":"Vain pääkäyttäjä voi kuitata",
        "notice-form-requireFeedPassword":"Tiedote on salasanasuojattu",
        "notice-form-recipients":"Vastaanottajat",
        "notice-form-products":"Tuotteet",
        "notice-form-preview":"Esikatsele",
        "notice-form-save":"Tallenna",
        "notice-form-cancel":"Takaisin",
        "notice-form-saveTemplate":"Tallenna pohjaksi",
        
        "notice-view-header-view":"Tiedote",
        "notice-view-header-preview":"Tiedotteen esikatselu",
        "notice-view-markRead":"Merkitse luetuksi",
        "notice-view-markDone":"Merkitse suoritetuksi",
        "notice-view-markAllRead":"Merkitse viestit luetuksi",
        "notice-view-markAllDone":"Merkitse viestit suoritetuksi",
        "notice-view-submit":"Hyväksy",
        "notice-view-edit":"Muokkaa",
        "notice-view-delete":"Poista",
        "notice-view-back":"Takaisin",

        "feeds-header":"Syötteet",
        "feeds-new":"Uusi",
        "feeds-item-feedtitle":"Syötteen nimi",
        "feeds-item-categories":"Kategoriat",
        "feeds-item-id":"ID",

        "feed-form-header-new":"Uusi syöte",
        "feed-form-header-edit":"Muokkaa syötelistaa",
        "feed-form-apSystem":"Apteekkijärjestelmä",
        "feed-form-title":"Syötteen nimi",
        "feed-form-description":"Syötteen kuvaus",
        "feed-form-categories":"Syötteen kategoriat",
        "feed-form-back":"Takaisin",
        "feed-form-submit":"Tallenna",
        "feed-form-accept":"Hyväksy",
        "feed-form-password":"Syötteen salasana",

        "templates-header":"Listapohjat",
        "templates-new":"Uusi",
        "templates-item-name":"Listapohjan nimi",
        "templates-item-subscribers":"Vastaanottajat",
        "templates-item-id":"ID",

        "template-form-header-new":"Uusi listapohja",
        "template-form-header-edit":"Muokkaa listapohjaa",
        "template-form-name":"Listapohjan nimi",
        "template-form-pharmacies":"Listapohjan vastaanottajat",
        "template-form-back":"Takaisin",
        "template-form-submit":"Lisää",
        "template-form-accept":"Hyväksy",

        "noticelist-tabs": {
            "all":"Kaikki",
            "active":"Aktiiviset",
            "outbound":"Lähtevät",
            "expired":"Päättyneet",
            "archived":"Arkistoidut",
            "noack":"Ei kuitattu",
            "read":"Luettu",
            "acknowledged":"Suoritettu",
            "require1":"Vaatii kuittausta",
            "require2":"Vaatii toimenpiteitä"
        },
        "categories": {
            "withdrawal":"Takaisinveto",
            "pricechange":"Hinnanmuutos",
            "new":"Uutuus",
            "campaign":"Kampanja",
            "availability":"Saatavuus",
            "recruitment":"Rekrytointi",
            "training":"Koulutus",
            "notice":"Tiedote",
            "emergency":"Hätäviesti"
        },
        "pricing": {
            "breakdown": "Hintaerittely",
            "basenotice": "Perustiedote",
            "emergencynotice": "Hätätiedote",
            "categories": "Kategoriat",
            "readAck": "Lukukuittaus",
            "actionAck": "Toimenpidekuittaus",
            "subsum": "Välisumma",
            "urgency": "Nopean toimituksen lisä",
            "isChild": "Jatkotiedotealennus",
            "limitedDistribution": "Pienjakelualennus",
            "pilotDiscount": "Pilot-asiakkaan alennus",
            "total": "Yhteensä (ALV 0)",
            "perrecipient": " per vastaanottaja"
        }
    },
    
    "en": {
        "loginscreen-header":"Login to Farmahub",
        "loginscreen-login":"Login",
        "loginscreen-username":"Username",
        "loginscreen-password":"Password",
        "changepw-header":"Change password",
        "changepw-password":"Old password",
        "changepw-newPassword":"New password",
        "changepw-retype":"Retype password",
        "changepw-accept":"Accept",
        "changepw-back":"Back",
        "search-noresults":"No results found",
        "loading":"Loading...",
        "nav-login":"Login",
        "nav-logout":"Logout",
        "nav-changepw":"Change password",
        "nav-notices":"Notices",
        "nav-feeds":"Feeds",
        "confirmdialog-deleteHeader":"Delete notice",
        "confirmdialog-deleteDesc":"Are you sure you want to delete this notice?",
        "confirmdialog-confirm":"Confirm",
        "confirmdialog-cancel":"Cancel",

        "noticelist-header":"Notices",
        "noticelist-newbutton":"Create new notice",
        "noticelist-sendingLabel":"Sending",
        "noticelist-sentLabel":"Sent",

        "notice-form-header-create":"Create new notice",
        "notice-form-header-edit":"Edit notice",
        "notice-form-header-preview":"Notice preview",
        "notice-form-title":"Title",
        "notice-form-categories":"Categories",
        "notice-form-description":"Description",
        "notice-form-activationDate":"Activation date",
        "notice-form-expirationDate":"End date",
        "notice-form-requireLabel":"Required acknowledgements",
        "notice-form-noneRequired":"None required",
        "notice-form-requireRead":"Read notice",
        "notice-form-requireReact":"Read notice and did required procedures",
        "notice-form-requireAuthFalse":"All users can check out",
        "notice-form-requireAuthTrue":"Only the main user can check out",
        "notice-form-requireFeedPassword":"Notice is password protected",
        "notice-form-archiveDate":"Archive date",
        "notice-form-recipients":"Recipients",
        "notice-form-products":"Products",
        "notice-form-preview":"Preview",
        "notice-form-save":"Save",
        "notice-form-cancel":"Cancel",
        "notice-form-saveTemplate":"Save as Template",
        
        "notice-view-header-view":"Notice",
        "notice-view-header-preview":"Notice preview",
        "notice-view-markRead":"Mark as Read",
        "notice-view-markDone":"Mark as Done",
        "notice-view-submit":"Submit",
        "notice-view-edit":"Edit",
        "notice-view-delete":"Delete",
        "notice-view-back":"Back",

        "feeds-header":"Feeds",
        "feeds-new":"New",
        "feeds-item-feedtitle":"Feed title",
        "feeds-item-categories":"Categories",
        "feeds-item-id":"ID",

        "feed-form-header-new":"New Feed",
        "feed-form-header-edit":"Edit Feed",
        "feed-form-apSystem":"Apothecary system",
        "feed-form-name":"Feed title",
        "feed-form-description":"Feed description",
        "feed-form-categories":"Feed categories",
        "feed-form-back":"Cancel",
        "feed-form-submit":"Submit",
        "feed-form-accept":"Accept",
        "feed-form-password":"Feed password",

        "templates-header":"List Templates",
        "templates-new":"New",
        "templates-item-name":"Template name",
        "templates-item-subscribers":"Pharmacies",
        "templates-item-id":"ID",

        "template-form-header-new":"New template",
        "template-form-header-edit":"Edit template",
        "template-form-title":"Template name",
        "template-form-pharmacies":"Template pharmacies",
        "template-form-back":"Back",
        "template-form-submit":"Create",
        "template-form-accept":"Accept",

        "noticelist-tabs": {
            "all":"All",
            "active":"Active",
            "outbound":"Outbound",
            "expired":"Expired",
            "archived":"Archived",
            "noack":"Not seen",
            "read":"Read",
            "acknowledged":"Acknowledged",
            "require1":"Requires check",
            "require2":"Requires actions"
        },
        "categories": {
            "withdrawal":"Withdrawal",
            "pricechange":"Price Change",
            "new":"New",
            "campaign":"Campaign",
            "availability":"Availability",
            "recruitment":"Recruitment",
            "training":"Training",
            "notice":"Notice",
            "emergency":"Emergency"
        },
        "pricing": {
            "breakdown": "Price Breakdown",
            "basenotice": "Basic notice",
            "emergencynotice": "Emergency notice",
            "categories": "Categories",
            "readAck": "Read acknowledgement",
            "actionAck": "Procedure acknowledgement",
            "subsum": "Sub total",
            "urgency": "Urgency",
            "isChild": "Additional notice discount",
            "limitedDistribution": "Limited distribution",
            "pilotDiscount": "Pilot customer discount",
            "total": "Total (VAT 0)",
            "perrecipient": " per recipient"
        }
    }
}

export function getItem(language : string, itemKey : string) {
    let item = locale[language][itemKey];
    if (item == null) throw Error("Item '"+itemKey+"' does not exist in language '"+language+"'");
    return item;
}