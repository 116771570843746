import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import NoticeList from '../kits/notice-kit/NoticeList';
import { Button, Divider, List, Segment } from 'semantic-ui-react';
import { getItem } from '../../assets/localization';
import { Link } from 'react-router-dom';
import FormSearch from '../kits/form-kit/FormSearch';
import { getAPIUrl, getRouteUrl, getFullRouteUrl } from '../../util/url';
import rest from '../../util/rest';
import { ITEMS_PER_PAGE, ROUTES, COLORS } from '../../assets/constants';
import Tabs from '../kits/layout-kit/Tabs';
import PageMenu from '../kits/base-kit/PageMenu';
import { refreshTimeout } from '../../util/timeout';
import RouteBreadcrumb from '../kits/base-kit/RouteBreadcrumb';
import { openNotification } from '../kits/base-kit/Notification';
import FeedDropdown from '../kits/notice-kit/FeedDropdown';
import CategoryDropdown from '../kits/base-kit/CategoryDropdown';
import ColorCircle from '../kits/base-kit/ColorCircle';

export interface Props extends RouteComponentProps<any> {
    lang: string,
    categoryOptions: any,
    categoryDict: any,
    productOptions: any,
    productDict: any,
    permissions?: any,
    uuid?: any
}

const getAcklist = (noticeList: Array<any>, ackState: number) => ({ notices: noticeList.map(notice => notice.id), ack: ackState })

const getTabs = (lang: string, permissions: any) => {
    let tabs = getItem(lang, "noticelist-tabs");
    if (permissions && permissions.isAdmin) {
        return [
            { key: "all", value: tabs["all"] },
            { key: "outbound", value: tabs["outbound"] },
            { key: "active", value: tabs["active"] },
            { key: "expired", value: tabs["expired"] },
            { key: "archived", value: tabs["archived"] }
        ];
    } else {
        if (permissions && permissions.canSeeTabs) {
            return [
                { key: "all", value: tabs["all"] },
                { key: "outbound", value: tabs["outbound"] },
                { key: "active", value: tabs["active"] },
                { key: "expired", value: tabs["expired"] }
            ];
        } else {
            return [
                { key: "all", value: tabs["all"] },
                { key: "noack", value: tabs["noack"] },
                { key: "require1", value: tabs["require1"] },
                { key: "require2", value: tabs["require2"] },
                { key: "read", value: tabs["read"] },
                { key: "acknowledged", value: tabs["acknowledged"] }
            ];
        }
    }
}

class NoticeListView extends React.Component<Props, any> {
    constructor(props: Props) {
        super(props);
        this.state = {
            items: [],
            isLoading: true,
            inputTimeout: null,
            categoryOptions: null,
            activePage: 1,
            totalPages: 1,
            queryMatch: "",
            queryCategories: [],
            queryType: "all",
            feedList: [],
            activeFeed: undefined
        }
    }

    _isMounted = false;

    handleChange = (key: any, value: any, delay: number) => {
        const timeout = refreshTimeout(this.state.inputTimeout, this.handleGet, delay !== undefined ? delay : 0);
        this.setState((prevState: any) => ({
            ...prevState,
            [key]: value,
            inputTimeout: timeout
        }));
    }

    handleGet = async () => {
        if (this._isMounted) {
            this.setState((prevState: any) => ({
                ...prevState,
                isLoading: true,
                inputTimeout: null
            }));
            try {
                let query = {
                    match: this.state.queryMatch,
                    order: "newest",
                    categories: this.state.queryCategories,
                    type: this.state.queryType,
                    offset: (this.state.activePage - 1) * ITEMS_PER_PAGE,
                    limit: ITEMS_PER_PAGE
                }
                let url = this.props.uuid ?
                    getAPIUrl("/notice", "/feed", this.props.uuid) :
                    getAPIUrl("/notice");
                let response = await rest.get(url, query, this.props.history, this.props.uuid ? false : true);
                if (response && this._isMounted) {
                    this.setState((prevState: any) => ({
                        ...prevState,
                        items: response.items,
                        isLoading: false,
                        totalPages: Math.max(Math.ceil(parseInt(response.totalItems, 10) / ITEMS_PER_PAGE), 1)
                    }));
                }
            } catch (error) {
                openNotification(error.message);
            }
        }
    }

    handleAck = async (ackState: number) => {
        const body = getAcklist(this.state.items, ackState);
        const url = this.props.uuid
            ? getAPIUrl("/notice", "/acknowledge", "/feed", this.props.uuid)
            : getAPIUrl("/notice", "/acknowledgeauth");

        try {
            await rest.patch(url, "", body);
        } catch (error) {
            console.error(error);
        }
    }

    async componentDidMount() {
        this._isMounted = true;
        if (this.props.uuid) {
            try {
                const response = await rest.get(getAPIUrl("/feed", this.props.uuid, "?list=all"));
                if (response) {
                    this.setState({
                        feedList: response
                    });
                }
            } catch (error) {
                if (error.response.status === 409) {
                    window.open(getFullRouteUrl("/"), "_self");
                }
                openNotification(error.message);
            }
        }
        await this.handleGet();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        return (
            <div>
                <RouteBreadcrumb items={[
                    { text: "Tiedotteet", url: "/notices" + this.props.location.search }
                ]} size="tiny" />

                <Divider clearing hidden />

                {/* Controls & Search */}
                <List horizontal style={{ display: "flex", flexWrap: "wrap" }}>
                    {
                        this.props.permissions && (this.props.permissions.canCreateNotices || this.props.permissions.isAdmin) ?
                            <List.Item>
                                <Link to={getRouteUrl("/notices", "/new")}>
                                    <Button primary fluid icon="plus"
                                        content={getItem(this.props.lang, "noticelist-newbutton")} />

                                </Link>
                            </List.Item>
                            :
                            null
                    }
                    {
                        this.props.uuid ?
                            <List.Item>
                                <FeedDropdown items={this.state.feedList} value={this.props.uuid} />
                            </List.Item>
                            : null
                    }
                    <List.Item>
                        <FormSearch id="queryMatch"
                            placeholder="Hae tiedotteista"
                            value={this.state.queryMatch}
                            onChange={(key: any, value: any) => this.handleChange(key, value, 750)}
                        />
                    </List.Item>
                    <List.Item>
                        <CategoryDropdown id="queryCategories"
                            value={this.state.queryCategories}
                            categoryDict={this.props.categoryDict}
                            handleChange={this.handleChange}
                            header="Rajaa Kategoriat"
                            headerIcon="tags" />
                    </List.Item>
                    <List.Item style={{ flexGrow: "1" }}>
                    </List.Item>
                    {
                        !this.props.uuid && this.props.permissions.canAck ?
                            <List.Item style={{ marginLeft: "0px" }}>
                                <Button style={{ marginRight: "15px" }} size="medium" content={getItem(this.props.lang, "notice-view-markAllRead")} onClick={() => this.handleAck(1)} primary></Button>
                                <Button size="medium" content={getItem(this.props.lang, "notice-view-markAllDone")} onClick={() => this.handleAck(2)} primary></Button>
                            </List.Item>
                            : null
                    }
                </List>

                {/* Tabs */}
                <Tabs id="queryType"
                    fluid
                    items={getTabs(this.props.lang, this.props.permissions)}
                    activeItem={this.state.queryType}
                    onChange={this.handleChange} />

                {/* Descriptors */}
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ display: "flex" }}>
                        <ColorCircle size="10px" color={COLORS.lightGreen} margin="4px 5px 0 0" />
                        <h5 style={{ color: COLORS.grey, fontSize: "12px", margin: "1px 0 0 0" }}>
                            - Vaaditaan lukukuittaus
                        </h5>
                    </div>
                    <div style={{ display: "flex" }}>
                        <ColorCircle size="10px" color={COLORS.yellow} margin="4px 5px 0 0" />
                        <h5 style={{ color: COLORS.grey, fontSize: "12px", margin: "1px 0 0 0" }}>
                            - Vaaditaan toimenpiteitä
                        </h5>
                    </div>
                </div>

                {/* List */}
                {
                    this.state.items.length === 0 && !this.state.isLoading ?
                        <div>
                            <Segment basic textAlign="center" size="huge" style={{ width: "165px", height: "60px", margin: "auto" }}>Ei tiedotteita</Segment>
                        </div>
                        :
                        <div>
                            <NoticeList isLoading={this.state.isLoading} items={this.state.items} lang={this.props.lang} categoryDict={this.props.categoryDict} productDict={this.props.productDict} />
                            <Divider clearing hidden />
                            <PageMenu activePage={this.state.activePage} totalPages={this.state.totalPages} onPageChange={this.handleChange} />
                        </div>
                }
            </div>
        );
    }
}

export default withRouter(NoticeListView);