import React from 'react';

export interface Props {
    color?: string,
    margin?: string,
    padding?: string,
    border?: string,
    size?: string
}

const getCircleStyle = (props: Props): any => {
    return {
        backgroundColor: props.color || "#ACACAC",
        borderRadius: "100%",
        border: props.border || "0",
        margin: props.margin || "0 5px 0 0",
        height: props.size || "12px",
        width: props.size || "12px",
        padding: props.padding || "0px",
        display: "inline-block",
        textAlign: "center",
        verticalAlign: "middle"
    }
}

const ColorCircle = (props: Props) => {
    return (
        <div style={getCircleStyle(props)}>
        </div>
    );
};

export default ColorCircle;