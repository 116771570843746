export const copyToClipboard = (value: string) => {
    const el = document.createElement('textarea');  
        el.value = value;                                 
        el.setAttribute('readonly', '');                
        el.style.position = 'absolute';                 
        el.style.left = '-9999px';                      
        document.body.appendChild(el);    
        let selection = document.getSelection();              
        const selected =            
            selection != null && selection.rangeCount > 0        
            ? selection.getRangeAt(0)     
            : false;                                    
        el.select();                                   
        document.execCommand('copy');                  
        document.body.removeChild(el);          
        selection = document.getSelection();     
        if (selected && selection) {                              
            selection.removeAllRanges();   
            selection.addRange(selected);   
        }
}