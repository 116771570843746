import React from 'react';
import NavBar from '../kits/layout-kit/NavBar';
import Layout from '../kits/layout-kit/Layout';
import LoginView from '../views/LoginView';
import { withRouter, Route, Switch, Redirect } from 'react-router';
import ChangePasswordView from '../views/ChangePasswordView';
import { getRouteUrl } from '../../util/url';

const AuthRoutes = (props: any) => {
    return (
        <div>
            <NavBar lang={props.lang} handleLanguangeChange={props.handleLanguangeChange} headerOnly />
            <Layout textAlign="center">
                <Switch>
                    <Route path={getRouteUrl("/auth", "/login")} render={
                        (p) => <LoginView {...p} lang={props.lang} handlePermissionChange={props.handlePermissionChange} />
                    } />
                    <Route path={getRouteUrl("/auth", "/changepw")} render={
                        (p) => <ChangePasswordView {...p} lang={props.lang} handlePermissionChange={props.handlePermissionChange} />
                    } />
                    {/* REDIRECT */}
                    <Route path={getRouteUrl("/auth", "/*")} render={
                        (p) => <Redirect to={getRouteUrl("/auth", "/login")} />
                    } />
                </Switch>
            </Layout>
        </div>

    );
};

export default withRouter(AuthRoutes);