import React from 'react';
import { Header, Divider, Button } from 'semantic-ui-react';

export interface Props {
    content: any,
    header?: any,
    basic?: boolean,
    confirmLabel?: string,
    cancelLabel?: string,
    onConfirm?: any,
    onCancel?: any,
    handleClose?: any
}

class ModalDialogPage extends React.Component<Props, any> {
    render() {
        return (
            <div style={{ padding: "30px" }}>
                {
                    this.props.header ?
                        <div>
                            <Header>{this.props.header}</Header>
                            <Divider hidden clearing />
                        </div>
                        : null
                }
                <p style={{ textAlign: "left" }}>{this.props.content}</p>
                <Divider hidden clearing />
                <br />
                <Button primary content={this.props.confirmLabel || "Hyväksy"}
                    style={{ width: "200px", marginRight: "15px" }}
                    onClick={() => this.props.handleClose(false)} />
                <Button content={this.props.cancelLabel || "Takaisin"}
                    style={{ width: "200px", marginRight: "15px" }}
                    onClick={() => this.props.handleClose(true)} />
            </div>
        );
    }
}

export default ModalDialogPage;