import React from 'react';
import { Snackbar, Fade } from '@material-ui/core';
import { Icon } from 'semantic-ui-react';

export let openNotification = (message: string) => { };

class Notification extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            open: false,
            message: ""
        }
    }

    componentDidMount() {
        openNotification = this.handleOpen;
    }

    handleOpen = (message: string) => {
        if (!message || message.length === 0) return;
        this.setState({
            open: true,
            message: message
        })
    }

    handleClose = () => { this.setState({ open: false }) }

    render() {
        return (
            <Snackbar anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                autoHideDuration={6000}
                message={this.state.message}
                color=""
                TransitionComponent={Fade}
                action={<Icon link name="close" style={{ lineHeight: "14px" }} onClick={this.handleClose} />}
                open={this.state.open}
                onClose={this.handleClose} />
        );
    }
};

export default Notification;