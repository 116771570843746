import React from 'react';
import { Modal, Header, Segment, Input, Table, Button, Dropdown, Menu } from 'semantic-ui-react';
import Tabs, { TabItem, testTab } from '../layout-kit/Tabs';
import { refreshTimeout } from '../../../util/timeout';
import ModalListItem, { KeyElement } from './ModalListItem';
import PageMenu from '../base-kit/PageMenu';
import FormInput from "../form-kit/FormInput";
import ModalDialogPage from './ModalDialogPage';
import { ModalExternalState, openModal } from './ModalWindow';
import { openNotification } from '../base-kit/Notification';

const ITEMS_PER_PAGE = 100;
const DEFAULT_TABS = [
    { key: "all", value: "Kaikki" },
    { key: "selected", value: "Valitut" }
];

let templateName = "";

export interface Props {
    rowKeyElements: KeyElement[],
    lang: string,
    data?: any,
    canCancel?: boolean,
    canAccept?: boolean,
    canCreateList?: boolean,
    canOpenList?: boolean,
    canDeleteList?: boolean,
    tabs?: TabItem[] | undefined,
    tabTestKey?: string | number,
    tabTestMethod?: string,
    searchable?: boolean,
    searchKeys?: string[],
    searchMatchFunc?: any,
    searchPlaceholder?: string,
    onUnmount?: any,
    selectable?: boolean,
    selected?: string[] | number[],
    header?: string,
    scrolling?: boolean,
    handleClose?: any,
    openSelf?: any,
    saveTemplate?: any,
    getTemplateItems?: any,
    singleSelection?: boolean,
    requestTemplateItems?: any,
    deleteTemplateItem?: any,
    usedTemplate?: string
}

async function getSelectedSubscribers(options: any, selections: any) {
    let selectedForTemplate: string[] = [];
    for (var i = 0; i < selections.length; i++) {
        for (var m = 0; m < options.length; m++) {
            if (options[m].id === selections[i]) {
                selectedForTemplate.push(options[m]);
                break;
            }
        }
    }
    return selectedForTemplate;
}

class ModalListPage extends React.Component<Props, any> {
    constructor(props: Props) {
        super(props);
        this.state = {
            searchLoading: false,
            isLoading: true,
            search: "",
            inputTimeout: null,
            queryType: "all",
            queryTabIndex: 0,
            searchResults: [],
            selected: {},
            totalPages: 1,
            activePage: 1,
            templates: []
        };
    }

    componentDidMount() {
        let selected: any = {};
        if (this.props.selectable && this.props.selected) {
            for (let i = 0; i < this.props.selected.length; i++) {
                selected[this.props.selected[i]] = true;
            }
        }
        let queryType = this.props.tabs ? this.props.tabs[0].key : DEFAULT_TABS[0].key;
        this.setState({
            searchLoading: false,
            isLoading: true,
            search: "",
            inputTimeout: null,
            queryType: queryType,
            searchResults: this.props.data,
            selected: selected,
            totalPages: this.props.data ? Math.max(Math.ceil(this.props.data.length / ITEMS_PER_PAGE), 1) : 0,
            activePage: 1
        })
    }

    onPageChange = (key: string, activePage: number) => {
        this.setState({ [key]: activePage });
    }

    toggleSelected = (itemId: string | number) => {
        if (this.props.selectable) {
            let newSelected = {
                ...this.state.selected
            };
            let searchResults = this.state.searchResults;
            // We only want to keep the selected keys in the Dict
            if (!this.state.selected[itemId]) {
                newSelected[itemId] = true;
            }
            else {
                delete newSelected[itemId];
                if (this.state.queryType === "selected") {
                    searchResults = this.state.searchResults.filter(
                        (item: any) => item.id !== itemId);
                }
            }
            this.setState({ selected: newSelected, searchResults: searchResults });
        }
    }

    performSearch = () => {
        const queryType = this.state.queryType;
        const queryTabIndex = this.state.queryTabIndex;

        const isMatch = (item: any) => {
            let found = true;

            // Test against selected value
            if (this.props.tabs) {
                if (queryType !== this.props.tabs[0].key) {
                    found = testTab(queryTabIndex, item, this.props.tabs);
                }
            } else if (this.state.queryType === "selected") {
                found = this.state.selected[item.id] !== undefined;
            }

            // Test against RegExp
            if (found && this.props.searchKeys != undefined) {
                const regexp = new RegExp(this.state.search, "i");
                // Test through all the given search keys
                for (let i = 0; i < this.props.searchKeys.length; i++) {
                    found = regexp.test(item[this.props.searchKeys[i]]);
                    if (found) break;
                }
            }
            return found;
        }

        const results = this.props.data.filter(this.props.searchMatchFunc || isMatch);

        this.setState((prevState: any) => ({
            ...prevState,
            queryType: queryType,
            searchLoading: false,
            searchResults: results,
            totalPages: Math.max(Math.ceil(results.length / ITEMS_PER_PAGE), 1),
            activePage: 1,
            templateUsed: false
        }));
    }

    handleSearchChange = (e: any, { value }: any) => {
        const timeout = refreshTimeout(this.state.inputTimeout, this.performSearch, 300);
        this.setState({
            searchLoading: true,
            search: value,
            inputTimeout: timeout
        });
    }

    handleTabChange = (key: string, value: any) => {
        let newTabIndex = 0;
        if (this.props.tabs) {
            newTabIndex = this.props.tabs.findIndex(x => x.key === value);
            newTabIndex = newTabIndex > -1 ? newTabIndex : 0;
        }
        const timeout = refreshTimeout(this.state.inputTimeout, this.performSearch, 300);
        this.setState({
            [key]: value,
            queryTabIndex: newTabIndex,
            searchLoading: true,
            inputTimeout: timeout
        });
    }

    handleTemplateNameChange = (e: any, value: any) => {
        templateName = value;
    }

    closeTemplateModal = async (action: boolean) => {
        if (action === false) {
            await this.props.saveTemplate({ name: templateName, pharmacies: this.state.templates.map((x: any) => (x.id)) });
            this.props.openSelf();
        }
        else {
            templateName = "";
            this.props.openSelf();
        }
    }

    closeTemplateSelectModal = async (action: boolean, selected: any) => {
        let selections = [];
        let templateName;
        if (action === false) {
            if (selected && selected[0]) {
                let template = await this.props.requestTemplateItems(selected[0]);
                template = template[0];
                for (var i = 0; i < template.pharmacies.length; i++) {
                    selections.push([template.pharmacies[i]]);
                }
                templateName = template.name;
            }
            this.props.openSelf(null, null, selections, templateName);
        }
        else {
            this.props.openSelf();
        }  

    }

    templateDelete = async (selected: any) => {
        if (selected && selected.length > 0) {
            this.setState({ isLoading: true });
            await this.props.deleteTemplateItem(selected[0]);
            let response = await this.props.getTemplateItems();
            this.setState({ selected: {}, isLoading: false, searchResults: response });
        }
        else {
            openNotification("Et ole valinnut listapohjaa poistettavaksi.");
        }
    }

    openTemplateSave = async (selected: Props["selected"]) => {
        if (!selected || selected.length === 0) {
            return
        }
        let selectedKeys = Object.keys(this.state.selected);
        let selectedForTemplate = await getSelectedSubscribers(this.state.searchResults, selectedKeys);
        this.setState({ templates: selectedForTemplate });
        this.props.handleClose(false, selectedKeys)
        let state: ModalExternalState = {
            render: (props: any) => <ModalDialogPage
                header={"Luo uusi listapohja"}
                handleClose={this.closeTemplateModal}
                content={
                    <div>
                        <FormInput
                            id="templateName"
                            type="text"
                            required
                            max={160}
                            value={this.state.templateName}
                            /*
                            error={
                                this.props.feed.title.length > 0 &&
                                this.props.feed.title.length < MIN_INPUT_LENGTH
                            }
                            */
                            placeholder="Syötä listapohjan nimi"
                            onChange={this.handleTemplateNameChange}
                        />
                        <div style={{ "marginTop": "8px" }}>
                            Olet valinnut listapohjaan seuraavat vastaanottajat:
                            <table style={{ "marginTop": "16px" }}>
                                {
                                    this.state.templates.map((item: any) =>
                                        <tr>
                                            <td key={item.oid}>{item.name}</td>
                                        </tr>
                                    )
                                }
                            </table>
                        </div>
                    </div>}
            />
        }
        openModal(state)
    }

    openTemplateSelection = () => {
        let state: ModalExternalState = {
            render: (props: any) => <ModalListPage
                {...props}
                canCreateList={false}
                canOpenList={false}
                canDeleteList={true}
                lang={this.props.lang}
                header="Valitse vastaanottajalistapohja"
                selectable singleSelection scrolling
                rowKeyElements={[
                    { key: "name", title: "Nimi" },
                    { key: "pharmacies", title: "Valintojen lukumäärä" }
                ]}
                tabs={[{ "key": "all", "value": "Kaikki" }]}
                canAccept={true}
                canCancel={true}
                handleClose={this.closeTemplateSelectModal}
                deleteTemplateItem={this.props.deleteTemplateItem}
                getTemplateItems={this.props.getTemplateItems}
            />,
            onOpen: this.props.getTemplateItems,
            onClose: this.closeTemplateSelectModal
        };
        openModal(state);
    }

    drawPage = () => {
        let items = this.state.searchResults ? this.state.searchResults : [];
        let page = [];
        let start = (this.state.activePage - 1) * ITEMS_PER_PAGE;
        let end = Math.min(this.state.activePage * ITEMS_PER_PAGE, items.length);
        for (let i = start; i < end; i++) {
            page.push(<ModalListItem key={items[i].id} item={items[i]}
                singleSelection={this.props.singleSelection}
                rowKeyElements={this.props.rowKeyElements}
                selectable={this.props.selectable}
                selected={this.state.selected}
                onSelect={this.toggleSelected} />);
        }
        return page;
    }

    render() {
        const pageHeight = screen.height * 0.35;
        return (
            <div style={{ padding: "25px" }}>
                <Header size="large">{this.props.header}</Header>
                {
                    this.props.searchable ?
                        <div>

                            <Input type="text"
                                icon="search"
                                loading={this.state.searchLoading}
                                placeholder={this.props.searchPlaceholder}
                                onChange={this.handleSearchChange}
                                value={this.state.value}
                                results={this.state.searchResults} />


                        </div> : null
                }

                {
                    this.props.usedTemplate ? 
                        <div >
                            <Header size='small' floated="right">Listapohja: {this.props.usedTemplate}</Header>
                        </div> : null

                }

                <br />

                <Tabs id="queryType" fluid
                    items={this.props.tabs || DEFAULT_TABS}
                    activeItem={this.state.queryType}
                    onChange={this.handleTabChange} />

                <Modal.Content scrolling={this.props.scrolling} style={{ minHeight: pageHeight, maxHeight: pageHeight }}>
                    <Table basic="very" >
                        <Table.Header>
                            <Table.Row>
                                {
                                    this.props.rowKeyElements.map((pair: any) =>
                                        <Table.Cell key={pair.key}><h5>{pair.title}</h5></Table.Cell>
                                    )
                                }
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {
                                this.drawPage()
                            }
                        </Table.Body>
                    </Table>
                </Modal.Content>
                <br />
                <PageMenu activePage={this.state.activePage}
                    totalPages={this.state.totalPages}
                    onPageChange={this.onPageChange} />
                <div style={{ paddingTop: "25px" }}>
                    {
                        this.props.canCancel ?
                            <Button size="large" style={{ marginRight: "15px" }} content="Takaisin" onClick={() => this.props.handleClose(true)} />
                            : null
                    }
                    {
                        this.props.canAccept ?
                            <Button size="large" style={{ marginRight: "15px" }} primary content="Hyväksy" onClick={() => this.props.handleClose(false, Object.keys(this.state.selected))} />
                            : null
                    }

                    {
                        this.props.canDeleteList ?
                            <Button size="large" style={{ marginLeft: "15px", marginRight: "0px" }} floated='right' disabled={Object.keys(this.state.selected).length == 0} content="Poista listapohja" onClick={() => this.templateDelete(Object.keys(this.state.selected))} />
                            : null
                    }

                    {
                        this.props.canCreateList ?
                            <Button size="large" style={{ marginLeft: "15px", marginRight: "0px" }} floated='right' disabled={Object.keys(this.state.selected).length == 0} content="Tallenna listapohjaksi" onClick={() => this.openTemplateSave(Object.keys(this.state.selected))} />
                            : null
                    }
                    {
                        this.props.canCreateList ?
                            <Button size="large" style={{ marginLeft: "15px", marginRight: "0px" }} floated='right' content="Käytä listapohjaa" onClick={this.openTemplateSelection} />
                            : null
                    }
                </div>
            </div>
        );
    }
}

export default ModalListPage;