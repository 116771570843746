import React from 'react';
import { Modal, Button, Placeholder } from 'semantic-ui-react';
import { openNotification } from '../base-kit/Notification';
import PlaceholderBlock from '../layout-kit/PlaceholderBlock';

interface ModalInternalState {
    open: boolean,
    isLoading: boolean,
    data: any
}

export interface ModalExternalState {
    render: any,
    style?: any,
    onOpen?: any,
    size?: any,
    onClose?: any,
    closeOnDimmerClick?: boolean,
    basic?: boolean,
    canCancel?: boolean,
    canAccept?: boolean,
    canCreateList?: boolean,
    canOpenList?: boolean
}

type ModalState = ModalExternalState & ModalInternalState;

const emptyState = {
    open: false,
    isLoading: true,
    size: undefined,
    render: undefined,
    onOpen: undefined,
    onClose: undefined,
    data: undefined,
    canCancel: true,
    canAccept: false,
    canCreateList: false,
    canOpenList: false
}

export let openModal = (state: ModalExternalState) => { };

class ModalWindow extends React.Component<any, ModalState> {
    constructor(props: any) {
        super(props);
        this.state = {
            ...emptyState
        };
    }

    handleOpen = async (state: ModalExternalState) => {
        let data = undefined;
        if (state.onOpen) {
            try {
                // Open window, start loading and do what is told
                this.setState({ open: true, isLoading: true })
                data = await state.onOpen();
            } catch (error) {
                // Show error, close the window and return
                openNotification(error.message);
                return this.setState({ open: false });
            }
        }
        // If no errors, set state whether or not we have data
        this.setState({
            ...state,
            data: data,
            isLoading: false,
            open: true
        });
    }

    handleClose = (cancel: boolean, ...args: any) => {
        // If we're not canceling, call function with given arguments
        if (!cancel) this.state.onClose(...args || undefined);
        this.setState({
            ...emptyState
        });
    }

    componentDidMount() {
        openModal = this.handleOpen;
    }

    renderComponent = () => {
        let Component = (props: any) => <this.state.render {...props} />;
        return (
            this.state.render && this.state.open === true && this.state.isLoading === false ?
                <Component data={this.state.data} canAccept={this.state.canAccept} canCancel={this.state.canCancel} canOpenList={this.state.canOpenList} canCreateList={this.state.canCreateList} handleClose={this.handleClose} />
                : null
        );
    }

    render() {
        return (
            <Modal open={this.state.open}
                onClose={() => this.handleClose(true)}
                style={this.state.style || {}}
                size={this.state.size}
                closeOnDimmerClick={this.state.closeOnDimmerClick}
                basic={this.state.basic} >
                <PlaceholderBlock lines={4}
                    paragraphs={8}
                    lang={"fi"}
                    inverted
                    isLoading={this.state.isLoading}>
                    {
                        this.renderComponent()
                    }
                </PlaceholderBlock>
            </Modal>
        );
    }
}

export default ModalWindow;