import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Dropdown, Grid, Segment, List } from 'semantic-ui-react';
import { getItem } from '../../../assets/localization';
import { getAPIUrl, getRouteUrl, getFullRouteUrl } from '../../../util/url';
import rest from '../../../util/rest';
import Layout from './Layout';
import "../../../styles/components/NavBar.css";
import { ROUTES } from '../../../assets/constants';
import { openNotification } from '../base-kit/Notification';
import { ModalExternalState, openModal } from '../modal-kit/ModalWindow';
import ModalDialogPage from '../modal-kit/ModalDialogPage';
import { clearSession } from '../../../util/session';

interface Props extends RouteComponentProps<any> {
    lang: string,
    handleLanguangeChange?: any,
    handlePermissionChange?: any,
    hasFeeds?: boolean,
    hasPharmacyTemplates?: boolean,
    hasNotices?: boolean,
    hasLanguange?: boolean,
    hasChangePw?: boolean,
    hasLogout?: boolean,
    hasLogin?: boolean,
    hasGotoUser?: boolean,
    hasContact?: boolean,
    headerOnly?: boolean,
    centered?: boolean
}

const openLogoutPopup = (history: any) => {
    let state: ModalExternalState = {
        render: (props: any) => <ModalDialogPage {...props}
            content="Oletko varma että haluat kirjautua ulos?" />,
        size: "tiny",
        onClose: async () => handleLogout(history)
    }
    openModal(state)
}

const handleLogout = async (history: any) => {
    let url = getAPIUrl("/logout");
    try {
        await rest.post(url, null, history);
        clearSession();
        const location = {
            pathname: getRouteUrl(ROUTES.LOGIN),
            state: { lastPage: undefined }
        }
        history.push(location);
    } catch (error) {
        //We should probably do nothing here as the redirection happens automatically
        openNotification(error.message);
    }
};

const handleLink = (url: string, includeQuery: boolean, props: any) => {
    const curPath = props.location.pathname;
    const location = {
        pathname: url,
        search: includeQuery ? props.location.search : undefined,
        state: { lastPage: curPath + (includeQuery ? props.location.search : "") }
    }
    props.history.push(location);
}

const handleUserViewChange = (props: any) => {
    const curPath = props.location.pathname;
    window.open(getFullRouteUrl(curPath), "_self");
}

const NavBar = (props: Props) => {
    let currentPage = props.location.pathname.split("/")[1] || "";
    return (
        <Segment inverted color="blue" basic style={{ height: "56px" }} clearing>
            {
                props.headerOnly ?
                    <Layout>
                        <List floated="left" verticalAlign="middle" horizontal inverted relaxed style={{ paddingTop: "12px" }}>
                            <List.Item onClick={() => handleLink(getRouteUrl("/"), true, props)} >
                                <h1 className="navlogo" >Farmahubi</h1>
                            </List.Item>
                        </List>
                        <List floated="right" verticalAlign="middle" horizontal inverted relaxed style={{ paddingTop: "12px" }}>
                            <List.Item onClick={() => handleLink(getRouteUrl("/contact"), false, props)} >
                                <h3 className={currentPage === "contact" ? "navbuttoncurrent" : "navbutton"}>Ota Yhteyttä</h3>
                            </List.Item>
                            <List.Item onClick={() => handleLink(getRouteUrl("/", props.location.search), true, props)} >
                                <h3 className={currentPage === "auth" ? "navbuttoncurrent" : "navbutton"}>Kirjaudu sisään</h3>
                            </List.Item>
                        </List>
                        <Grid>

                        </Grid>
                    </Layout>
                    :
                    <Layout>
                        <List floated="left" verticalAlign="middle" horizontal inverted relaxed style={{ paddingTop: "12px" }}>
                            <List.Item onClick={() => handleLink(getRouteUrl("/notices"), true, props)}>
                                <h1 className="navlogo">Farmahubi</h1>
                            </List.Item>
                            {
                                props.hasNotices ?
                                    <List.Item onClick={() => handleLink(getRouteUrl("/notices"), true, props)} >
                                        <h3 className={currentPage === "notices" ? "navbuttoncurrent" : "navbutton"}>Tiedotteet</h3>
                                    </List.Item>
                                    : null
                            }
                            {
                                props.hasFeeds ?
                                    <List.Item onClick={() => handleLink(getRouteUrl("/feeds"), false, props)}>
                                        <h3 className={currentPage === "feeds" ? "navbuttoncurrent" : "navbutton"}>Syötteet</h3>
                                    </List.Item>
                                    : null
                            }
                            {
                                props.hasPharmacyTemplates ?
                                    <List.Item onClick={() => handleLink(getRouteUrl("/templates"), false, props)}>
                                        <h3 className={currentPage === "templates" ? "navbuttoncurrent" : "navbutton"}>Listapohjat</h3>
                                    </List.Item>
                                    : null
                            }
                        </List>
                        <List floated="right" verticalAlign="middle" horizontal inverted relaxed style={{ paddingTop: "12px" }}>
                            {
                                props.hasLanguange ?
                                    <List.Item>
                                        <Dropdown item text={props.lang.toUpperCase()}>
                                            <Dropdown.Menu>
                                                <Dropdown.Item onClick={() => props.handleLanguangeChange("fi")}>FI</Dropdown.Item>
                                                <Dropdown.Item onClick={() => props.handleLanguangeChange("en")}>EN</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </List.Item>
                                    : null
                            }
                            {
                                props.hasContact ?
                                    <List.Item onClick={() => handleLink(getRouteUrl("/contact"), false, props)} >
                                        <h3 className={currentPage === "contact" ? "navbuttoncurrent" : "navbutton"}>Ota Yhteyttä</h3>
                                    </List.Item>
                                    : null
                            }
                            {
                                props.hasChangePw ?
                                    <List.Item onClick={() => handleLink(getRouteUrl(ROUTES.CHANGEPWD), false, props)} >
                                        <h3 className="navbutton" >Vaihda salasana</h3>
                                    </List.Item>
                                    : null
                            }
                            {
                                props.hasLogout ?
                                    <List.Item onClick={() => openLogoutPopup(props.history)} >
                                        <h3 className="navbutton">Kirjaudu ulos</h3>
                                    </List.Item>
                                    : null
                            }
                            {
                                props.hasGotoUser ?
                                    <List.Item onClick={() => handleUserViewChange(props)}>
                                        <h3 className="navbutton">Vaihda Pääkäyttäjä-näkymään</h3>
                                    </List.Item>
                                    : props.hasLogin ?
                                        <List.Item onClick={() => handleLink(getRouteUrl(ROUTES.LOGIN), false, props)}>
                                            <h3 className="navbutton">Kirjaudu sisään</h3>
                                        </List.Item>
                                        : null
                            }
                        </List>
                        <Grid>

                        </Grid>
                    </Layout>
            }
        </Segment>
    );
}

export default withRouter(NavBar);