import React from 'react';
import { Table, Icon } from 'semantic-ui-react';
import BadgeList from '../base-kit/BadgeList';
import PopupButton from '../base-kit/PopupButton';
import { copyToClipboard } from '../../../util/clipboard';
import { openNotification } from '../base-kit/Notification';
import { getFullAPIUrl } from '../../../util/url';
import { COLORS } from '../../../assets/constants';
import ModalDialogPage from '../modal-kit/ModalDialogPage';
import { ModalExternalState, openModal } from '../modal-kit/ModalWindow';

export interface Props {
    item: any,
    handleEdit: any,
    handleDelete: any,
    handleUse: any,
    permissions: any,
}

const deletePopup = (onConfirm: any) => {
    let state: ModalExternalState = {
        render: (props: any) => <ModalDialogPage {...props}
            content="Oletko varma, että haluat poistaa tämän listapohjan?" />,
        size: "tiny",
        onClose: onConfirm
    }
    openModal(state)
}

const TemplateListItem = (props: Props) => {
    return (
        <Table.Row>
            {/* Name */}
            <Table.Cell width="4">
                <div style={{ display: "flex" }}>
                    <h4 style={{ lineHeight: "14px", marginTop: "5px", marginBottom: "0px" }}>
                        {props.item.name}
                    </h4>
                </div>
                <h5 style={{ color: COLORS.green, fontSize: "14px", marginTop: "0px", marginBottom: "10px" }}>Vastaanottajia: {props.item.pharmacies.length}</h5>
            </Table.Cell>
            <Table.Cell width="2">
                <div>

                    <PopupButton icon="file alternate"
                        inverted
                        tooltip="Luo tiedote käyttäen listapohjaa"
                        onClick={() => props.handleUse(props.item.id)} />
                    <PopupButton icon="edit"
                        inverted
                        tooltip="Muokkaa listapohjaa"
                        onClick={() => props.handleEdit(props.item.id)} />
                    <PopupButton icon="trash alternate"
                        inverted
                        tooltip="Poista listapohja"
                        onClick={() => deletePopup(() => props.handleDelete(props.item.id))} />
                </div>
            </Table.Cell>
        </Table.Row>
    );
};

export default TemplateListItem;