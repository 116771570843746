import React from 'react';
import { Form, Input } from 'semantic-ui-react';
import ReactDatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { DATEFORMATS } from '../../../assets/constants';
require("moment/min/locales.min");

export interface Props {
    id: string,
    label: string,
    value: any,
    onChange: any,
    lang: string,
    required?: boolean,
    min? : string | number,
    max? : string | number,
}

const handleFixDatetime = (value: any) => {
    const timeZoneOffset = value.getTimezoneOffset();
    value.setHours(0, 0, 0, 0);
    value.setHours(value.getHours() - timeZoneOffset / 60);
    return value;
}

const onChange = (props: any, value: any) => {
    let date = handleFixDatetime(value);
    props.onChange(props.id, date.toISOString());
}

const getFormat = (locale: string) => {
    return DATEFORMATS[locale];
}

const FormDateInput = (props : Props) => {
    return (
        <Form.Field required={props.required}>
             <label>{props.label}</label>
            <ReactDatePicker 
                dateFormat={getFormat(props.lang)}
                selected={new Date(props.value) as any}
                minDate={props.min ? new Date(props.min) as any : undefined}
                maxDate={props.max ? new Date(props.max) as any : undefined}
                customInput={<Input icon="calendar"/>}
                showDisabledMonthNavigation
                dateFormatCalendar={getFormat(props.lang)}
                onChange={(date: any) => onChange(props, date)}/>
        </Form.Field>
        
    );
};

export default FormDateInput;