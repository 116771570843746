import React from 'react';
import { getAPIUrl, getRouteUrl } from '../../util/url';
import { withRouter, RouteComponentProps, Switch, Route } from 'react-router';
import NoticeFormView from '../views/NoticeFormView';
import NoticeView from '../views/NoticeView';
import rest from '../../util/rest';
import { NoticeViewEnum } from '../../assets/constants';
import { refreshTimeout } from '../../util/timeout';
import { getISODateString, getDefaultActivationDate, getDefaultExpirationDate, getDefaultArchiveDate, getLocalizedDate } from '../../util/date';
import { openNotification } from '../kits/base-kit/Notification';
import { checkKeys } from '../../util/utils';
import md5 from 'md5';
import { ModalExternalState, openModal } from '../kits/modal-kit/ModalWindow';
import PasswordModalDialogPage from '../kits/modal-kit/PasswordModalDialogPage';
import { getSubscriberTemplates } from "../../util/listOptions"

interface Props extends RouteComponentProps<any> {
    lang: string,
    categoryOptions: any,
    subscriberOptions: any,
    productOptions: any,
    categoryDict: any,
    subscriberDict: any,
    productDict: any,
    permissions?: any,
    uuid?: any
}

const emptyItem: any = {
    title: "",
    categories: [],
    description: "",
    activationDate: getISODateString(getDefaultActivationDate()),
    expirationDate: getISODateString(getDefaultExpirationDate()),
    archiveDate: getISODateString(getDefaultArchiveDate()),
    deliverToAll: 1,
    subscribers: [],
    usedTemplate: "",
    products: [],
    files: [],
    requireAck: 0,
    requireAuth: 0,
    requireFeedPassword: 0,
    parentNotice: undefined,
    isChild: false
}

const emptyStats = {
    viewed: 0,
    read: 0,
    done: 0
}

let timer: any = undefined;

class NoticeRoutes extends React.Component<Props, any> {
    constructor(props: Props) {
        super(props);
        this.state = {
            item: { ...emptyItem },
            stats: { ...emptyStats },
            id: "",
            ack: 0,
            errors: [],
            feedPassword: "",
            isLoading: true,
            inputTimeout: null,
            mode: NoticeViewEnum.CREATE,
            pricing: { isLoading: false, breakdown: { items: [], total: {} } },
            prevValue: "",
            foundProducts: [],
            popupVisible: false,
            loaderVisible: false,
            noticeList: []
        };
    }

    updatePricing = async (item?: any) => {
        if (this.props.permissions && this.props.permissions.canSeePrices) {
            if (!item) item = { ...this.state.item };
            this.setState((prevState: any) => ({
                ...prevState,
                pricing: { ...this.state.pricing, isLoading: true }
            }));
            try {
                const breakdown = await rest.post(getAPIUrl("/pricing", "/breakdown"), item, this.props.history, true);
                this.setState((prevState: any) => ({
                    ...prevState,
                    pricing: { breakdown: breakdown, isLoading: false }
                }));
            } catch (error) {
                openNotification(error.message);
                this.setState((prevState: any) => ({
                    ...prevState,
                    pricing: { ...this.state.pricing, isLoading: false }
                }));
            }
        }
    }

    handleEnterPress = (e: KeyboardEvent) => {
        if (e.keyCode === 13 && this.state.popupVisible) {
            e.preventDefault();
            this.handlePopUpSubmit();
        }
    }

    handlePopUpSubmit = async () => {
        const foundProducts = this.state.foundProducts;
        const item = Object.assign({}, this.state.item);
        for (let i = 0; i < foundProducts.length; i++) {
            item["products"].push(foundProducts[i]);
        }
        this.setState({
            item: item,
            popupVisible: false
        });
    }

    handlePopUpCancel = async () => {
        this.setState({ popupVisible: false })
    }

    clearTimer = () => {
        if (timer !== undefined) {
            clearTimeout(timer);
            timer = undefined;
            this.setState({ loaderVisible: false });
        }
    }

    handleSearchVnr = (value: any) => {
        let products: any;
        let match: any = [];

        // Get vnr numbers from feed string
        let vnrList = value.match(/\d+/g);
        vnrList = vnrList !== null ? vnrList.map(String) : [];
        let query: any = { vnr: vnrList };
        if (this.props.uuid) {
            query.feed = this.props.uuid;
        }
        this.setState({ loaderVisible: true });
        timer = setTimeout(async () => {
            if (value === this.state.prevValue) {
                try {
                    products = vnrList.length > 0 ? await rest.get(getAPIUrl("/product"),
                        query, null, this.props.uuid ? false : true) : [];

                    products.forEach((product: any) => {
                        if (product.vnr) {
                            let foundIndex = this.state.item.products.findIndex((x: any) => x.id === product.id);
                            if (foundIndex < 0) {
                                match.push(product);
                            }
                        }
                    });
                } catch (error) {
                    openNotification(error.message);
                }
                if (match.length > 0) {
                    this.setState({
                        foundProducts: match,
                        popupVisible: true,
                        loaderVisible: false
                    })
                } else {
                    this.setState({
                        popupVisible: false,
                        loaderVisible: false
                    })
                }
            }
        }, 2500);
        this.setState({ prevValue: value });
    }

    // This will have to log errors from the form for validation
    handleChange = (key: string, value: any) => {
        this.setState({ popupVisible: false })
        const newItem = {
            ...this.state.item,
            [key]: value
        };
        if (!this.props.permissions.isAdmin &&
            checkKeys(key, ["categories", "activationDate", "deliverToAll", "subscribers", "usedTemplate", "requireAck"])) {
            const timeout = refreshTimeout(this.state.inputTimeout, this.updatePricing, 300);
            this.setState({ ["item"]: newItem, ["inputTimeout"]: timeout });
        } else {
            this.setState({ ["item"]: newItem });
        }
        if (key === "description") {
            this.handleSearchVnr(value);
        }
    }

    handleSubmit = async (event: any) => {
        event.preventDefault();
        try {
            let tempArr: any = [];
            const item = {
                ...this.state.item,
                requireAuth: this.state.item.requireAuth === 1,
                files: this.state.item.files.map((file: any) => file.id),
                parentNotice: this.state.item.isChild && this.state.item.parentNotice ? this.state.item.parentNotice.id : undefined
            };
            item.products.forEach((x: any) => {
                tempArr.push(x.id);
            });
            item.products = tempArr;
            let response;
            if (this.state.mode === NoticeViewEnum.PREVIEW) {
                response = await rest.post(getAPIUrl("/notice"), item, this.props.history, this.props.uuid ? false : true);
                this.props.history.replace(getRouteUrl("/notices"));
                openNotification(`Tiedote '${this.state.item.title}' luotu onnistuneesti!`);
            } else if (this.state.mode === NoticeViewEnum.EDIT) {
                response = await rest.put(getAPIUrl("/notice"), item.id, item, this.props.history, this.props.uuid ? false : true);
                await this.handleGet();
                this.props.history.replace(getRouteUrl("/notices", this.props.match.params.id));
                openNotification(`Tiedote '${this.state.item.title}' muokattu onnistuneesti.`);
            }
        } catch (error) {
            openNotification(error.message);
        }
    }

    handleDelete = async () => {
        try {
            const response = await rest.delete(getAPIUrl("/notice"), this.props.match.params.id, this.props.history, true);
            this.props.history.push(getRouteUrl("/notices"));
            openNotification(`Tiedote '${this.state.item.title}' poistettu onnistuneesti.`);
        } catch (error) {
            openNotification(error.message);
        }
    }

    handleTransition = (event: any, mode: number) => {
        if (event) event.preventDefault();
        this.setState({ ["mode"]: mode });
        switch (mode) {
            case NoticeViewEnum.VIEW:
                return this.props.history.push(getRouteUrl("/notices", "/:id", this.props.location.search));
            case NoticeViewEnum.PREVIEW:
                return this.props.history.push(getRouteUrl("/notices", "/preview"));
            case NoticeViewEnum.CREATE:
                return this.props.history.push(getRouteUrl("/notices", "/new"));
            case NoticeViewEnum.EDIT:
                return this.props.history.push(getRouteUrl("/notices", this.state.item.id, "/edit"));
            default:
                return;
        }
    }

    handleBack = async (event: any, noticeMode: number) => {
        if (event) event.preventDefault();
        switch (noticeMode) {
            case NoticeViewEnum.VIEW:
                return this.props.history.push(getRouteUrl("/notices", this.props.location.search));
            case NoticeViewEnum.PREVIEW:
                return this.props.history.push(getRouteUrl("/notices", "/new"));
            case NoticeViewEnum.CREATE:
                return this.props.history.push(getRouteUrl("/notices"));
            case NoticeViewEnum.EDIT:
                await this.handleGet(getRouteUrl("/notices", this.state.item.id));
                break;
            default:
                return;
        }
    }

    handleAck = async (event: any, ackMode: number) => {
        try {
            let url = this.state.item.requireAuth === 1 ? "/notice/acknowledgeauth/" : "/notice/acknowledge";
            const response = await rest.put(getAPIUrl(url), this.state.item.viewid, { ack: ackMode }, this.props.history, this.props.uuid ? false : true);
            let readDate = this.state.readDate;
            let doneDate = this.state.doneDate;
            if (ackMode === 2) {
                doneDate = !doneDate ? getLocalizedDate(new Date()).toISOString() : doneDate;
                readDate = !readDate ? doneDate : readDate;
            } else if (ackMode === 1) {
                readDate = !readDate ? getLocalizedDate(new Date()).toISOString() : readDate;
            }
            this.setState({
                ack: ackMode,
                readDate: readDate,
                doneDate: doneDate
            });
        } catch (error) {
            openNotification(error.message);
        }
    }

    handleGet = async (redirectUrl?: any) => {
        // If this is a feed subscriber, we take feed password into account
        if (this.props.uuid) {
            const noticeListUrl = getRouteUrl("/notices", `?feed=${this.props.uuid}`);
            try {
                const checkResponse = await rest.get(getAPIUrl(
                    "/notice",
                    this.props.match.params.id,
                    "/checkPasswordRequirement",
                ));

                // If password is required and we don't have it, open modal
                if (checkResponse.requireFeedPassword === true &&
                    (!this.state.feedPassword || this.state.feedPassword.length < 1)) {
                    let state: ModalExternalState = {
                        render: (props: any) =>
                            <PasswordModalDialogPage {...props}
                                header="Anna syötteen salasana"
                                handleSubmit={(password: string) => {
                                    this.setState({ feedPassword: password }, async () => await this.handleGet());
                                }}
                            />,
                        size: "tiny",
                        closeOnDimmerClick: false,
                        onClose: (goBack: boolean) => {
                            if (goBack) {
                                this.props.history.push(noticeListUrl);
                            }
                        },
                    }
                    openModal(state);
                }
                // If we have a password given, try getting the notice with it
                else {
                    // Try getting the item, or throw out
                    try {
                        const noticeResponse = await rest.get(
                            getAPIUrl(
                                "/notice",
                                this.props.match.params.id,
                                "/feed",
                                this.props.uuid
                            ),
                            { feedPassword: md5(this.state.feedPassword) },
                            this.props.history
                        );
                        const newItem: any = {
                            ...emptyItem,
                            ...noticeResponse.item,
                            requireAuth: noticeResponse.item.requireAuth === true ? 1 : 0,
                            requireFeedPassword: noticeResponse.item.requireFeedPassword === true ? 1 : 0
                        };
                        this.setState({
                            item: newItem,
                            stats: noticeResponse.stats || { ...emptyStats },
                            ack: noticeResponse.doneDate ? 2 : noticeResponse.readDate ? 1 : 0,
                            readDate: noticeResponse.readDate,
                            doneDate: noticeResponse.doneDate,
                            id: this.props.match.params.id,
                            mode: NoticeViewEnum.VIEW,
                            isLoading: false
                        }, this.updatePricing);
                        if (redirectUrl) this.props.history.push(redirectUrl);
                    } catch (error) {
                        openNotification("Väärä salasana.");
                        this.props.history.push(noticeListUrl);
                    }
                }
            } catch (error) {
                openNotification(error.message);
                this.props.history.push(noticeListUrl);
            }
        }
        // An LT or AP user
        else {
            try {
                const url = getAPIUrl("/notice", this.props.match.params.id);
                const response = await rest.get(url, null, this.props.history, true);
                const newItem: any = {
                    ...emptyItem,
                    ...response.item,
                    requireAuth: response.item.requireAuth === true ? 1 : 0,
                    requireFeedPassword: response.item.requireFeedPassword === true ? 1 : 0
                };
                this.setState({
                    item: newItem,
                    stats: response.stats || { ...emptyStats },
                    ack: response.doneDate ? 2 : response.readDate ? 1 : 0,
                    readDate: response.readDate,
                    doneDate: response.doneDate,
                    id: this.props.match.params.id,
                    mode: NoticeViewEnum.VIEW,
                    isLoading: false
                }, this.updatePricing);
                if (redirectUrl) this.props.history.push(redirectUrl);
            } catch (error) {
                openNotification(error.message);
                this.props.history.replace(getRouteUrl("/notices"));
            }
        }
    }

    getNotices = async () => {
        try {
            let query: any = { compact: true };
            if (this.props.uuid) {
                query.feed = this.props.uuid;
            }
            let url = getAPIUrl("/notice");
            let response = await rest.get(url, query, this.props.history, this.props.uuid ? false : true);
            let tempArr = [];

            for (let i = 0; i < response.length; i++) {
                if (this.state.item.id) {
                    if (response[i].id === this.state.item.id) {
                        continue;
                    }
                }
                tempArr.push({
                    text: response[i].title,
                    key: response[i].id,
                    value: response[i].id
                })
            }
            this.setState({ noticeList: tempArr });
        } catch (error) {
            openNotification(error.message);
        }
    }

    async componentDidMount() {
        const templateId = this.props.location.state;
        let emptyItemModified = Object.assign({}, emptyItem)
        const id = this.props.match.params.id;

        if (templateId !== undefined) {
            try {
                let subscriberTemplate = await getSubscriberTemplates(templateId);
                emptyItemModified.subscribers = subscriberTemplate[0].pharmacies;
                emptyItemModified.usedTemplate = subscriberTemplate[0].name;
                emptyItemModified.deliverToAll = 0;
            } catch {
                openNotification("Virhe: Listapohjaa ei voitu hakea");
            }
        }

        if (id === "new") {
            emptyItemModified.products = [];
            this.setState({
                item: { ...emptyItemModified },
                stats: { ...emptyStats },
                id: id,
                mode: NoticeViewEnum.CREATE,
                isLoading: false,
            })
            await this.updatePricing();
        } else {
            await this.handleGet();
        }
        if (this.props.permissions && (this.props.permissions.canCreateNotices || this.props.permissions.isAdmin)) {
            await this.getNotices();
        }
    }

    render() {
        if (this.state.isLoading) {
            return null;
        }
        return (
            <Switch>
                <Route path={getRouteUrl("/notices", "/:id(new)")} render={
                    (props) => <NoticeFormView
                        lang={this.props.lang}
                        item={this.state.item}
                        uuid={this.props.uuid}
                        handleChange={this.handleChange}
                        handleTransition={this.handleTransition}
                        handleBack={this.handleBack}
                        handleSubmit={this.handleSubmit}
                        categoryOptions={this.props.categoryOptions}
                        categoryDict={this.props.categoryDict}
                        subscriberOptions={this.props.subscriberOptions}
                        subscriberDict={this.props.subscriberDict}
                        productOptions={this.props.productOptions}
                        productDict={this.props.productDict}
                        isLoading={this.state.isLoading}
                        noticeMode={NoticeViewEnum.CREATE}
                        permissions={this.props.permissions}
                        pricing={this.state.pricing}
                        stats={this.state.stats}
                        foundProducts={this.state.foundProducts}
                        handlePopUpSubmit={this.handlePopUpSubmit}
                        handlePopUpCancel={this.handlePopUpCancel}
                        popupVisible={this.state.popupVisible}
                        handleEnterPress={this.handleEnterPress}
                        clearTimer={this.clearTimer}
                        loaderVisible={this.state.loaderVisible}
                        noticeList={this.state.noticeList} />
                } />
                <Route path={getRouteUrl("/notices", "/:id(preview)")} render={
                    (props) => <NoticeView
                        lang={this.props.lang}
                        item={this.state.item}
                        uuid={this.props.uuid}
                        handleTransition={this.handleTransition}
                        handleBack={this.handleBack}
                        handleSubmit={this.handleSubmit}
                        handleDelete={this.handleDelete}
                        categoryDict={this.props.categoryDict}
                        subscriberDict={this.props.subscriberDict}
                        productDict={this.props.productDict}
                        isLoading={this.state.isLoading}
                        noticeMode={NoticeViewEnum.PREVIEW}
                        permissions={this.props.permissions}
                        pricing={this.state.pricing}
                        stats={this.state.stats}
                        noticeList={this.state.noticeList} />
                } />
                <Route path={this.props.match.path}>
                    <Switch>
                        <Route path={getRouteUrl("/notices", "/:id/edit")} render={
                            (props) => <NoticeFormView
                                lang={this.props.lang}
                                item={this.state.item}
                                uuid={this.props.uuid}
                                handleChange={this.handleChange}
                                handleTransition={this.handleTransition}
                                handleBack={this.handleBack}
                                handleSubmit={this.handleSubmit}
                                isLoading={this.state.isLoading}
                                categoryOptions={this.props.categoryOptions}
                                categoryDict={this.props.categoryDict}
                                subscriberOptions={this.props.subscriberOptions}
                                subscriberDict={this.props.subscriberDict}
                                productOptions={this.props.productOptions}
                                productDict={this.props.productDict}
                                noticeMode={NoticeViewEnum.EDIT}
                                permissions={this.props.permissions}
                                pricing={this.state.pricing}
                                stats={this.state.stats}
                                readDate={this.state.readDate}
                                doneDate={this.state.doneDate}
                                foundProducts={this.state.foundProducts}
                                handlePopUpSubmit={this.handlePopUpSubmit}
                                handlePopUpCancel={this.handlePopUpCancel}
                                popupVisible={this.state.popupVisible}
                                handleEnterPress={this.handleEnterPress}
                                clearTimer={this.clearTimer}
                                loaderVisible={this.state.loaderVisible}
                                noticeList={this.state.noticeList} />
                        } />
                        <Route path={getRouteUrl("/notices", "/:id")} render={
                            (props) =>
                                <NoticeView
                                    lang={this.props.lang}
                                    item={this.state.item}
                                    uuid={this.props.uuid}
                                    handleTransition={this.handleTransition}
                                    handleBack={this.handleBack}
                                    handleSubmit={this.handleSubmit}
                                    handleDelete={this.handleDelete}
                                    handleAck={this.handleAck}
                                    categoryDict={this.props.categoryDict}
                                    subscriberDict={this.props.subscriberDict}
                                    productDict={this.props.productDict}
                                    isLoading={this.state.isLoading}
                                    noticeMode={NoticeViewEnum.VIEW}
                                    permissions={this.props.permissions}
                                    ackState={this.state.ack}
                                    stats={this.state.stats}
                                    readDate={this.state.readDate}
                                    doneDate={this.state.doneDate}
                                    noticeList={this.state.noticeList} />
                        } />
                    </Switch>
                </Route>
            </Switch>
        );
    }
}

export default withRouter(NoticeRoutes);