import React from 'react';
import { List, Grid, Header, Icon, Item } from 'semantic-ui-react';

//links
//https://storage.googleapis.com/farmahubi-static-assets/Lis%C3%A4tietoja%20apteekeille.pdf
//https://storage.googleapis.com/farmahubi-static-assets/Lis%C3%A4tietoja%20tiedottajille.pdf

const InfoView = (props: any) => {
    return (
        <div>
            <Header style={{fontFamily: "Lato", fontWeight: "normal", fontSize:"26px", textAlign:"center"}}>Mikä on Farmahubi?</Header>
            <Grid centered>
                <Grid.Column width="5">
                    <div className="ui card">
                        <div className="content">
                            <div className="header">Lääkeyrityksiä jotka tiedottavat jo Farmahubissa</div>
                            <div className="description">
                                <List bulleted>
                                    <List.Item>
                                        <h5>
                                            Orion
                                        </h5>
                                    </List.Item>
                                    <List.Item>
                                        <h5>
                                            Krka
                                        </h5>
                                    </List.Item>
                                    <List.Item>
                                        <h5>
                                            Verman
                                        </h5>
                                    </List.Item>
                                    <List.Item>
                                        <h5>
                                            Orifarm
                                        </h5>
                                    </List.Item>
                                    <List.Item>
                                        <h5>
                                            Medical Valley
                                        </h5>
                                    </List.Item>
                                    <List.Item>
                                        <h5>
                                            Stada
                                        </h5>
                                    </List.Item>
                                    <List.Item>
                                        <h5>
                                            Fennogate Apteq
                                        </h5>
                                    </List.Item>
                                    <List.Item>
                                        <h5>
                                            Bioteekki
                                        </h5>
                                    </List.Item>
                                    <List.Item>
                                        <h5>
                                            Fysioline
                                        </h5>
                                    </List.Item>
                                    <List.Item>
                                        <h5>
                                            Paranova
                                        </h5>
                                    </List.Item>
                                </List>
                            </div>
                        </div>
                    </div>
                </Grid.Column>
                <Grid.Column width="5">
                    <div className="ui card">
                        <div className="content">
                            <div className="header">Linkkejä</div>
                            <div className="description">
                                <List>
                                    <List.Item>
                                        <i className="icon attach" />
                                        <div className="content">
                                            <h5>
                                                <a href="https://storage.googleapis.com/farmahubi-static-assets/Lis%C3%A4tietoja%20apteekeille.pdf" target="_blank">Lisätietoa apteekeille</a>
                                            </h5>
                                        </div>

                                    </List.Item>
                                    <List.Item>
                                        <i className="icon attach" />
                                        <div className="content">
                                            <h5>
                                                <a href="https://storage.googleapis.com/farmahubi-static-assets/Lis%C3%A4tietoja%20tiedottajille.pdf" target="_blank">Lisätietoa tiedottajille</a>
                                            </h5>
                                        </div>
                                    </List.Item>
                                    <List.Item>
                                        <i className="icon attach" />
                                        <div className="content">
                                            <h5>
                                                <a href="https://storage.googleapis.com/farmahubi-rss-installers/FarmahubiRSS%20Setup%200.2.1.exe" target="_blank">RSS-ohjelmiston lataus Salix-apteekeille</a>
                                            </h5>
                                        </div>
                                    </List.Item>
                                </List>
                            </div>
                        </div>
                    </div>
                </Grid.Column>
            </Grid>
        </div>
    )
}


export default InfoView;