import React from 'react';
import BadgeList from '../base-kit/BadgeList';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { formatISODateString, ISODateLessThan } from '../../../util/date';
import { Grid, Header, List, Icon } from 'semantic-ui-react';
import { getItem } from '../../../assets/localization';
import { shortenString, padStringStart } from '../../../util/string';
import '../../../styles/components/NoticeListItem.css';
import { getRouteUrl } from '../../../util/url';
import { ROUTES } from '../../../assets/constants';
import { COLORS } from '../../../assets/constants';
import ColorCircle from '../base-kit/ColorCircle';

const MAX_NOTICE_PRODUCTS_LENGTH = 5;
const MAX_DESC_LENGTH = 320;

export interface Props extends RouteComponentProps<any> {
    item: any,
    lang: string,
    url: string,
    categoryDict: any,
}

const linkOnClick = (url: string, history: any) => {
    history.push(url);
}

const drawProductItem = (product: any, key: any) => {
    const name = product.name;
    return (
        <div key={key} style={{ whiteSpace: "pre", overflow: "hidden", textOverflow: "ellipsis", height: "20px" }}>
            <b>{product.vnr}</b>{padStringStart(name, 6)}
        </div>
    );
}

const drawProducts = (products: any) => {
    if (!products) return null;
    let result = [];
    let maxLength = Math.min(products.length, MAX_NOTICE_PRODUCTS_LENGTH);
    for (let i = 0; i < maxLength; i++) {
        //Render "..." if there's more than max amount of products
        if (i === maxLength - 1 && products.length > MAX_NOTICE_PRODUCTS_LENGTH) {
            const diff = products.length - MAX_NOTICE_PRODUCTS_LENGTH;
            result.push(
                <div key={i} style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", height: "20px" }}>
                    <h5 style={{ display: "inline-block", whiteSpace: "nowrap" }}>{`(${diff + 1} lisää...)`}</h5>
                </div>
            );
        }
        else {
            if (!products[i]) continue;
            result.push(drawProductItem(products[i], i));
        }
    }
    return (
        <div style={{ marginTop: "10px" }}>
            {result}
        </div>
    );
}

const NoticeListItem = (props: Props) => {
    let isSent = !ISODateLessThan(props.item.activationDate);
    let sendLabel = isSent ? "" : getItem(props.lang, "noticelist-sendingLabel");
    return (
        <List.Item>
            <div className="noticelistitem" onClick={() => linkOnClick(getRouteUrl(ROUTES.NOTICES, props.item.id, props.location.search), props.history)}
                style={{
                    cursor: "pointer",
                    borderLeft: props.item.ack < props.item.requireAck ? `4px solid ${props.item.requireAck === 2 ? COLORS.yellow : COLORS.lightGreen}` : 0
                }}>
                <Grid >
                    <Grid.Column width={12}>
                        <Header floated="left">
                            <div style={{ display: "flex" }}>
                                {
                                    props.item.requireFeedPassword ?
                                        <Icon style={{ marginTop: "-2px" }} name="lock" />
                                        : null
                                }
                                {
                                    props.item.requireAck > 0 ?
                                        <ColorCircle
                                            color={props.item.requireAck === 2 ? COLORS.yellow : COLORS.lightGreen}
                                            margin="5px 5px 0 0"
                                        />
                                        : null
                                }
                                {props.item.title}
                            </div>
                            <Header.Subheader>{props.item.company ? props.item.company : props.item.author}</Header.Subheader>
                        </Header>
                        <Header floated="right" color={sendLabel.length > 0 ? "blue" : "grey"} size="tiny">
                            {`${sendLabel} ${formatISODateString(props.item.activationDate, ".")}`}
                        </Header>
                        <br />
                        <br />
                        <br />
                        <BadgeList keys={props.item.categories} options={props.categoryDict} />
                        <br />
                        <br />
                        <p>{shortenString(props.item.description, MAX_DESC_LENGTH)}</p>
                    </Grid.Column>
                    {
                        props.item.products.length > 0 ?
                            <Grid.Column width={4}>
                                <Header color="black" size="tiny">Liitetyt tuotteet</Header>
                                {drawProducts(props.item.products)}
                            </Grid.Column>
                            :
                            <Grid.Column width={4}>
                                <Header color="black" size="tiny">Ei liitettyjä tuotteita</Header>
                            </Grid.Column>
                    }
                </Grid>
            </div>
        </List.Item>
    );
};

export default withRouter(NoticeListItem);