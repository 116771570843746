import React, { ReactNode } from 'react';
import { Placeholder, Dimmer, Loader } from 'semantic-ui-react';
import { getItem } from '../../../assets/localization';
import { withRouter, RouteComponentProps } from 'react-router';

export interface Props extends RouteComponentProps<any> {
    lines: number,
    paragraphs: number,
    lang: string,
    inverted?: boolean,
    isLoading?: boolean,
    loaderSize?: any,
    header?: boolean,
    fluid?: boolean,
    children?: ReactNode
}

const drawLines = (props: Props) => {
    let lines: any = [];
    for (let i = 0; i < props.lines; i++) {
        lines.push(
            <Placeholder.Line key={i} />
        );
    }
    return lines;
}

const drawParagraphs = (props: Props) => {
    let paragraphs: any = []
    for (let i = 0; i < props.paragraphs; i++) {
        paragraphs.push(
            <Placeholder.Paragraph key={i}>
                {drawLines(props)}
            </Placeholder.Paragraph>
        );
    }
    return paragraphs;
}

const PlaceholderBlock = (props: Props) => {
    return (
        <Dimmer.Dimmable>
            <Dimmer inverted={props.inverted} active={props.isLoading}>
                <Loader size={props.loaderSize}>{getItem(props.lang, "loading")}</Loader>
            </Dimmer>
            {
                props.isLoading ?
                    <Placeholder fluid={props.fluid}>
                        {props.header ?
                            <Placeholder.Header>
                                <Placeholder.Line length="long" />
                            </Placeholder.Header>
                            : null
                        }
                        {drawParagraphs(props)}
                    </Placeholder>
                    :
                    props.children
            }
        </Dimmer.Dimmable>
    );
};

export default withRouter(PlaceholderBlock);