import { SESSION_DURATION, SESSION_TIME_LEFT_POPUP } from "../assets/constants";

let siteStore : any = {};
let sessionEndTime: number = 0;
let sessionEndCallback: any = undefined;
let sessionEndTimeoutHandle: any = undefined;

export const setStore = (key : string, value : any, useSessionStorage = true) => {
    let data = JSON.stringify(value);
    if(useSessionStorage)
        sessionStorage.setItem(key, data);
    else
        siteStore[key] = data;
}

export const getStore = (key : string, defaultValue? : any, useSessionStorage = true) => {
    let data;
    if(useSessionStorage)
        data = sessionStorage.getItem(key);
    else
        data = siteStore[key];
    if(data != null && data != "undefined") return JSON.parse(data);
    if(defaultValue) return defaultValue;
    return null;
}

export const removeStore = (key : string, useSessionStorage = true) => {
    if(useSessionStorage)
        sessionStorage.removeItem(key);
    else
        delete siteStore[key];
}

export const clearStore = (useSessionStorage = true) => {
    if(useSessionStorage)
        sessionStorage.clear();
    else
        siteStore = {};
}

export const registerSessionEndCallback = (callback: any) => {
    sessionEndCallback = callback;
}

export const refreshSession = () => {
    if(sessionEndCallback) {
        if(sessionEndTimeoutHandle) window.clearTimeout(sessionEndTimeoutHandle);
        sessionEndTimeoutHandle = window.setTimeout(sessionEndCallback, (SESSION_DURATION - SESSION_TIME_LEFT_POPUP) * 1000);
        sessionEndTime = Date.now() / 1000 + SESSION_DURATION;
    }
}

export const clearSession = () => {
    window.clearTimeout(sessionEndTimeoutHandle);
    sessionEndTime = 0;
}

export const getSessionEnd = () => {
    return sessionEndTime;
}