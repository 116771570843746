import React, { Component } from 'react';
import { getAPIUrl, getFullRouteUrl } from '../../util/url';
import { Header, Button, Grid, Divider, Segment, List, Table, Icon } from 'semantic-ui-react';
import { getItem } from '../../assets/localization';
import BadgeList from '../kits/base-kit/BadgeList';
import { NoticeViewEnum, COLORS } from '../../assets/constants';
import PlaceholderBlock from '../kits/layout-kit/PlaceholderBlock';
import { formatISODateString, ISODateLessThan, formatISODateTimeString } from '../../util/date';
import { getStore } from '../../util/session';
import PermissionCheck from '../kits/layout-kit/PermissionCheck';
import rest from '../../util/rest';
import PriceBreakdown from '../kits/notice-kit/PriceBreakdown';
import { withRouter } from 'react-router';
import FileList from '../kits/file-kit/FileList';
import RouteBreadcrumb from '../kits/base-kit/RouteBreadcrumb';
import { prettyNumber, padStringStart } from '../../util/string';
import { openNotification } from '../kits/base-kit/Notification';
import { ModalExternalState, openModal } from '../kits/modal-kit/ModalWindow';
import ModalListPage from '../kits/modal-kit/ModalListPage';
import ModalDialogPage from '../kits/modal-kit/ModalDialogPage';
import Linkify from 'react-linkify';

const subscriberItemState = (state: number, requireAck: number, viewed: boolean) => {
    if (requireAck === 0) {
        const s = state > -1 ? (viewed === true ? 1 : 0) : state;
        switch (s) {
            case 1:
                return {
                    text: "Katsottu",
                    color: COLORS.green
                };
            case 0:
                return {
                    text: "Ei katsottu",
                    color: COLORS.red
                };
            default:
                return {
                    text: "Ei aktiivinen",
                    color: COLORS.grey
                };
        }
    } else {
        switch (state) {
            case 2:
                return {
                    text: "Toimenpiteet suoritettu",
                    color: COLORS.green
                };
            case 1:
                return {
                    text: "Tiedote luettu",
                    color: requireAck === 1 ? COLORS.green : COLORS.orange
                };
            case 0:
                return {
                    text: "Ei kuitattu",
                    color: COLORS.red
                };
            default:
                return {
                    text: "Ei aktiivinen",
                    color: COLORS.grey
                };
        }
    }
}

const statisticItem = (key: string, value: number, maxValue: number, color?: string) => {
    const valueString = prettyNumber(value) + "/" + prettyNumber(maxValue);
    return (
        <div style={{ whiteSpace: "pre", overflow: "hidden", textOverflow: "ellipsis", color: color ? color : "", lineHeight: "28px" }}>
            {valueString + padStringStart(key, 10 - valueString.length)}
        </div>
    );
}

class NoticeView extends Component<any, any> {

    constructor(props: any) {
        super(props);
        this.state = {
            isFeedPasswordChecked: false
        }
    }

    getSubscriberModalItems = async (test: any) => {
        if (this.props.noticeMode === NoticeViewEnum.PREVIEW) {
            // Get chosen subscribers
            let subscribers = this.props.item.subscribers;
            return subscribers.map((sub: any) => {
                return {
                    id: sub,
                    oid: this.props.subscriberDict[sub].oid,
                    name: this.props.subscriberDict[sub].name,
                    count: 0,
                    state: this.props.subscriberDict[sub].isActive ? 0 : -1,
                    viewed: sub.viewed
                };
            });
        }
        else {
            // Get all available subscribers
            try {
                let subscribers = await rest.get(getAPIUrl("/notice", this.props.item.id, "/acknowledged"), null, this.props.history, this.props.uuid ? false : true);
                let result = [];
                if (subscribers) {
                    for (let i = 0; i < subscribers.length; i++) {
                        const id = subscribers[i].id;
                        if (!this.props.subscriberDict[id]) continue;
                        let ack = subscribers[i].ack;
                        result.push({
                            id: id,
                            oid: this.props.subscriberDict[id].oid,
                            name: this.props.subscriberDict[id].name,
                            count: subscribers[i].count || 0,
                            state: this.props.subscriberDict[id].isActive ? (ack || 0) : -1,
                            viewed: subscribers[i].viewed
                        });
                    }
                }
                return result;
            } catch (error) {
                openNotification(error.message);
            }
        }
    }

    openSubscriberModal = () => {
        let state: ModalExternalState = {
            render: (props: any) => <ModalListPage
                {...props}
                header="Vastaanottajat"
                lang={this.props.lang}
                scrolling searchable
                usedTemplate={this.props.item.usedTemplate}
                rowKeyElements={[
                    { key: "oid", title: "OID" },
                    { key: "name", title: "Nimi" },
                    { key: "state", title: "Tila", render: this.drawSubscriberModalState }]}
                tabs={[{ key: -1, value: "Kaikki", testMethod: "gt", testKey: "state" },
                { key: 0, value: "Lukemattomat", testMethod: "lte", testKey: "state" },
                { key: 1, value: "Luetut", testMethod: "gte", testKey: "state" },
                { key: 2, value: "Kuitatut", testMethod: "eq", testKey: "state" }]}
                searchPlaceholder="Hae vastaanottajia"
                searchKeys={["name", "oid"]} />,
            onOpen: this.getSubscriberModalItems,
            canCancel: true
        };
        openModal(state);
    }

    drawSubscriberModalState = (props: any) => {
        const itemState = subscriberItemState(props.value, this.props.item.requireAck, props.item.viewed);
        return (
            <p style={{ color: itemState.color }}>{itemState.text}</p>
        );
    }

    drawProductListItem = (item: any) => {
        if (!item) return null;
        return (
            <Table.Row key={item.id}>
                <Table.Cell>{item.vnr}</Table.Cell>
                <Table.Cell>{item.name}</Table.Cell>
                <Table.Cell>{item.strength}</Table.Cell>
                <Table.Cell>{item.packSize}</Table.Cell>
            </Table.Row>
        );
    }

    drawProductList = (products: any) => {
        if (!products) return null;
        return (
            <Table basic="very" compact>
                <Table.Header>
                    <Table.Row>
                        <Table.Cell><h5>VNR</h5></Table.Cell>
                        <Table.Cell><h5>Nimi</h5></Table.Cell>
                        <Table.Cell><h5>Vahvuus</h5></Table.Cell>
                        <Table.Cell><h5>Pakkauskoko</h5></Table.Cell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {
                        products.map((p: any) => {
                            return this.drawProductListItem(p);
                        })
                    }
                </Table.Body>
            </Table>
        );
    }

    deletePopup = (onConfirm: any) => {
        let state: ModalExternalState = {
            render: (props: any) => <ModalDialogPage {...props}
                content="Oletko varma että haluat poistaa tämän tiedotteen?" />,
            size: "tiny",
            onClose: onConfirm
        }
        openModal(state)
    }

    drawUserControls = (lang: string) => {
        switch (this.props.noticeMode) {
            case NoticeViewEnum.PREVIEW:
                return (
                    <List horizontal>
                        <List.Item>
                            <Button size="medium" onClick={(event) => this.props.handleBack(event, this.props.noticeMode)}>
                                {getItem(lang, "notice-view-back")}
                            </Button>
                        </List.Item>
                        <List.Item>
                            <Button size="medium" primary onClick={(event) => this.props.handleSubmit(event)}>
                                {getItem(lang, "notice-view-submit")}
                            </Button>
                        </List.Item>
                    </List>
                );
            default:
                return (
                    <List horizontal>
                        <List.Item>
                            <Button size="medium" onClick={(event) => this.props.handleBack(event, this.props.noticeMode)}>
                                {getItem(lang, "notice-view-back")}
                            </Button>
                        </List.Item>

                        <PermissionCheck permissions={this.props.permissions}
                            check="canEditNotices"
                            conditional={(this.props.permissions.isAdmin || ISODateLessThan(this.props.item.activationDate))
                                && this.props.item.active}
                            adminOverride>
                            <List.Item>
                                <Button size="medium" onClick={(event) => this.props.handleTransition(event, NoticeViewEnum.EDIT)}>
                                    {getItem(lang, "notice-view-edit")}
                                </Button>
                            </List.Item>
                        </PermissionCheck>

                        <PermissionCheck permissions={this.props.permissions}
                            check="canDisableNotices"
                            conditional={(this.props.permissions.isAdmin || ISODateLessThan(this.props.item.activationDate))
                                && this.props.item.active}
                            adminOverride>
                            <List.Item>
                                <Button content={getItem(lang, "notice-view-delete")}
                                    negative
                                    onClick={() => this.deletePopup(this.props.handleDelete)} />
                            </List.Item>
                        </PermissionCheck>

                        <PermissionCheck permissions={this.props.permissions}
                            conditional={this.props.item.requireAck >= 1}
                            check="canAck">
                            <List.Item>
                                <Button icon={this.props.ackState >= 1 ? "checkmark" : undefined}
                                    content={this.props.ackState >= 1 ? "Luettu" : getItem(lang, "notice-view-markRead")}
                                    size="medium" primary
                                    onClick={(event: any) => this.props.handleAck(event, 1)}
                                    disabled={this.props.ackState >= 1} />
                            </List.Item>
                        </PermissionCheck>

                        <PermissionCheck permissions={this.props.permissions}
                            conditional={this.props.item.requireAck == 2}
                            check="canAck">
                            <List.Item>
                                <Button icon={this.props.ackState === 2 ? "checkmark" : undefined}
                                    content={this.props.ackState === 2 ? "Suoritettu" : getItem(lang, "notice-view-markDone")}
                                    size="medium" primary
                                    onClick={(event: any) => this.props.handleAck(event, 2)}
                                    disabled={this.props.ackState === 2} />
                            </List.Item>
                        </PermissionCheck>

                    </List>
                );
        }
    }

    drawSubscriberControls = (lang: string) => {
        return (
            <List horizontal>
                <List.Item>
                    <Button size="medium" onClick={(event) => this.props.handleBack(event, this.props.noticeMode)}>
                        {getItem(lang, "notice-view-back")}
                    </Button>
                </List.Item>

                {
                    this.props.item.requireAuth === 0 && this.props.item.requireAck >= 1 ?
                        <List.Item>
                            <Button icon={this.props.ackState >= 1 ? "checkmark" : undefined}
                                content={this.props.ackState >= 1 ? "Luettu" : getItem(lang, "notice-view-markRead")}
                                size="medium" primary
                                onClick={(event: any) => this.props.handleAck(event, 1)}
                                disabled={this.props.ackState >= 1} />
                        </List.Item>
                        : null
                }

                {
                    this.props.item.requireAuth === 0 && this.props.item.requireAck === 2 ?
                        <List.Item>
                            <Button icon={this.props.ackState === 2 ? "checkmark" : undefined}
                                content={this.props.ackState === 2 ? "Suoritettu" : getItem(lang, "notice-view-markDone")}
                                size="medium" primary
                                onClick={(event: any) => this.props.handleAck(event, 2)}
                                disabled={this.props.ackState === 2} />
                        </List.Item>
                        : null
                }
            </List>
        );
    }

    drawView = (lang: string) => {
        let item = this.props.item;
        const deliverToAll = this.props.item.deliverToAll;
        const count = (deliverToAll ? this.props.subscriberDict.count : this.props.item.subscribers.length) || 0;
        const products = this.props.item.products;
        return (
            <Grid divided relaxed>

                {/* Preview */}
                <Grid.Column width={this.props.permissions && !this.props.permissions.isAdmin && this.props.pricing ? 11 : 16}>

                    {/* Title */}
                    <Grid.Row>
                        <Header size="medium">
                            <div style={{ display: "flex" }}>
                                {
                                    this.props.item.requireFeedPassword ?
                                        <Icon style={{ marginTop: "-2px" }} name="lock" />
                                        : null
                                }
                                {this.props.item.title}
                            </div>
                            <Header.Subheader>{this.props.item.company ? this.props.item.company : this.props.item.author}</Header.Subheader>
                        </Header>
                    </Grid.Row>

                    {/* Categories */}
                    {this.props.item.categories && this.props.item.categories.length > 0 ?
                        <Grid.Row>
                            <br />
                            <BadgeList keys={this.props.item.categories} options={this.props.categoryDict} />
                            <br />
                        </Grid.Row>
                        :
                        null
                    }

                    {/* Description */}
                    <Linkify>
                        <Grid.Row>
                            <br />
                            <div style={{
                                textAlign: "left",
                                whiteSpace: "pre-line",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                wordWrap: "break-word",
                                overflowWrap: "break-word",
                                hyphens: "auto",
                                msHyphens: "auto",
                                MozHyphens: "auto",
                                WebkitHyphens: "auto"
                            }}>{this.props.item.description}</div>
                            <br />
                        </Grid.Row>
                    </Linkify>

                    {/* Dates */}
                    <Grid.Row>
                        <Grid columns={this.props.permissions && (this.props.permissions.isAdmin ||
                            this.props.permissions.canSeeArchiveDate) ? 3 : 2}>
                            <Grid.Column >
                                <Header size="tiny">
                                    {getItem(lang, "notice-form-activationDate")}
                                </Header>
                                <p>{formatISODateString(this.props.item.activationDate, ".")}</p>
                            </Grid.Column>
                            <Grid.Column>
                                <Header size="tiny">
                                    {getItem(lang, "notice-form-expirationDate")}
                                </Header>
                                <p>{formatISODateString(this.props.item.expirationDate, ".")}</p>
                            </Grid.Column>
                            <PermissionCheck permissions={this.props.permissions}
                                check="canSeeArchiveDate"
                                adminOverride>
                                <Grid.Column >
                                    <Header size="tiny">
                                        {getItem(lang, "notice-form-archiveDate")}
                                    </Header>
                                    <p>{formatISODateString(this.props.item.archiveDate, ".")}</p>
                                </Grid.Column>
                            </PermissionCheck>
                        </Grid>
                        <br />
                    </Grid.Row>

                    {/* Required acks */}
                    {
                        <Grid.Row>
                            {this.props.item.requireAck > 0 ? <Header size="tiny">Vaaditut kuittaukset</Header> : null}
                            {
                                this.props.item.requireAck >= 1 || this.props.uuid || (this.props.permissions.canAck && this.props.ackState >= 1) ?
                                    <div style={{
                                        lineHeight: "28px",
                                        color: this.props.ackState >= 1 ?
                                            COLORS.green : undefined
                                    }}>
                                        <Icon name={this.props.ackState >= 1 ?
                                            "check" :
                                            "exclamation circle"}
                                        />
                                        Tiedote luettu
                                    {this.props.readDate ?
                                            " (" + formatISODateTimeString(this.props.readDate, ".", ":") + ")" :
                                            this.props.doneDate ?
                                                " (" + formatISODateTimeString(this.props.doneDate, ".", ":") + ")" :
                                                null}
                                    </div>
                                    : null
                            }
                            {
                                this.props.item.requireAck === 2 || this.props.uuid || (this.props.permissions.canAck && this.props.ackState === 2) ?
                                    <div style={{
                                        lineHeight: "28px",
                                        color: this.props.ackState === 2 ?
                                            COLORS.green : undefined
                                    }}>
                                        <Icon name={this.props.ackState === 2 ?
                                            "check" :
                                            "exclamation circle"}
                                        />
                                        Vaadittavat toimenpiteet suoritettu
                                    {this.props.doneDate ?
                                            " (" + formatISODateTimeString(this.props.doneDate, ".", ":") + ")" :
                                            null}
                                    </div>
                                    : null
                            }
                            {
                                this.props.item.requireAuth === 1 ?
                                    <div style={{ lineHeight: "28px", fontStyle: "italic" }}>
                                        <Icon name="user" />
                                        Vain pääkäyttäjä voi kuitata tiedotteen
                                </div>
                                    : null
                            }
                            <br />
                        </Grid.Row>
                    }


                    {/* Subscribers */}
                    <PermissionCheck permissions={this.props.permissions}
                        check="canListSubscribers"
                        adminOverride>
                            <Grid columns={3}>
                            <Grid.Column>
                                <Grid.Row>
                                <Header size="tiny">Vastaanottajat</Header>

                                    {/* Subscriber Statistics */}
                                {
                                        this.props.item.requireAck >= 1 ?
                                        <Grid.Row>
                                                    {statisticItem("Ei kuitattu", count - Math.max(this.props.stats.read, this.props.stats.done), count, COLORS.red)}                                                {statisticItem("Luettu", this.props.stats.read, count, this.props.item.requireAck === 1 ? COLORS.green : COLORS.orange)}
                                                {
                                                    this.props.item.requireAck === 2 ?
                                                        statisticItem("Suoritettu", this.props.stats.done, count, COLORS.green)
                                                        : null
                                                }
                                        </Grid.Row>
                                            : null
                                    }
                                    {
                                        this.props.item.requireAck === 0 ?
                                            <Grid.Row>
                                                {statisticItem("Katsottu", this.props.stats.viewed, count)}
                                            </Grid.Row>
                                            : null
                                    }

                                    {/* Show Subscribers button */}
                                    {
                                        this.props.noticeMode === NoticeViewEnum.PREVIEW && this.props.item.deliverToAll ?
                                            <b style={{ color: COLORS.grey, lineHeight: "28px" }}>Lähetetään kaikille</b>
                                            :
                                            <b style={{ color: COLORS.blue, cursor: "pointer", lineHeight: "28px" }} onClick={this.openSubscriberModal}>Näytä vastaanottajat</b>    
                                    }
                                    <br />
                                    <br />
                            </Grid.Row>
                        </Grid.Column>
                        {
                            this.props.item.usedTemplate !== "" &&
                             this.props.item.usedTemplate !== undefined &&
                             this.props.item.deliverToAll !== 1 ?
                                <Grid.Column>
                                    <Grid.Row>
                                        <Header size="tiny">Listapohja</Header>
                                    </Grid.Row>
                                    <br />
                                    <Grid.Row>
                                        <p>{this.props.item.usedTemplate}</p>
                                    </Grid.Row>
                                </Grid.Column> : null
                        }
                        </Grid>
                    </PermissionCheck>

                    {/* File list */}
                    {
                        this.props.item.files.length > 0 ?
                            <Grid.Row>
                                <Header size="tiny">Liitetyt tiedostot</Header>
                                <FileList files={this.props.item.files} />
                                <br />
                            </Grid.Row>
                            : null
                    }

                    {/* Product list */}
                    {
                        products.length > 0 ?
                            <Grid.Row>
                                <Header size="tiny">Liitetyt tuotteet</Header>
                                {this.drawProductList(products)}
                            </Grid.Row>
                            : null
                    }

                    {
                        this.props.item.parentNotice && (this.props.item.isChild || this.props.noticeMode !== NoticeViewEnum.PREVIEW ) ?
                            <Grid.Row>                    
                                <Header size="tiny">Jatkoa tiedotteelle</Header>
                                <h5>
                                    <a href={getFullRouteUrl("/notices/", this.props.item.parentNotice.id)}>{this.props.item.parentNotice.title}</a>
                                </h5>                   
                                <Divider clearing hidden />
                            </Grid.Row>
                            : null
                    }

                    {
                        item.childNotices && item.childNotices.length > 0 ?                      
                            <Grid.Row>                                                          
                                <Header size="tiny">Alatiedote</Header>
                                {
                                    item.childNotices.map((entry: any, index: any) => 
                                        <h5 key={index}>
                                            <a href={getFullRouteUrl("/notices/", entry.id)}>{entry.title}</a>
                                            <br />
                                        </h5>
                                    )
                                }
                                <Divider clearing hidden />
                            </Grid.Row>
                        : null
                    }

                    {
                        this.props.permissions ?
                            this.drawUserControls(lang)
                            :
                            this.drawSubscriberControls(lang)
                    }
                </Grid.Column>

                {/* Price listing */}
                {
                    this.props.permissions && !this.props.permissions.isAdmin && this.props.pricing ?
                        <Grid.Column width={5}>
                            <Segment basic>
                                <PriceBreakdown pricing={this.props.pricing}
                                    categories={this.props.categoryDict}
                                    lang={lang} />
                            </Segment>
                        </Grid.Column>
                        :
                        null
                }
            </Grid>
        );
    }

    render() {
        let lang = getStore("lang", "fi");
        return (
            <div>
                {
                    this.props.noticeMode === NoticeViewEnum.PREVIEW ?
                        <RouteBreadcrumb items={[
                            { text: "Tiedotteet", url: "/notices" + this.props.location.search },
                            { text: "Uusi tiedote", url: "/notices/new" },
                            { text: "Tiedotteen esikatselu", url: "/notices/preview" }
                        ]} size="tiny" />
                        :
                        <RouteBreadcrumb items={[
                            { text: "Tiedotteet", url: "/notices" + this.props.location.search },
                            { text: "Tiedote", url: "/notices/" + this.props.item.id + this.props.location.search }
                        ]} size="tiny" />
                }
                <Divider clearing hidden />
                <PlaceholderBlock lang={lang}
                    paragraphs={4}
                    lines={6}
                    isLoading={this.props.isLoading}
                    inverted
                    header
                    loaderSize="large">
                    {this.props.item != null ? this.drawView(lang) : null}
                </PlaceholderBlock>
            </div>
        );
    }
}

export default withRouter(NoticeView);