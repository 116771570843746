import React from 'react';
import { getItem } from '../../../assets/localization';
import "../../../styles/components/PriceBreakdown.css";

class PriceBreakdown extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
    }

    getString(key: string): string {
        return getItem(this.props.lang, "pricing")[key];
    }

    addItemLines(result: any[], typeStr: string, detail1: string | null, detail2: string | null, price: string, rowClass: string) {
        price = price || "";
        detail1 = detail1 || "";
        detail2 = detail2 || "";
        if (typeStr.length < 30 && !detail1.length && !detail2.length) {
            result.push(<tr className={rowClass} key={result.length}>
                <td className="type-col" colSpan={2}>{typeStr}</td>
                <td className="price-col">{price}</td>
            </tr>);
        } else {
            if (typeStr) {
                result.push(<tr className={rowClass} key={result.length}>
                    <td className="type-col" colSpan={2}>{typeStr}</td>
                </tr>);
            }
            if (detail1 && !detail2) {
                result.push(<tr className={rowClass} key={result.length}>
                    <td className="detail1-col" colSpan={2}>{detail1}</td>
                    <td className="price-col">{price}</td>
                </tr>);
            } else {
                result.push(<tr className={rowClass} key={result.length}>
                    <td className="detail1-col">{detail1}</td>
                    <td className="detail2-col">{detail2}</td>
                    <td className="price-col">{price}</td>
                </tr>);
            }
        }
    }

    addGenericItem(item: any, rowClass: string, result: string[]) {
        this.addItemLines(result,
            this.getString(item.type), item.detail1, item.detail2,
            (item.price ? item.price : item.sum ? item.sum : 0).toFixed(2) + " €",
            rowClass
        );
    }

    lineRender(item: any) {
        switch (item.type) {
            case "categories": return (item: any, result: string[]) => {
                this.addItemLines(result, this.getString(item.type), null, null, "", "default-row");
                item.items.forEach((cat: any) => {
                    this.addItemLines(result, "", this.props.categories[cat.category].text, null, cat.price.toFixed(2) + " €", "category-row");
                });
            };
            case "subsum": return (item: any, result: string[]) => {
                this.addGenericItem(item, "subsum-row", result);
            };
            case "total": return (item: any, result: string[]) => {
                this.addGenericItem(item, "total-row", result);
                this.addGenericItem({ type: 'perrecipient', sum: item.sum / item.recipients }, "perrecipient-row", result);
            };
            default: return (item: any, result: string[]) => {
                this.addGenericItem(item, "default-row", result);
            };
        }
    }

    render() {
        const { breakdown, isLoading } = this.props.pricing;

        const result: any[] = [];
        if (breakdown.items.length) {
            breakdown.items.forEach((item: any) => {
                this.lineRender(item)(item, result);
            });
            this.lineRender(breakdown.total)(breakdown.total, result);
        }

        return (
            <div className="ui segment" style={{ padding: "15px", width: "280px" }}>
                <h2>{this.getString("breakdown")}&nbsp;{isLoading ? "*" : ""}</h2>
                <table className="breakdown-table">
                    <tbody>
                        {result}
                    </tbody>
                </table>
            </div>
        );
    }
}

export default PriceBreakdown;