import React from "react";
import { Header, Form, Button, Divider } from "semantic-ui-react";
import { getItem } from "../../assets/localization";
import FormDropdown from "../kits/form-kit/FormDropdown";
import FormInput from "../kits/form-kit/FormInput";
import PlaceholderBlock from "../kits/layout-kit/PlaceholderBlock";
import RouteBreadcrumb from "../kits/base-kit/RouteBreadcrumb";
import { MIN_INPUT_LENGTH } from "../../assets/constants";
import { ModalExternalState, openModal } from "../kits/modal-kit/ModalWindow";
import ModalDialogPage from "../kits/modal-kit/ModalDialogPage";

const validateForm = (template: any) => {
    return (
        template.name &&
        template.name.length >= MIN_INPUT_LENGTH &&
        template.pharmacies &&
        template.pharmacies.length >= 0
    );
};

const backPopup = (event: any, onConfirm: any) => {
    event.preventDefault();
    let state: ModalExternalState = {
        render: (props: any) => (
            <ModalDialogPage
                {...props}
                content="Oletko varma että haluat hylätä tekemäsi muutokset?"
            />
        ),
        size: "tiny",
        onClose: onConfirm
    };
    openModal(state);
};

class TemplateFormView extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
    }

    render() {
        return (
            <div>
                {this.props.mode === "new" ? (
                    <RouteBreadcrumb
                        items={[
                            { text: "Listapohjat", url: "/templates" },
                            { text: "Uusi listapohja", url: "/templates/new" }
                        ]}
                        size="tiny"
                    />
                ) : (
                        <RouteBreadcrumb
                            items={[
                                { text: "Listapohjat", url: "/templates" },
                                {
                                    text: "Muokkaa listapohjaa",
                                    url: "/templates/" + this.props.template.id + "/edit"
                                }
                            ]}
                            size="tiny"
                        />
                    )}
                <Header size="large">
                    {getItem(
                        this.props.lang,
                        this.props.mode === "new"
                            ? "template-form-header-new"
                            : "template-form-header-edit"
                    )}
                </Header>
                <br />
                <PlaceholderBlock
                    isLoading={this.props.isLoading}
                    lines={3}
                    paragraphs={3}
                    inverted
                    lang={this.props.lang}
                >
                    <Form>
                        <FormInput
                            id="name"
                            type="text"
                            label={getItem(this.props.lang, "template-form-name")}
                            required
                            max={500}
                            // error={
                            //     this.props.template.name.length > 0 &&
                            //     this.props.template.name.length < MIN_INPUT_LENGTH
                            // }
                            placeholder="Syötä nimi"
                            value={this.props.template.name}
                            onChange={this.props.handleChange}
                        />
                        <FormDropdown
                            id="pharmacies"
                            label={getItem(this.props.lang, "template-form-pharmacies")}
                            required
                            multiple
                            search
                            selection
                            placeholder="Valitse vastaanottajat"
                            options={this.props.subscriberOptions.map((x: any) => ({ key: x.key, value: x.key, text: x.text }))}
                            value={this.props.template.pharmacies}
                            onChange={this.props.handleChange}
                        />
                        <br />

                        <Divider />
                        <Form.Group>
                            <Button onClick={event => backPopup(event, this.props.handleBack)}>
                                {getItem(this.props.lang, "template-form-back")}
                            </Button>
                            <Button
                                primary
                                disabled={!validateForm(this.props.template)}
                                onClick={this.props.handleSubmit}
                            >
                                {getItem(
                                    this.props.lang,
                                    this.props.mode === "new" ? "template-form-submit" : "template-form-accept"
                                )}
                            </Button>
                        </Form.Group>
                    </Form>
                </PlaceholderBlock>
            </div>
        );
    }
};

export default TemplateFormView;
