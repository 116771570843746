import React from 'react';
import { TextArea, Form } from 'semantic-ui-react';

export interface Props {
    id: string,
    value: string,
    rows: number,
    onChange: any
    error?: boolean,
    max?: number,
    readOnly?: boolean,
    label?: string,
    required?: boolean,
    disabled?: boolean,
    placeholder?: string,
    handleEnterPress?: any
}

class FormTextArea extends React.Component<Props, never> {
    shouldComponentUpdate(nextProps: any, nextState: never): boolean {
        return this.props.value !== nextProps.value || this.props.label !== nextProps.label;
    }

    handleChange = (event: any) => {
        if (!this.props.max || event.target.value.length < this.props.max)
            this.props.onChange(this.props.id, event.target.value)
    }

    render() {
        return (
            <Form.Field required={this.props.required} error={this.props.error}>
                <label htmlFor={this.props.id}>{this.props.label}</label>
                <TextArea id={this.props.id}
                    autoheight="true"
                    rows={this.props.rows}
                    readOnly={this.props.readOnly}
                    disabled={this.props.disabled}
                    placeholder={this.props.placeholder}
                    value={this.props.value}
                    onChange={this.handleChange} 
                    onKeyDown={(e: any) => this.props.handleEnterPress(e)}
                    />
            </Form.Field>
        );
    }
}

export default FormTextArea;