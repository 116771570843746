import React from 'react';
import NavBar from '../kits/layout-kit/NavBar';
import Layout from '../kits/layout-kit/Layout';
import { withRouter, Route, Switch, Redirect } from 'react-router';
import DocumentsView from '../views/DocumentsView';

const HiddenRoutes = (props: any) => {

    return (
        <div>
            <NavBar
                lang={props.lang}
                handleLanguangeChange={props.handleLanguangeChange}
                headerOnly
            />
            <Layout>
                <Switch>
                    {/* Hidden documents page  */}
                    <Route
                        path={'/'}
                        render={p => <DocumentsView {...p} lang={props.lang} />}
                    />
                </Switch>
            </Layout>
        </div>
    );
};

export default withRouter(HiddenRoutes);
