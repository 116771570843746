import React from 'react';
import { withRouter, Switch, Route, Redirect } from 'react-router';
import { getRouteUrl, getAPIUrl } from '../../util/url';
import NoticeRoutes from './NoticeRoutes';
import NoticeListView from '../views/NoticeListView';
import Layout from '../kits/layout-kit/Layout';
import NavBar from '../kits/layout-kit/NavBar';
import { getCategoryOptions, getSubscriberOptions, getProductOptions, getCategoryDict, getSubscriberDict, getProductDict } from '../../util/listOptions';
import FeedRoutes from './FeedRoutes';
import FeedListView from '../views/FeedListView';
import TemplateRoutes from './TemplateRoutes';
import TemplateListView from '../views/TemplateListView';
import rest from '../../util/rest';
import { ROUTES } from '../../assets/constants';

class BaseRoutes extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            isMounted: false,
            initComplete: false,
            permissions: {},
            categoryOptions: [],
            categoryDict: [],
            subscriberOptions: [],
            subscriberDict: [],
            productOptions: [],
            productDict: []
        }
    }

    async componentWillReceiveProps(nextProps: any) {
        if (this.props.lang !== nextProps.lang) {
            const c: any = await getCategoryOptions(this.props.lang);
            const cd: any = await getCategoryDict(this.props.lang);
            this.setState({
                categoryOptions: c,
                categoryDict: cd
            });
        }
    }

    async componentDidMount() {
        // Get user permissions if not viewing through a feed link
        let permissions: undefined;
        if (!this.props.uuid) {
            try {
                let response = await rest.get(getAPIUrl("/userinfo"), undefined)
                permissions = response.role.permissions;
            } catch (error) {
                return this.props.history.replace(getRouteUrl(ROUTES.LOGIN));
            }
        }

        //Load categories into an object list and a "dictionary" respectively
        let c: any, cd: any;
        try {
            c = await getCategoryOptions(this.props.lang, true, this.props.uuid || undefined);
            cd = await getCategoryDict(this.props.lang, false, this.props.uuid || undefined);
        } catch (error) {
            c = [];
            cd = {};
        }

        //Load subscribers into an object list and a "dictionary" respectively
        let s: any, sd: any;
        if (!this.props.uuid) {
            try {
                s = await getSubscriberOptions(true);
                sd = await getSubscriberDict(false);
            } catch (error) {
                s = [];
                sd = {};
            }
        } else {
            s = [];
            sd = {};
        }

        //Load products into an object list and a "dictionary" respectively
        let p: any, pd: any;
        try {
            p = await getProductOptions(true, this.props.uuid || undefined);
            pd = await getProductDict(false, this.props.uuid || undefined);
        } catch (error) {
            p = [];
            pd = {};
        }

        this.setState({
            isMounted: true,
            initComplete: true,
            permissions: permissions,
            categoryOptions: c,
            categoryDict: cd,
            subscriberOptions: s,
            subscriberDict: sd,
            productOptions: p,
            productDict: pd
        })
    }

    componentWillUnmount() {
        this.setState({
            isMounted: false,
            initComplete: false,
            categoryOptions: [],
            categoryDict: [],
            subscriberOptions: [],
            subscriberDict: [],
            productOptions: [],
            productDict: []
        })
    }

    render() {
        return (
            this.state.initComplete === true ?
                <div>
                    <NavBar lang={this.props.lang}
                        handleLanguangeChange={this.props.handleLanguangeChange}
                        handlePermissionChange={this.props.handlePermissionChange}
                        hasNotices
                        hasFeeds={this.state.permissions && this.state.permissions.canListFeeds}
                        hasPharmacyTemplates={this.state.permissions && this.state.permissions.canListPharmacyTemplates}
                        hasGotoUser={this.props.hasSession && this.props.uuid}
                        hasLogin={!this.props.hasSession}
                        hasLogout={this.state.permissions != undefined}
                        hasChangePw={this.state.permissions != undefined}
                        hasContact
                    />
                    <Layout>
                        <Switch>
                            {/* SINGLE NOTICE */}
                            <Route path={getRouteUrl("/notices", "/:id")} render={
                                (p) => <NoticeRoutes {...p} lang={this.props.lang}
                                    uuid={this.props.uuid}
                                    permissions={this.state.permissions}
                                    categoryOptions={this.state.categoryOptions}
                                    subscriberOptions={this.state.subscriberOptions}
                                    productOptions={this.state.productOptions}
                                    categoryDict={this.state.categoryDict}
                                    subscriberDict={this.state.subscriberDict}
                                    productDict={this.state.productDict} />
                            } />
                            {/* NOTICE LIST */}
                            <Route path={getRouteUrl("/notices")} render={
                                (p) => <NoticeListView {...p} lang={this.props.lang}
                                    uuid={this.props.uuid}
                                    permissions={this.state.permissions}
                                    categoryOptions={this.state.categoryOptions}
                                    productOptions={this.state.productOptions}
                                    categoryDict={this.state.categoryDict}
                                    productDict={this.state.productDict} />
                            } />
                            {/* SINGLE FEED */}
                            <Route path={getRouteUrl("/feeds", "/:id")} render={
                                (p) => <FeedRoutes {...p} lang={this.props.lang}
                                    uuid={this.props.uuid}
                                    permissions={this.state.permissions}
                                    categoryOptions={this.state.categoryOptions}
                                    categoryDict={this.state.categoryDict} />
                            } />
                            {/* FEED LIST */}
                            <Route path={getRouteUrl("/feeds")} render={
                                (p) => <FeedListView {...p} lang={this.props.lang}
                                    uuid={this.props.uuid}
                                    permissions={this.state.permissions}
                                    categoryOptions={this.state.categoryOptions}
                                    categoryDict={this.state.categoryDict} />
                            } />
                            {/* SINGLE TEMPLATE */}
                            <Route path={getRouteUrl("/templates", "/:id")} render={
                                (p) => <TemplateRoutes {...p} lang={this.props.lang}
                                    uuid={this.props.uuid}
                                    permissions={this.state.permissions}
                                    subscriberOptions={this.state.subscriberOptions} />
                            } />
                            {/* TEMPLATE LIST */}
                            <Route path={getRouteUrl("/templates")} render={
                                (p) => <TemplateListView {...p} lang={this.props.lang}
                                    uuid={this.props.uuid}
                                    permissions={this.state.permissions} />
                            } />
                            {/* REDIRECT */}
                            <Route path={getRouteUrl("*")} render={
                                (props) => <Redirect to={getRouteUrl("/notices")} />
                            } />
                        </Switch>
                    </Layout>
                </div>
                : null
        );
    }
};

export default withRouter(BaseRoutes);