import React from 'react';
import { Menu, Header } from 'semantic-ui-react';
import { withRouter, RouteComponentProps } from 'react-router';

export interface TabItem {
    key: string | number,
    value: string | number,
    testMethod?: "eq" | "neq" | "gt" | "gte" | "lt" | "lte"
    testKey?: string | number
}

export interface Props extends RouteComponentProps<any> {
    id: string,
    items: TabItem[],
    activeItem: string | number,
    onChange: any,
    fluid?: boolean
}

export const testTab = (tabIndex: string | number, item: any, tabs: TabItem[]): boolean => {
    let tab = undefined;
    if (typeof tabIndex === "string") {
        tab = tabs.find(x => x.key === tabIndex);
    } else {
        tab = tabs[tabIndex];
    }
    if (tab && tab.testMethod && tab.testKey) {
        if (item && item[tab.testKey]) {
            switch (tab.testMethod) {
                case "eq":
                    return tab.key === item[tab.testKey];
                case "neq":
                    return tab.key !== item[tab.testKey];
                case "gt":
                    return tab.key < item[tab.testKey];
                case "gte":
                    return tab.key <= item[tab.testKey];
                case "lt":
                    return tab.key > item[tab.testKey];
                case "lte":
                    return tab.key >= item[tab.testKey];
                default:
                    break;
            }
        }
    }
    return false;
}

const Tabs = (props: Props) => {
    return (
        <Menu pointing secondary fluid={props.fluid} id={props.id}>
            {
                props.items.map((item: any, key: any) =>
                    <Menu.Item color="blue" key={key} name={item.key.toString()} active={props.activeItem === item.key} onClick={(event) => props.onChange(props.id, item.key)}>
                        <Header size="medium" color={props.activeItem === item.key ? "black" : "grey"}>{item.value}</Header>
                    </Menu.Item>
                )
            }
        </Menu>
    );
};

export default withRouter(Tabs);