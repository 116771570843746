import React from 'react';
import NavBar from '../kits/layout-kit/NavBar';
import Layout from '../kits/layout-kit/Layout';
import ContactView from '../views/ContactView';
import { withRouter, Route, Switch, Redirect } from 'react-router';
import ChangePasswordView from '../views/ChangePasswordView';
import { getRouteUrl } from '../../util/url';
import DocumentsView from '../views/DocumentsView';

const FrontRoutes = (props: any) => {

  return (
    <div>
      <NavBar
        lang={props.lang}
        handleLanguangeChange={props.handleLanguangeChange}
        headerOnly
      />
      <Layout>
        <Switch>
          <Route
            path={'/'}
            render={p => <ContactView {...p} lang={props.lang} />}
          />
        </Switch>
      </Layout>
    </div>
  );
};

export default withRouter(FrontRoutes);
