import React from 'react';

export interface Props {
    permissions: any,
    children: JSX.Element,
    check?: string,
    adminOverride?: boolean,
    conditional?: boolean,
    overrideConditional?: boolean
}

const performCheck = (props: Props) => {
    return props.permissions != null &&
        //The required condition checks out
        (props.conditional == null || props.conditional === true) &&
        //Permission checks out
        ((props.check && props.permissions[props.check]) ||
        //We have overrides that are true
        (props.overrideConditional && props.overrideConditional === true) ||
        //We have admin override and the user is Admin
        (props.adminOverride && props.permissions["isAdmin"]));
}

const PermissionCheck = (props: Props) => {
    return (
        performCheck(props) ?
            props.children
            :
            null
    );
};

export default PermissionCheck;