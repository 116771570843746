export const getISODateString = (date : Date) => {
    if (!date) return "";
    return getISODateSubstr(date.toISOString());
}

export const getISODateSubstr = (ISODate : string) => {
    if (ISODate == null) return "";
    return ISODate.substring(0, 10);
}

export const formatISODateString = (ISODate : string, separator? : string) : string => {
    if(!separator) separator = "/";
    if (ISODate == null) return "";
    let parts : string[] = ISODate.substring(0,10).split("-");
    return `${parts[2]}${separator}${parts[1]}${separator}${parts[0]}`;
}

export const formatISODateTimeString = (ISODateTime: string, dateSeparator?: string, timeSeparator?: string) => {
    if (!dateSeparator) dateSeparator = "/";
    if (!timeSeparator) timeSeparator = ":";
    let dateParts = ISODateTime.substring(0, 10).split("-");
    let timeParts = ISODateTime.substring(11, 16).split(":");
    return `${dateParts[2]}${dateSeparator}${dateParts[1]}${dateSeparator}${dateParts[0]}, klo ${timeParts[0]}${timeSeparator}${timeParts[1]}`;
}

export const ISODateLessThan = (ISODate : string) : boolean => {
    let now = new Date(Date.now());
    let then = new Date(ISODate);
    return then.getTime() - now.getTime() > 0;
}

export const toDatetimeLocal = (date: Date) => {
    const
      ten = function (i: number) {
        return (i < 10 ? '0' : '') + i;
      },
      YYYY = date.getFullYear(),
      MM = ten(date.getMonth() + 1),
      DD = ten(date.getDate()),
      HH = ten(date.getHours()),
      II = ten(date.getMinutes()),
      SS = ten(date.getSeconds())
    ;
    return YYYY + '-' + MM + '-' + DD + 'T' +
             HH + ':' + II + ':' + SS;
  };

export const fromDatetimeLocal = (BST : string) => {
  // BST should not be present as UTC time
    if(new Date(BST).toISOString().slice(0, 16) === BST) {
        let date: Date = new Date();
        return new Date(
            date.getTime() +
            (date.getTimezoneOffset() * 60000)
        ).toISOString();
    }
    // otherwise can just be equivalent of toISOString
    return Date.prototype.toISOString();
};

export const getDefaultActivationDate = () => {
    // today
    let now = new Date();
    return now;
}

export const getDefaultExpirationDate = () => {
    // A year from now
    let now = new Date();
    now.setFullYear(now.getFullYear() + 1);
    return now;
}

export const getDefaultArchiveDate = () => {
    // 6 years from now
    let now = new Date();
    now.setFullYear(now.getFullYear() + 6);
    return now;
}

export const getLocalizedDate = (date: Date) => {
    date.setHours(date.getHours() - date.getTimezoneOffset() / 60);
    return date;
}