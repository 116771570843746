import React from 'react';
import { Table } from 'semantic-ui-react';
import TemplateListItem from './TemplateListItem';
import PermissionCheck from '../layout-kit/PermissionCheck';

export interface Props {
    items: any,
    handleEdit: any,
    handleDelete: any,
    handleUse: any,
    permissions: any,
    lang: any,
    isLoading?: boolean
}

const TemplateList = (props: Props) => {
    return (
        <Table basic="very" columns="2">
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell width="4">Aktiiviset Listapohjat</Table.HeaderCell>
                </Table.Row>
            </Table.Header>

            <Table.Body>
                <PermissionCheck permissions={props.permissions} check="canListPharmacyTemplates">
                    {props.items && props.items.length > 0 ?
                        props.items.map((item: any, id: any) =>
                            <TemplateListItem key={id} item={item}
                                handleEdit={props.handleEdit}
                                handleDelete={props.handleDelete}
                                handleUse={props.handleUse}
                                permissions={props.permissions} />
                        )
                        :
                        <Table.Row>
                            <Table.Cell width="4">
                                <p>Ei listapohjia</p>
                            </Table.Cell>
                        </Table.Row>
                    }
                </PermissionCheck>
            </Table.Body>
        </Table>
    );
};

export default TemplateList;